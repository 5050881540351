import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

import Button from 'components/Button'
import Popover from 'components/Popover'
import { ALLOW_TO_OPEN_DRAWER } from 'utils/appVars'

const PopoverHolder = styled.div`
  position: absolute;
  height: 100px;
  left: 100%;
  top: calc((100% - 90px) / 2);
`

const ConfirmationText = styled.div`
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: center;
`

const ButtonContainer = styled.div.attrs({
  className: ALLOW_TO_OPEN_DRAWER,
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`

const ConfirmationButton = styled(Button)`
  min-width: 12rem;
  padding-left: 4rem;
  padding-right: 4rem;
  border-radius: 100px;
  text-transform: none;
  font-weight: normal;
  :disabled {
    filter: brightness(0.4);
    cursor: wait;
    color: #fff;
  }
`

const ConfirmButton = styled(ConfirmationButton)`
  border: 1px solid ${(props) => props.theme.statusBooked};
  background-color: ${(props) => props.theme.statusBooked};
  :disabled {
    border: 1px solid ${(props) => props.theme.statusBooked};
    background-color: ${(props) => props.theme.statusBooked};
  }
`

const CancelButton = styled(ConfirmationButton)`
  border: 1px solid ${(props) => props.theme.panelBG};
  margin-right: 15px;
  background-color: transparent;
  :disabled {
    border: 1px solid ${(props) => props.theme.panelBG};
  }
`

const StaticPopover = ({
  sendConfirmation,
  deleteReservationMessage,
  isFetching,
  showPopup,
  popupData,
  closeConfirmationPopup,
}) => (
  <PopoverHolder>
    <Popover
      deleteReservationMessage={deleteReservationMessage}
      show={showPopup}
      content={
        <div>
          <ConfirmationText>
            <Translate
              id="billboard.mainPanel.removeBookingConfirmation.title"
              data={{
                reservationName: popupData.reservationName,
              }}
            />
          </ConfirmationText>
          <ButtonContainer>
            <CancelButton
              onClick={closeConfirmationPopup}
              disabled={isFetching}
            >
              <Translate id="billboard.mainPanel.removeBookingConfirmation.button.no" />
            </CancelButton>
            <ConfirmButton
              onClick={() => sendConfirmation(popupData)}
              disabled={isFetching}
            >
              <Translate id="billboard.mainPanel.removeBookingConfirmation.button.yes" />
            </ConfirmButton>
          </ButtonContainer>
        </div>
      }
      onHide={closeConfirmationPopup}
    />
  </PopoverHolder>
)

StaticPopover.propTypes = {
  isFetching: PropTypes.bool,
  deleteReservationMessage: PropTypes.bool,
  showPopup: PropTypes.bool,
  popupData: PropTypes.object,
  closeConfirmationPopup: PropTypes.func,
  sendConfirmation: PropTypes.func,
}

export default StaticPopover
