import styled, { css } from 'styled-components'

const statusMap = (theme) => ({
  FREE: theme.statusFree,
  AWAY: theme.statusAway,
  BUSY: theme.statusBusy,
  BOOKED: theme.statusBooked,
  CLAIMED: theme.statusClaimed,
  TAKEN: theme.statusTaken,
  OOO: theme.statusOOO,
  OFFLINE: theme.statusOffline,
  INFO: theme.statusOffline,
  NONE: theme.statusNone,
})

const StatusIndicator = styled.span`
  color: ${(props) => props.theme.panelBgLight};
  background-color: ${(props) => props.theme.panelBg};
  ${(props) =>
    props.status &&
    css`
      background-color: ${(props) => statusMap(props.theme)[props.status]};
    `};
`

export default StatusIndicator
