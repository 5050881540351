import {
  SAME_BUILDING,
  OTHER_BUILDING,
  OFFICE,
  DESK,
  VACATION,
  HOME,
} from 'utils/appVars'

export const mapAttendanceTypes = (array, currentBuildingId) =>
  array.map((attendance) => {
    switch (attendance.type) {
      case OFFICE:
      case DESK:
        return currentBuildingId === attendance.buildingId
          ? { ...attendance, type: SAME_BUILDING }
          : { ...attendance, type: OTHER_BUILDING }
      default:
        return attendance
    }
  })

export const sortByTypeAndName = (array) => {
  const typeOrder = {
    [SAME_BUILDING]: 1,
    [OTHER_BUILDING]: 2,
    [HOME]: 3,
    [VACATION]: 4,
  }

  return array.slice().sort((a, b) => {
    const typeComparison = typeOrder[a.type] - typeOrder[b.type]
    if (typeComparison !== 0) {
      return typeComparison
    }

    const aSurname = a.user.name.split(' ')[0]
    const bSurname = b.user.name.split(' ')[0]
    if (aSurname < bSurname) {
      return -1
    }
    if (aSurname > bSurname) {
      return 1
    }
    return 0
  })
}
