import PropTypes from 'prop-types'
import React, { Component } from 'react'

import ModalRow from 'containers/quickView/modal/ModalRow'
import SpacePropGrid from 'containers/quickView/spaceModal/SpacePropGrid'
import SpaceProp from 'containers/quickView/spaceModal/SpaceProp'

class SpaceProps extends Component {
  render() {
    const { spaceProps } = this.props
    if (!spaceProps) {
      return null
    }

    return (
      <ModalRow>
        <SpacePropGrid>
          {spaceProps.map((prop) => (
            <SpaceProp key={prop.id} id={prop.id} />
          ))}
        </SpacePropGrid>
      </ModalRow>
    )
  }
}

SpaceProps.propTypes = {
  spaceProps: PropTypes.arrayOf(PropTypes.object),
}

export default SpaceProps
