import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import {
  getSpaces,
  getMetaTypes,
  getFutureDesks,
  getFutureRooms,
  getFutureUsers,
} from 'containers/app/selectors'
import toJS from 'components/toJS'
import Icon from 'components/Icon'
import ResultContainer from 'containers/searchPanel/results/ResultContainer'
import ResultText from 'containers/searchPanel/results/ResultText'
import ResultSymbol from 'containers/searchPanel/results/ResultSymbol'
import ResultCapacity from 'containers/searchPanel/results/ResultCapacity'
import { clickAsset } from 'containers/searchPanel/results/actions'
import { getActiveSpaceId } from 'containers/quickView/selectors'
import {
  getDateFilter,
  getIsFuture,
} from 'containers/searchPanel/filter/selectors'
import { icons } from 'components/icon/icons'
import { checkSlotInterval } from 'utils/utilsFunctions'

const modeMap = {
  CLAIM: 'filter.bookable',
  BOOK: 'filter.bookable',
  TAKE: 'filter.nonBookable',
  NONE: 'filter.none',
}
const statusMap = {
  OFFLINE: 'space.status.offline',
  FREE: 'space.status.free',
  CLAIMED: 'space.status.claimed',
  BOOKED: 'space.status.booked',
  NONE: 'space.status.none',
  BUSY: 'space.status.busy',
  TAKEN: 'space.status.taken',
}
const descToIconMap = {
  ROOM: icons.ROOM,
  DESK: icons.DESK,
}

class SpaceItem extends Component {
  getFutureDesk = () => {
    const { isFuture, futureDesks, futureRooms } = this.props

    if (isFuture) {
      const futureSpaces = [...futureDesks, ...futureRooms]
      return futureSpaces.find((space) => space.id === this.props.id)
    }
  }

  getFutureDeskUser = (desk) => {
    const { futureUsers } = this.props
    if (desk) {
      const userId = desk.state === 'BOOKED' ? desk.events[0].userId : null
      if (userId) {
        const u = futureUsers.find((user) => user.id === userId)
        return `${u.name[0]}. ${u.lName}`
      }
      return (
        <Translate>
          {({ translate }) => `${translate('space.status.booked')}`}
        </Translate>
      )
    }
  }

  render() {
    const { space, type, active, clickAsset, isFuture } = this.props
    const futureDesk = this.getFutureDesk()
    if (!space) return null

    const assetState = space.status.state

    return (
      <ResultContainer onClick={() => clickAsset(space)} active={active}>
        <ResultSymbol status={futureDesk ? futureDesk.state : assetState}>
          <Icon name={descToIconMap[type.space.desc]} />
        </ResultSymbol>
        <ResultText>
          <span>{space.info.name}</span>
          <Translate>
            {({ translate }) => (
              <>
                <span>
                  {isFuture && futureDesk ? (
                    futureDesk.state === 'BOOKED' ||
                    futureDesk.state === 'CLAIMED' ? (
                      `${translate('space.status.booked')}`
                    ) : (
                      translate(statusMap[futureDesk.state])
                    )
                  ) : assetState === 'BOOKED' || assetState === 'CLAIMED' ? (
                    space.status.occupant ? (
                      <>
                        <div>{space.status.occupant}</div>
                      </>
                    ) : (
                      `${translate('space.status.booked')}`
                    )
                  ) : (
                    translate(statusMap[assetState])
                  )}
                </span>
              </>
            )}
          </Translate>
        </ResultText>
        {space.info.cap >= 1 && (
          <ResultCapacity>
            <Icon name={icons.USERS} /> {space.info.cap}
          </ResultCapacity>
        )}
      </ResultContainer>
    )
  }
}

SpaceItem.propTypes = {
  id: PropTypes.number,
  activeId: PropTypes.number,
  clickAsset: PropTypes.func,
  space: PropTypes.object,
  type: PropTypes.object,
  active: PropTypes.bool,
  selectedDate: PropTypes.instanceOf(Date),
  isFuture: PropTypes.bool,
  futureUsers: PropTypes.array,
  futureDesks: PropTypes.array,
  futureRooms: PropTypes.array,
}

const mapStateToProps = (state, props) => {
  const space = getSpaces(state).get(`${props.id}`)
  const spaceTypeId = space ? space.get('typeId') : null
  const type = spaceTypeId ? getMetaTypes(state).get(`${spaceTypeId}`) : null

  return {
    activeId: getActiveSpaceId(state),
    space,
    type,
    selectedDate: getDateFilter(state),
    isFuture: getIsFuture(state),
    futureDesks: getFutureDesks(state),
    futureRooms: getFutureRooms(state),
    futureUsers: getFutureUsers(state),
  }
}

export default connect(mapStateToProps, { clickAsset })(toJS(SpaceItem))
