export const CLOSE_CLAIM_UI =
  'containers/quickView/spaceModal/claimFlow/CLOSE_CLAIM_UI'
export const CLAIM_ON_BEHALF_SELECT =
  'containers/quickView/spaceModal/claimFlow/CLAIM_ON_BEHALF_SELECT'

export const CLAIM_SPACE_REQUEST =
  'containers/quickView/spaceModal/claimFlow/CLAIM_SPACE_REQUEST'
export const CLAIM_SPACE_SUCCESS =
  'containers/quickView/spaceModal/claimFlow/CLAIM_SPACE_SUCCESS'
export const CLAIM_SPACE_FAILURE =
  'containers/quickView/spaceModal/claimFlow/CLAIM_SPACE_FAILURE'
export const CLEAR_CLAIM_SPACE_ERROR =
  'containers/quickView/spaceModal/claimFlow/CLEAR_CLAIM_SPACE_ERROR'

export const RELEASE_SPACE_REQUEST =
  'containers/quickView/spaceModal/claimFlow/RELEASE_SPACE_REQUEST'
export const RELEASE_SPACE_SUCCESS =
  'containers/quickView/spaceModal/claimFlow/RELEASE_SPACE_SUCCESS'
export const RELEASE_SPACE_FAILURE =
  'containers/quickView/spaceModal/claimFlow/RELEASE_SPACE_FAILURE'

export const CHANGE_CLAIMED_SPACE_REQUEST =
  'containers/quickView/spaceModal/claimFlow/CHANGE_CLAIMED_SPACE_REQUEST'
export const CHANGE_BOOKED_DESK_REQUEST =
  'containers/quickView/spaceModal/claimFlow/CHANGE_BOOKED_DESK_REQUEST'

export const BOOK_DESK_REQUEST =
  'containers/quickView/spaceModal/claimFlow/BOOK_DESK_REQUEST'
export const BOOK_DESK_SUCCESS =
  'containers/quickView/spaceModal/claimFlow/BOOK_DESK_SUCCESS'
export const BOOK_DESK_FAILURE =
  'containers/quickView/spaceModal/claimFlow/BOOK_DESK_FAILURE'

export const CANCEL_BOOKING_REQUEST =
  'containers/quickView/spaceModal/claimFlow/CANCEL_BOOKING_REQUEST'
export const CANCEL_BOOKING_SUCCESS =
  'containers/quickView/spaceModal/claimFlow/CANCEL_BOOKING_SUCCESS'

export const GET_CLAIM_ON_BEHALF_PERMISSION_REQUEST =
  'containers/quickView/spaceModal/claimFlow/GET_CLAIM_ON_BEHALF_PERMISSION_REQUEST'
export const GET_CLAIM_ON_BEHALF_PERMISSION_SUCCESS =
  'containers/quickView/spaceModal/claimFlow/GET_CLAIM_ON_BEHALF_PERMISSION_SUCCESS'
export const GET_CLAIM_ON_BEHALF_PERMISSION_FAILURE =
  'containers/quickView/spaceModal/claimFlow/GET_CLAIM_ON_BEHALF_PERMISSION_FAILURE'
export const GET_ASSET_CALENDAR_REQUEST =
  'containers/quickView/spaceModal/claimFlow/GET_ASSET_CALENDAR_REQUEST'
export const GET_ASSET_CALENDAR_SUCCESS =
  'containers/quickView/spaceModal/claimFlow/GET_ASSET_CALENDAR_SUCCESS'
export const GET_ASSET_CALENDAR_FAILURE =
  'containers/quickView/spaceModal/claimFlow/GET_ASSET_CALENDAR_FAILURE'
export const SET_DELETE_ON_BEHALF_REQUEST =
  'containers/quickView/spaceModal/claimFlow/SET_DELETE_ON_BEHALF_REQUEST'

export const GET_МAX_ALLOWED_DAYS_REQUEST =
  'containers/quickView/spaceModal/claimFlow/GET_МAX_ALLOWED_DAYS_REQUEST'
export const GET_МAX_ALLOWED_DAYS_SUCCESS =
  'containers/quickView/spaceModal/claimFlow/GET_МAX_ALLOWED_DAYS_SUCCESS'
export const GET_МAX_ALLOWED_DAYS_FAILURE =
  'containers/quickView/spaceModal/claimFlow/GET_МAX_ALLOWED_DAYS_FAILURE'

export const GET_МAX_ALLOWED_DURATION_REQUEST =
  'containers/quickView/spaceModal/claimFlow/GET_МAX_ALLOWED_DURATION_REQUEST'
export const GET_МAX_ALLOWED_DURATION_SUCCESS =
  'containers/quickView/spaceModal/claimFlow/GET_МAX_ALLOWED_DURATION_SUCCESS'
export const GET_МAX_ALLOWED_DURATION_FAILURE =
  'containers/quickView/spaceModal/claimFlow/GET_МAX_ALLOWED_DURATION_FAILURE'
