import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import Icon from 'components/Icon'
import { getCurrentBuildingId } from 'containers/app/selectors'
import {
  selectUser,
  selectUserDetailsRequest,
} from 'containers/quickView/actions'
import { changeModalPosition } from 'containers/flowPlanner/actions'
import { SAME_BUILDING, OTHER_BUILDING, VACATION, HOME } from 'utils/appVars'

const Wrapper = styled.div`
  position: relative;
  margin-right: 2em;
  width: 1.5em;
  height: 1.5em;
  font-size: 20px;
  font-weight: 500;
  margin-right: 16px;
  &:hover {
    cursor: pointer;
  }
  ${Icon} {
    ${(props) =>
      props.attendanceType === HOME &&
      css`
        background-image: url(./home_2.svg);
      `};
    ${(props) =>
      props.attendanceType === VACATION &&
      css`
        background-image: url(./vacation_2.svg);
      `};
    ${(props) =>
      props.attendanceType === SAME_BUILDING &&
      css`
        display: none;
      `};
    ${(props) =>
      props.attendanceType === OTHER_BUILDING &&
      css`
        background-image: url(./other_building_2.svg);
      `};
    height: 1.3em;
    width: 1.3em;
    position: absolute;
    left: 20px;
    top: -20px;
    transform: translate(50%, 50%);
    border-radius: 50%;
    background-color: white;
    margin: 0;
    font-size: 1em;
    background-repeat: no-repeat;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.19);
  }
`

const Circle = styled.div`
  border-radius: 50%;
  background-color: ${(props) => props.theme.panelBg};
  color: ${(props) => props.theme.actionButton};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`

class FlowPlannerProfilePicture extends Component {
  render() {
    const {
      children,
      className,
      attendanceType,
      currentBuilding,
      selectUser,
      selectUserDetailsRequest,
      changeModalPosition,
      user,
    } = this.props
    const userModalPosition = {
      bottom: '390px',
      'z-index': 3,
    }
    return (
      <Wrapper
        onClick={() => {
          changeModalPosition(userModalPosition)
          selectUser(Map(user))
          selectUserDetailsRequest(user.id)
        }}
        className={className}
        attendanceType={attendanceType}
      >
        <Circle>{children}</Circle>
        <Icon />
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => ({
  currentBuilding: getCurrentBuildingId(state),
})

FlowPlannerProfilePicture.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  className: PropTypes.string,
  currentBuilding: PropTypes.number,
  attendanceBuildingId: PropTypes.number,
  user: PropTypes.object,
  selectUser: PropTypes.func,
  selectUserDetailsRequest: PropTypes.func,
  changeModalPosition: PropTypes.func,
  attendanceType: PropTypes.string,
}

export default connect(mapStateToProps, {
  changeModalPosition,
  selectUserDetailsRequest,
  selectUser,
})(FlowPlannerProfilePicture)
