import { takeEvery, all, call, put, select, delay } from 'redux-saga/effects'
import { getUnixTime, fromUnixTime } from 'date-fns'

import {
  WRONG_SLOT,
  ERROR_APPEARED,
  DOUBLE_BOOKING,
  BOOKING_REGISTERED,
  BOOKING_REMOVED,
  FETCH_UPDATE_SUCCESS,
} from 'containers/app/constants'
import * as argus from 'api/argus'
import * as desks from 'api/desks'
import {
  claimSpaceFailed,
  claimSpaceSuccess,
  releaseSpaceSuccess,
  releaseSpaceFailed,
  bookDeskSuccess,
  cancelDeskBookingSuccess,
  getClaimOnBehalfPermissionSuccess,
  getClaimOnBehalfPermissionFailed,
  getClaimOnBehalfPermission,
  getAssetCalendar,
  getAssetCalendarSuccess,
  getAssetCalendarFailed,
  getMaxAllowedDaysSuccess,
  getMaxBookingDurationSuccess,
} from 'containers/quickView/spaceModal/claimFlow/actions'
import {
  CLAIM_SPACE_REQUEST,
  RELEASE_SPACE_REQUEST,
  CHANGE_CLAIMED_SPACE_REQUEST,
  BOOK_DESK_REQUEST,
  CANCEL_BOOKING_REQUEST,
  CHANGE_BOOKED_DESK_REQUEST,
  GET_CLAIM_ON_BEHALF_PERMISSION_REQUEST,
  GET_ASSET_CALENDAR_REQUEST,
  SET_DELETE_ON_BEHALF_REQUEST,
} from 'containers/quickView/spaceModal/claimFlow/constants'
import { getClientTime } from 'utils/argusTimeUtils'
import {
  getClaimFlow,
  getEventsPerAssset,
} from 'containers/quickView/spaceModal/claimFlow/selectors'
import {
  getDateFilter,
  getIsFuture,
} from 'containers/searchPanel/filter/selectors'
import { updateDeskBookingsRequest } from 'containers/app/actions'
import {
  getRegisteredSlot,
  getSubmitDisabled,
} from 'containers/quickView/selectors'
import { setBookingMessageToShow } from 'containers/quickView/actions'
import { getDefaultStart, getDefaultEnd } from 'containers/app/selectors'

function* changeClaimedSpace({ fromSpace, toSpace }) {
  yield call(releaseSpace, { space: fromSpace })
  yield call(claimSpace, { space: toSpace })
}

function* changeDeskBooking({ fromBookingId, toSpaceId }) {
  yield call(deleteDeskBooking, { bookingId: fromBookingId })
  yield call(bookDesk, { spaceId: toSpaceId })
}

function* bookDesk({ spaceId, userId, slot }) {
  const dateSelected = yield select(getDateFilter)
  const defaultStart = yield select(getDefaultStart)
  const defaultEnd = yield select(getDefaultEnd)
  const isFuture = yield select(getIsFuture)
  const parsedDefaultStartHour = parseInt(defaultStart / 60)
  const parsedDefaultEndHour = parseInt(defaultEnd / 60)
  const selectedDayHours = dateSelected.getHours()
  const defaultStartOrCurrentTime =
    parsedDefaultStartHour < selectedDayHours && !isFuture
      ? selectedDayHours
      : parsedDefaultStartHour
  const emptySlot = Object.keys(slot).length === 0
  let start, end

  if (emptySlot) {
    const endDate = new Date(dateSelected)
    const startHour = defaultStartOrCurrentTime
    const endHour = parsedDefaultEndHour
    const startMinute = defaultStart % 60
    const endMinute = defaultEnd % 60
    start =
      getUnixTime(
        new Date(dateSelected).setHours(startHour, startMinute, 0, 0),
      ) / 60
    end = getUnixTime(new Date(endDate).setHours(endHour, endMinute, 0, 0)) / 60
  } else {
    start = getUnixTime(slot.start.value.setSeconds(0, 0)) / 60
    end = getUnixTime(slot.end.value.setSeconds(0, 0)) / 60
  }

  try {
    yield call(desks.bookDesk, spaceId, userId, start, end)
    yield delay(1000)
    yield all([
      put(getClaimOnBehalfPermission(spaceId)),
      put(getAssetCalendar(spaceId)),
      put(updateDeskBookingsRequest()),
      put(bookDeskSuccess()),
      put(setBookingMessageToShow(BOOKING_REGISTERED)),
    ])
  } catch ({ error }) {
    if (error.code === 400) {
      if (error.detailedCode === 'MULTI_DESKS') {
        yield put(setBookingMessageToShow(DOUBLE_BOOKING))
      } else {
        yield put(setBookingMessageToShow(WRONG_SLOT))
      }
    }

    if (error.code === 409) {
      yield put(setBookingMessageToShow(WRONG_SLOT))
    }
    if (error.detailedCode === 'END_GREATER_START') {
      yield put(setBookingMessageToShow(ERROR_APPEARED))
    }
    if (error.code === 500) {
      yield put(setBookingMessageToShow(ERROR_APPEARED))
    }
    yield put(claimSpaceFailed(error.message))
  }
}

function* deleteDeskBooking({ bookingId }) {
  try {
    yield call(desks.cancelBooking, bookingId)
    yield delay(1000)
    yield all([
      put(updateDeskBookingsRequest()),
      put(cancelDeskBookingSuccess()),
    ])
  } catch ({ error, response }) {
    yield put(releaseSpaceFailed(error.message))
  }
}

function* claimSpace({ space }) {
  const until = 0
  try {
    yield call(
      argus.postData,
      `/secure/space/claim?date=${getClientTime()}`,
      {
        body: { claim: true, until: until, id: space.id },
      },
      false,
    )
  } catch ({ error, response }) {
    console.error(error.message)
    yield put(claimSpaceFailed(error.message))
  }
}

function* releaseSpace({ space }) {
  try {
    yield call(
      argus.postData,
      `/secure/space/claim?date=${getClientTime()}`,
      {
        body: { claim: false, until: 0, id: space.id },
      },
      false,
    )
  } catch ({ error, response }) {
    console.error(error.message)
    yield put(releaseSpaceFailed(error.message))
  }
}

function* selfUpdated({ response }) {
  const claimFlow = yield select(getClaimFlow)
  if (!claimFlow.get('isFetching')) return

  let claims = null

  try {
    claims = response.result.self.status.claims
  } catch ({ error, response }) {
  } finally {
    if (claims) {
      const spaceRequestedToClaim = yield claims.find(
        (claim) => claim.id === claimFlow.get('toClaimId'),
      )

      if (spaceRequestedToClaim) {
        yield put(claimSpaceSuccess())
      } else {
        const spaceRequestedToRelease = yield claims.find(
          (claim) => claim.id === claimFlow.get('toReleaseId'),
        )

        if (!spaceRequestedToRelease) {
          yield put(releaseSpaceSuccess())
        }
      }
    }
  }
}

function* getClaimOnBehalfPermissionReq({ spaceId }) {
  try {
    const response = yield call(
      argus.fetchData,
      `/secure/v2/asset/${spaceId}/calendar/options`,
    )
    yield all([
      put(getClaimOnBehalfPermissionSuccess(response.allowBoBo)),
      put(getMaxAllowedDaysSuccess(response.maxDays)),
      put(getMaxBookingDurationSuccess(response.maxDuration)),
    ])
  } catch ({ error, response }) {
    yield put(getClaimOnBehalfPermissionFailed(error?.message))
  }
}

function* getAssetCalendarReq({ spaceId }) {
  try {
    const response = yield call(
      argus.fetchData,
      `/secure/v2/asset/${spaceId}/calendar`,
    )
    yield put(getAssetCalendarSuccess(response.events))
  } catch ({ error, response }) {
    yield put(getAssetCalendarFailed(error?.message))
  }
}

function* setDeleteOnBehalfPermission({ spaceId, eventId, start, end }) {
  const currentTime = new Date()
  const startTime = fromUnixTime(new Date(start * 60))
  const endTime = fromUnixTime(new Date(end * 60))
  try {
    if (endTime > currentTime && startTime < currentTime) {
      yield call(desks.cancelAssetBooking, spaceId, eventId)
    } else {
      yield call(desks.deleteAssetBooking, spaceId, eventId)
    }
    yield delay(500)
    yield all([
      put(updateDeskBookingsRequest()),
      put(cancelDeskBookingSuccess()),
    ])
    yield put(setBookingMessageToShow(BOOKING_REMOVED))
  } catch ({ error, response }) {
    yield put(setBookingMessageToShow(ERROR_APPEARED))
    yield put(releaseSpaceFailed(error.message))
  }
}

function* watchChangeClaimedSpaceRequest() {
  yield takeEvery(CHANGE_CLAIMED_SPACE_REQUEST, changeClaimedSpace)
}

function* watchClaimSpaceRequest() {
  yield takeEvery(CLAIM_SPACE_REQUEST, claimSpace)
}

function* watchReleaseSpaceRequest() {
  yield takeEvery(RELEASE_SPACE_REQUEST, releaseSpace)
}

function* watchSelfClaimsUpdate() {
  yield takeEvery(FETCH_UPDATE_SUCCESS, selfUpdated)
}

function* watchBookingRequest() {
  yield takeEvery(BOOK_DESK_REQUEST, bookDesk)
}

function* watchDeleteBookingRequest() {
  yield takeEvery(CANCEL_BOOKING_REQUEST, deleteDeskBooking)
}

function* watchChangeBookingDeskRequest() {
  yield takeEvery(CHANGE_BOOKED_DESK_REQUEST, changeDeskBooking)
}

function* watchGetClaimOnBehalfPermissionRequest() {
  yield takeEvery(
    GET_CLAIM_ON_BEHALF_PERMISSION_REQUEST,
    getClaimOnBehalfPermissionReq,
  )
}
function* watchGetAssetCalendarRequest() {
  yield takeEvery(GET_ASSET_CALENDAR_REQUEST, getAssetCalendarReq)
}

function* watchSetDeleteOnBehalfPermissionRequest() {
  yield takeEvery(SET_DELETE_ON_BEHALF_REQUEST, setDeleteOnBehalfPermission)
}

export default function* claimSagas() {
  yield all([
    watchChangeClaimedSpaceRequest(),
    watchClaimSpaceRequest(),
    watchReleaseSpaceRequest(),
    watchSelfClaimsUpdate(),
    watchBookingRequest(),
    watchDeleteBookingRequest(),
    watchChangeBookingDeskRequest(),
    watchGetClaimOnBehalfPermissionRequest(),
    watchGetAssetCalendarRequest(),
    watchSetDeleteOnBehalfPermissionRequest(),
  ])
}
