import styled from 'styled-components'

const ModalWrapper = styled.div`
  position: absolute;
  top: 100px;
  bottom: 200px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export default ModalWrapper
