import React, { Component } from 'react'

import Row from 'components/form/Row'
import Label from 'components/form/Label'

class OAuthSuccess extends Component {
  componentDidMount() {
    window.localStorage.setItem('oauthSuccess', true)

    const applicationBase = window.localStorage.getItem('applicationBase')

    window.location = applicationBase
  }

  render() {
    return (
      <Row>
        <Label>OAuth Success!</Label>
      </Row>
    )
  }
}

export default OAuthSuccess
