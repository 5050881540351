import {
  SELECT_SPACE_ID,
  SELECT_SIGN_ID,
  SELECT_USER,
  SELECT_USER_SUCCESS,
  SELECT_USER_FAILURE,
  CLEAR_QUICK_VIEW,
  SEARCH_USERS_REQUEST,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_FAILURE,
  SEARCH_USERS_CLEAR,
  REGISTER_SLOT,
  SET_BOOKING_MESSAGE,
  DELETE_BOOKING_MESSAGE,
  SET_DISABLED_SUBMIT,
  SELECT_USER_DETAILS_REQUEST,
  SELECT_USER_DETAILS_SUCCESS,
} from 'containers/quickView/constants'

export const selectSpaceId = (id) => ({
  type: SELECT_SPACE_ID,
  id,
})

export const selectSignId = (id) => ({
  type: SELECT_SIGN_ID,
  id,
})

export const selectUser = (user) => {
  return {
    type: SELECT_USER,
    user,
  }
}
export const selectUserSuccess = (bookings) => {
  return {
    type: SELECT_USER_SUCCESS,
    bookings,
  }
}

export const selectUserFailure = () => {
  return {
    type: SELECT_USER_FAILURE,
  }
}
export const clearQuickView = () => ({
  type: CLEAR_QUICK_VIEW,
})

export const searchUsersRequest = (query) => ({
  type: SEARCH_USERS_REQUEST,
  query,
})

export const searchUsersSuccess = (users) => ({
  type: SEARCH_USERS_SUCCESS,
  users,
})

export const searchUsersFailure = (message) => ({
  type: SEARCH_USERS_FAILURE,
  message,
})

export const searchUsersClear = () => ({
  type: SEARCH_USERS_CLEAR,
})

export const registerSlot = (slot) => ({
  type: REGISTER_SLOT,
  slot,
})

export const setBookingMessageToShow = (message) => ({
  type: SET_BOOKING_MESSAGE,
  message,
})

export const deleteBookingMessageToShow = () => ({
  type: DELETE_BOOKING_MESSAGE,
})

export const setDisabledSubmit = (payload) => ({
  type: SET_DISABLED_SUBMIT,
  payload,
})

export const selectUserDetailsRequest = (id) => {
  return {
    type: SELECT_USER_DETAILS_REQUEST,
    id,
  }
}

export const selectUserDetailsSuccess = (userInfo) => {
  return {
    type: SELECT_USER_DETAILS_SUCCESS,
    userInfo,
  }
}
