import { takeEvery, all, put, call, select } from 'redux-saga/effects'

import {
  FETCH_PENDING_CONSENT_REQUEST,
  APPROVE_CONSENT_REQUEST,
} from 'containers/app/constants'
import {
  fetchPendingUserConsentFailure,
  fetchPendingUserConsentSuccess,
  approveUserConsentSuccess,
  approveUserConsentFailure,
} from 'containers/app/actions'
import * as argus from 'api/argus'
import { getUserConsent } from 'containers/app/selectors'

function* fetchConsent() {
  try {
    const response = yield call(argus.fetchData, '/secure/user-consent/pending')
    yield put(fetchPendingUserConsentSuccess(response))
  } catch ({ error, response }) {
    yield put(fetchPendingUserConsentFailure('Failed to fetch user consent'))
  }
}

function* approveConsent() {
  const consent = yield select(getUserConsent)

  try {
    yield call(
      argus.postData,
      '/secure/user-consent/approve',
      {
        body: {
          id: consent.id,
          approvalDeviceType: 'web',
        },
      },
      false,
    )
    yield put(approveUserConsentSuccess())
  } catch ({ error, response }) {
    yield put(approveUserConsentFailure(error.message))
  }
}

function* watchFetchConsent() {
  yield takeEvery(FETCH_PENDING_CONSENT_REQUEST, fetchConsent)
}

function* watchConsentApproval() {
  yield takeEvery(APPROVE_CONSENT_REQUEST, approveConsent)
}

export default function* () {
  yield all([watchFetchConsent(), watchConsentApproval()])
}
