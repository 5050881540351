import {
  takeEvery,
  takeLatest,
  all,
  select,
  call,
  put,
  take,
  delay,
} from 'redux-saga/effects'
import { getUnixTime } from 'date-fns'
import Alert from 'react-s-alert'

import * as argus from 'api/argus'
import * as desks from 'api/desks'
import {
  SET_FLOOR,
  FETCH_UPDATE_REQUEST,
  SET_BUILDING,
  FETCH_DESK_BOOKINGS_REQUEST,
  FETCH_UPDATE_SUCCESS,
  FETCH_CLIENT_CONFIG_SUCCESS,
} from 'containers/app/constants'
import {
  getIsFuture,
  getDateFilter,
} from 'containers/searchPanel/filter/selectors'
import {
  updateRequest,
  updateSuccess,
  updateFailure,
  authenticateFailure,
  clearUpdates,
  updateDeskBookingsSuccess,
  updateDeskBookingsFailure,
  fetchFutureView,
} from 'containers/app/actions'
import {
  getStamp,
  getFloorStamps,
  getFutureDeskbookingEnabled,
  getCurrentFloorId,
} from 'containers/app/selectors'
import { UNITY_PANNED_FLOOR } from 'containers/unityLoader/constants'
import { clearQuickView } from 'containers/quickView/actions'
import {
  clearFilter,
  clearDataset,
  setDateFilter,
} from 'containers/searchPanel/filter/actions'
import { getSelectedColleagues } from 'containers/searchPanel/results/actions'
import { CHANGE_VISIBILITY } from 'containers/mainPanel/visibility/constants'
import { visibilityChanged } from 'containers/mainPanel/visibility/actions'
import { handlePrevDayFuture } from 'utils/utilsFunctions'

function* update({ floorIds, resetMap }) {
  const stamp = resetMap ? 0 : yield select(getStamp)
  const floorStamps = resetMap ? 0 : yield select(getFloorStamps)
  try {
    let response = yield call(
      argus.fetchUpdate,
      stamp,
      floorIds,
      floorIds.map((id) => floorStamps[id] || 0),
    )

    if (resetMap) {
      response = handlePrevDayFuture(response)
    }

    const floorsWithSpaces = {}
    const mergedSpacesResponse = response
      ? JSON.parse(JSON.stringify(response))
      : null
    if (response && response.spaces) {
      response.spaces.forEach((spaceType) => {
        if (floorsWithSpaces[spaceType.parentId]) {
          floorsWithSpaces[spaceType.parentId].add.push(...spaceType.add)
          floorsWithSpaces[spaceType.parentId].remove.push(...spaceType.remove)
          floorsWithSpaces[spaceType.parentId].update.push(...spaceType.update)
        } else {
          floorsWithSpaces[spaceType.parentId] = {
            ...spaceType,
          }
        }

        mergedSpacesResponse.spaces = []
        for (const floor in floorsWithSpaces) {
          mergedSpacesResponse.spaces.push(floorsWithSpaces[floor])
        }
      })
    }
    yield all([
      put(updateSuccess(mergedSpacesResponse, floorIds)),
      put(getSelectedColleagues()),
    ])
    if (process.env.NODE_ENV === 'development') {
      console.info('-- NEW UPDATE -----------------------------')
    }
  } catch ({ error, response }) {
    const message = error
      ? error.message
      : 'Could not fetch update from the server.'
    yield put(updateFailure(message))
    console.error(message)
    if (response && response.status === 401) {
      yield put(authenticateFailure())
    }
  }
}

function* updateDeskBookings() {
  const deskBookingEnabled = yield select(getFutureDeskbookingEnabled)
  if (!deskBookingEnabled) return null
  try {
    const response = yield call(desks.getAllUserBookings)
    yield put(updateDeskBookingsSuccess(response))
  } catch ({ error, response }) {
    const message = error
      ? error.message
      : 'Could not fetch update from the server.'
    yield put(updateDeskBookingsFailure(message))
    console.error(message)
  }
}
function* changeVisibility({ visibility }) {
  const dataToSend = {
    state: visibility,
  }
  const expectedData = false

  try {
    const response = yield call(
      argus.postData,
      '/secure/self/visibility',
      {
        body: dataToSend,
      },
      expectedData,
    )
    yield put(visibilityChanged(visibility))
  } catch ({ error, response }) {
    if (response.status === 401) {
      yield put(authenticateFailure())
    }
    Alert.error(`ERROR: ${error.message}`)
  }
}

function* changeFloor({ floorId }) {
  const isFuture = yield select(getIsFuture)
  yield put(updateRequest([floorId]))
  yield take(FETCH_UPDATE_SUCCESS)
  if (isFuture) {
    yield put(fetchFutureView())
  }
}

function* changeBuilding() {
  yield put(clearQuickView())
  yield put(clearFilter())
  yield put(clearDataset())
  yield put(clearUpdates())
}

function* panFloor({ payload }) {
  yield put(updateRequest([payload.floorId]))
}
function* watchUpdate() {
  yield takeLatest(FETCH_UPDATE_REQUEST, update)
}

function* watchChangeFloor() {
  yield takeEvery([SET_FLOOR, SET_BUILDING], changeFloor)
}

function* watchChangeBuilding() {
  yield takeEvery([SET_BUILDING], changeBuilding)
}

function* watchPanFloor() {
  yield takeEvery(UNITY_PANNED_FLOOR, panFloor)
}

function* watchDesksBooking() {
  yield takeEvery([FETCH_DESK_BOOKINGS_REQUEST], updateDeskBookings)
}

function* watchConfigSuccess() {
  yield all([take(FETCH_UPDATE_SUCCESS), take(FETCH_CLIENT_CONFIG_SUCCESS)])
  yield call(updateDeskBookings)
}

function* watchVisibilityChange() {
  yield takeEvery([CHANGE_VISIBILITY], changeVisibility)
}

function* updateSagas() {
  yield all([
    watchChangeFloor(),
    watchChangeBuilding(),
    watchUpdate(),
    watchPanFloor(),
    watchDesksBooking(),
    watchConfigSuccess(),
    watchVisibilityChange(),
  ])
}
export default updateSagas
