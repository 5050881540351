import styled from 'styled-components'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { StyledInput, Wrapper } from './Input'

import Icon from 'components/Icon'
import { icons } from 'components/icon/icons'

const PasswordWrapper = Wrapper.withComponent('div')

const ToggleButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  background: none;
  border: none;
  outline: none;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  ${Icon} {
    color: ${(props) => props.theme.darkText};
  }
`

class Password extends PureComponent {
  state = {
    visible: false,
  }

  toggleVisibility = () => {
    this.setState({
      visible: !this.state.visible,
    })
  }

  render() {
    const { type, input, disabled, ...rest } = this.props
    const { visible } = this.state

    return (
      <PasswordWrapper>
        <StyledInput
          id={input.name}
          name={input.name}
          disabled={disabled}
          type={visible ? 'text' : 'password'}
          tabIndex="0"
          {...input}
          {...rest}
        />
        {input.value.length > 0 && (
          <ToggleButton
            type="button"
            onClick={this.toggleVisibility}
            disabled={disabled}
            tabIndex="-1"
          >
            <Icon name={visible ? icons.NOT_VISIBLE : icons.VISIBLE} />
          </ToggleButton>
        )}
      </PasswordWrapper>
    )
  }
}

Password.propTypes = {
  type: PropTypes.oneOf(['text', 'password']),
  input: PropTypes.any,
  disabled: PropTypes.bool,
}

Password.propTypes = {
  type: PropTypes.oneOf(['text', 'password']),
  input: PropTypes.any,
  disabled: PropTypes.bool,
}

export default Password
