import React, { Component } from 'react'
import styled from 'styled-components'

import Panel from 'components/Panel'
import PanelHead from 'components/panel/PanelHead'
import Version from 'pages/login/Version'
import ModalWrapper from 'pages/login/ModalWrapper'
import LoginAuth from 'pages/login/LoginAuth'
import WaveBackground from 'components/Wave'
import FlexSpacer from 'components/FlexSpacer'

const LoginPanel = styled(Panel)`
  position: absolute;
  height: auto;
  width: 540px;
  top: 0px;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 6px;
  padding: 0 0 20px 0;
  background-color: #fffcfa;
`
const LoginLogo = styled.div`
  margin: 45px auto 0 auto;
  width: 200px;
  height: 22px;
  background-image: url('./logo-black.svg');
  background-size: cover;
`
const LoginCaption = styled.span`
  font-size: 30px;
  font-weight: bold;
  left: 0;
  right: 0;
  margin: 30px auto;
`
const BackgroundHolder = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('./lights_table.jpg');
  background-size: cover;
  z-index: -1;
`
class Login extends Component {
  state = {
    showCredentialsModal: false,
  }
  componentDidMount() {
    window.localStorage.setItem('applicationBase', window.location)
  }

  render() {
    return (
      <React.Fragment>
        <BackgroundHolder />
        <ModalWrapper>
          <LoginPanel>
            {!this.state.showCredentialsModal && (
              <>
                <LoginLogo />
                <FlexSpacer />
                <LoginCaption>Web App</LoginCaption>
              </>
            )}
            <LoginAuth
              hideLoginTitle={(_) =>
                this.setState({
                  showCredentialsModal: !this.state.showCredentialsModal,
                })
              }
            />
            <FlexSpacer />
          </LoginPanel>
        </ModalWrapper>
        <Version />
      </React.Fragment>
    )
  }
}

export default Login
