import { normalize } from 'normalizr'

import * as schema from 'containers/app/schema'
import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  FETCH_UPDATE_REQUEST,
  FETCH_UPDATE_SUCCESS,
  FETCH_UPDATE_FAILURE,
  SET_BUILDING,
  SET_FLOOR,
  CLEAR_FLOOR_STAMPS,
  CLEAR_UPDATES,
  TOGGLE_POLLING,
  START_WAYFINDING_REQUEST,
  START_WAYFINDING_SUCCESS,
  START_WAYFINDING_FAILURE,
  STOP_WAYFINDING_REQUEST,
  STOP_WAYFINDING_SUCCESS,
  STOP_WAYFINDING_FAILURE,
  FETCH_CLIENT_CONFIG_REQUEST,
  FETCH_CLIENT_CONFIG_SUCCESS,
  FETCH_CLIENT_CONFIG_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  FETCH_PENDING_CONSENT_REQUEST,
  FETCH_PENDING_CONSENT_SUCCESS,
  FETCH_PENDING_CONSENT_FAILURE,
  APPROVE_CONSENT_REQUEST,
  APPROVE_CONSENT_SUCCESS,
  APPROVE_CONSENT_FAILURE,
  MISSING_LOCATION,
  LOCATE_REQUEST,
  LOCATE_SUCCESS,
  LOCATE_FAILURE,
  FILTER_REQUEST,
  FILTER_SUCCESS,
  FILTER_FAILURE,
  FETCH_DESK_BOOKINGS_REQUEST,
  FETCH_DESK_BOOKINGS_SUCCESS,
  FETCH_DESK_BOOKINGS_FAILURE,
  UPDATE_AVAILABLE_DESKS,
  SET_FUTURE_DESKS,
  FETCH_FUTURE_VIEW,
} from 'containers/app/constants'
import { SAVE_TOKEN } from 'pages/login/constants'

export const startWayfinding = (floorId, assetType, assetId) => ({
  type: START_WAYFINDING_REQUEST,
  floorId,
  assetType,
  assetId,
})

export const wayfindSuccess = () => ({
  type: START_WAYFINDING_SUCCESS,
})

export const wayfindFailure = () => ({
  type: START_WAYFINDING_FAILURE,
})

export const stopWayfind = () => ({
  type: STOP_WAYFINDING_REQUEST,
})

export const stopWayfindSuccess = () => ({
  type: STOP_WAYFINDING_SUCCESS,
})

export const stopWayfindFailure = () => ({
  type: STOP_WAYFINDING_FAILURE,
})

export const updateRequest = (floorIds = [], resetMap = false) => ({
  type: FETCH_UPDATE_REQUEST,
  floorIds,
  resetMap,
})

export const updateSuccess = (response, floorIds) => {
  if (response) {
    return {
      type: FETCH_UPDATE_SUCCESS,
      response: normalize(response, schema.update),
      payload: response,
      floorIds,
    }
  } else {
    return {
      type: FETCH_UPDATE_SUCCESS,
    }
  }
}

export const updateFailure = (message) => ({
  type: FETCH_UPDATE_FAILURE,
  message,
})

export const setBuilding = (buildingId, floorId) => {
  window.localStorage.setItem('buildingId', buildingId)
  window.localStorage.setItem('floorId', floorId)

  return {
    type: SET_BUILDING,
    buildingId,
    floorId,
  }
}
export const setFloor = (floorId, forced) => {
  window.localStorage.setItem('floorId', floorId)

  return {
    type: SET_FLOOR,
    floorId,
    forced,
  }
}

export const startLocate = (floorId, assetType, assetId) => ({
  type: LOCATE_REQUEST,
  floorId,
  assetType,
  assetId,
})

export const locateFailure = () => ({ type: LOCATE_FAILURE })

export const locateSuccess = () => ({ type: LOCATE_SUCCESS })

export const startFilter = (
  filterType,
  showSpaceIds,
  hideSpaceIds,
  hideUserIds = [],
) => ({
  type: FILTER_REQUEST,
  filterType,
  showSpaceIds,
  hideSpaceIds,
  hideUserIds,
})

export const filterFailure = () => ({ type: FILTER_FAILURE })

export const filterSuccess = () => ({ type: FILTER_SUCCESS })

export const clearFloorStamps = () => ({
  type: CLEAR_FLOOR_STAMPS,
})

export const clearUpdates = () => ({
  type: CLEAR_UPDATES,
})

export const logoutRequest = () => ({
  type: LOGOUT_REQUEST,
})

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
})

export const logoutFailure = () => ({
  type: LOGOUT_FAILURE,
})

export const authenticateRequest = () => ({
  type: AUTH_REQUEST,
})

export const token = (token) => ({
  type: SAVE_TOKEN,
  token: token,
})

//TODO: Handle username/password and oauth
export const authenticateSuccess = (response) => ({
  type: AUTH_SUCCESS,
  response: response,
})

export const authenticateFailure = (message) => ({
  type: AUTH_FAILURE,
  message,
})

export const togglePolling = () => ({
  type: TOGGLE_POLLING,
})

export const fetchClientConfig = () => ({
  type: FETCH_CLIENT_CONFIG_REQUEST,
})

export const fetchClientConfigSuccess = (config) => ({
  type: FETCH_CLIENT_CONFIG_SUCCESS,
  config,
})

export const fetchClientConfigFailure = (error) => ({
  type: FETCH_CLIENT_CONFIG_FAILURE,
  error,
})

export const fetchPendingUserConsent = () => ({
  type: FETCH_PENDING_CONSENT_REQUEST,
})

export const fetchPendingUserConsentSuccess = (message) => ({
  type: FETCH_PENDING_CONSENT_SUCCESS,
  message,
})

export const fetchPendingUserConsentFailure = (error) => ({
  type: FETCH_PENDING_CONSENT_FAILURE,
  error,
})

export const approveUserConsent = () => ({
  type: APPROVE_CONSENT_REQUEST,
})

export const approveUserConsentSuccess = () => ({
  type: APPROVE_CONSENT_SUCCESS,
})

export const approveUserConsentFailure = (error) => ({
  type: APPROVE_CONSENT_FAILURE,
  error,
})

export const locationRequest = () => ({
  type: MISSING_LOCATION,
})

export const updateDeskBookingsRequest = () => ({
  type: FETCH_DESK_BOOKINGS_REQUEST,
})

export const updateDeskBookingsSuccess = (response) => ({
  type: FETCH_DESK_BOOKINGS_SUCCESS,
  response,
})

export const updateDeskBookingsFailure = (message) => ({
  type: FETCH_DESK_BOOKINGS_FAILURE,
  message,
})

export const updateAvailableDesks = (response) => ({
  type: UPDATE_AVAILABLE_DESKS,
  response,
})

export const updateFuture = (update) => ({
  type: SET_FUTURE_DESKS,
  update,
})

export const fetchFutureView = () => ({
  type: FETCH_FUTURE_VIEW,
})
