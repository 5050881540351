import { combineReducers } from 'redux-immutable'
import { fromJS, Map } from 'immutable'

import updates from 'containers/app/reducers/updates'
import visibility from 'containers/mainPanel/visibility/reducers'
import user from 'containers/app/reducers/user'
import available from 'containers/app/reducers/available'
import auth from 'containers/app/reducers/authentication'
import {
  SET_BUILDING,
  SET_FLOOR,
  FETCH_LOGO_SUCCESS,
  FETCH_CLIENT_CONFIG_SUCCESS,
  FETCH_PENDING_CONSENT_SUCCESS,
  AUTH_FAILURE,
  APPROVE_CONSENT_SUCCESS,
  MISSING_LOCATION,
  SET_FUTURE_DESKS,
} from 'containers/app/constants'
import {
  UNITY_SELECTED_FLOOR,
  UNITY_PANNED_FLOOR,
} from 'containers/unityLoader/constants'
import { SAVE_TOKEN } from 'pages/login/constants'

const startupSteps = {
  AUTH: 'auth',
  APPROVE: 'approve',
  BUILDING_SELECT: 'buildingSelect',
  RUN: 'run',
}

const baseUrl = (state = fromJS(window.location.href)) => {
  return state
}

const clientConfig = (state = Map(), action) => {
  if (action.type === FETCH_CLIENT_CONFIG_SUCCESS) {
    return state.merge(fromJS(action.config))
  }
  return state
}

const userConsent = (state = {}, action) => {
  if (action.type === FETCH_PENDING_CONSENT_SUCCESS) {
    return action.message
  }
  return state
}

//TODO: Load default building
const currentBuilding = (state = 1, action) => {
  if (action.type === SET_BUILDING) {
    return action.buildingId
  }
  return state
}

//TODO: Load default floor
const currentFloor = (state = 1, action) => {
  switch (action.type) {
    case SET_BUILDING:
    case SET_FLOOR:
      return action.floorId
    case UNITY_SELECTED_FLOOR:
    case UNITY_PANNED_FLOOR:
      return action.payload.floorId
    default:
      return state
  }
}

const future = (state = [], action) => {
  switch (action.type) {
    case SET_FUTURE_DESKS:
      return action.update
    default:
      return state
  }
}

const logo = (state = null, action) => {
  if (action.type === FETCH_LOGO_SUCCESS) {
    return action.logo
  } else return state
}

const token = (state = null, action) => {
  switch (action.type) {
    case SAVE_TOKEN:
      return action.token
    default:
      return state
  }
}

const startupFlow = (state = { currentStep: startupSteps.RUN }, action) => {
  switch (action.type) {
    case AUTH_FAILURE:
      return { currentStep: startupSteps.AUTH }
    case FETCH_PENDING_CONSENT_SUCCESS:
      return { currentStep: startupSteps.APPROVE }
    case APPROVE_CONSENT_SUCCESS:
    case MISSING_LOCATION:
      return { currentStep: startupSteps.BUILDING_SELECT }
    case SET_BUILDING:
      return { currentStep: startupSteps.RUN }
    default:
      return state
  }
}

export default combineReducers({
  baseUrl,
  auth,
  clientConfig,
  updates,
  currentBuilding,
  currentFloor,
  logo,
  token,
  userConsent,
  startupFlow,
  user,
  available,
  future,
  visibility,
})
