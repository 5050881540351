import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate, withLocalize } from 'react-localize-redux'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import {
  WRONG_SLOT,
  ERROR_APPEARED,
  REACHED_CAPACITY,
  BOOKING_REGISTERED,
  ATTENDANCE_REGISTERED,
  BOOKING_REMOVED,
  ATTENDANCE_REMOVED,
  DOUBLE_BOOKING,
} from 'containers/app/constants'

const TextHolder = styled.div`
  // height: 40px;
  padding: 10px 0;
  width: 375px;
  position: ${(props) => (props.isBookingMessage ? 'static' : 'absolute')};
  border-radius: 3px;
  right: 40px;
  top: 14px;
  font-size: 14px;
  font-weight: 200;
  display: flex;
  box-shadow: ${(props) =>
    props.isBookingMessage ? '0' : ' 0 2px 4px 0 rgba(140, 139, 139, 0.27)'};
  align-items: center;
  z-index: 1;
  margin: ${(props) => (props.isBookingMessage ? '5px 40px 0px 40px' : '0')};
  ${(props) =>
    (props.showMessage === ATTENDANCE_REGISTERED ||
      props.showMessage === BOOKING_REGISTERED) &&
    css`
      border-left: 7px solid #148368;
      background-color: #0e9571;
      color: white;
    `};
  ${(props) =>
    (props.showMessage === ATTENDANCE_REMOVED ||
      props.showMessage === BOOKING_REMOVED) &&
    css`
      border: 2px solid #2f3437;
      border-left: 7px solid #2f3437;
      background-color: #fff;
      color: #171717;
    `};
  ${(props) =>
    (props.showMessage === WRONG_SLOT ||
      props.showMessage === ERROR_APPEARED ||
      props.showMessage === DOUBLE_BOOKING) &&
    css`
      border-left: 7px solid #ffe564;
      background-color: #fffae2;
      color: ${(props) => props.theme.darkText};
    `};

  ${(props) =>
    props.showMessage === 'reachedCapacity' &&
    css`
      border-left: 7px solid #b11212;
      background-color: #e02020;
      color: #fff;
    `};
`
const TextValue = styled.p`
  line-height: 20px;
  margin: 0;
`
const IconHolder = styled.div`
  margin: 0px 8px;
  width: 40px;
  height: 20px;
  background-size: 20px;
  background-repeat: no-repeat;
  ${(props) =>
    (props.showMessage === ATTENDANCE_REGISTERED ||
      props.showMessage === BOOKING_REGISTERED) &&
    css`
      background-image: url(./common-icons/checked_white.svg);
    `};
  ${(props) =>
    (props.showMessage === ATTENDANCE_REMOVED ||
      props.showMessage === BOOKING_REMOVED) &&
    css`
      background-image: url(./common-icons/plus2.svg);
      background-size: 19px 21px;
    `};
  ${(props) =>
    (props.showMessage === WRONG_SLOT ||
      props.showMessage === ERROR_APPEARED ||
      props.showMessage === DOUBLE_BOOKING) &&
    css`
      background-image: url(./common-icons/info2.svg);
    `};
  ${(props) =>
    props.showMessage === REACHED_CAPACITY &&
    css`
      width: 25px;
      height: 24px;
      background-image: url(./common-icons/close_white.svg);
    `};
`

const InfoBox = ({ showMessage, isBookingMessage }) => {
  const textInThePopup = () => {
    switch (showMessage) {
      case ATTENDANCE_REGISTERED:
        return <Translate id="flowPlanner.scheduledAttendance" />
      case ATTENDANCE_REMOVED:
        return <Translate id="flowPlanner.removedAttendance" />
      case BOOKING_REGISTERED:
        return <Translate id="quickView.bookingSuccess" />
      case BOOKING_REMOVED:
        return <Translate id="quickView.meeting.cancelConfirmed" />
      case WRONG_SLOT:
        return <Translate id="flowPlanner.wrongTimeInterval" />
      case DOUBLE_BOOKING:
        return <Translate id="quickView.doubleBookingError" />
      case ERROR_APPEARED:
        return <Translate id="flowPlanner.errorAttendance" />
      case REACHED_CAPACITY:
        return <Translate id="flowPlanner.reachedCapacity" />

      default:
        return null
    }
  }
  return (
    <TextHolder isBookingMessage={isBookingMessage} showMessage={showMessage}>
      <IconHolder showMessage={showMessage} />
      <TextValue>{textInThePopup()}</TextValue>
    </TextHolder>
  )
}

InfoBox.propTypes = {
  showMessage: PropTypes.string,
  isBookingMessage: PropTypes.bool,
}
const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, null)(InfoBox)
