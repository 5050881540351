import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withLocalize, getTranslate, Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import ToggleButton from 'react-toggle-button'
import styled, { withTheme } from 'styled-components'
import { compose } from 'redux'

import { getSelfVisibility } from 'containers/app/selectors'
import { ALLOW_TO_OPEN_DRAWER } from 'utils/appVars'
import { EVERYONE, INVISIBLE } from 'containers/mainPanel/visibility/constants'
import { changeVisibility } from 'containers/mainPanel/visibility/actions'

const DataContainer = styled.div.attrs({
  className: ALLOW_TO_OPEN_DRAWER,
})`
  background-color: ${(props) => props.theme.logoBg};
  border-color: ${(props) => props.theme.panelBorder};
  color: ${(props) => props.theme.actionButton};
  padding: 0 16px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

class VisibilityPicker extends Component {
  state = {
    showActiveBtn: false,
  }
  componentDidMount() {
    const { visibility } = this.props
    this.setState({ showActiveBtn: visibility === EVERYONE })
  }
  selectVisibilityMode = (value) => {
    const { changeVisibility } = this.props

    const changeVisibilityTo = value ? INVISIBLE : EVERYONE
    changeVisibility(changeVisibilityTo)
    this.setState({ showActiveBtn: !value })
  }

  render() {
    const { translate, theme, visibility } = this.props
    return (
      <DataContainer>
        <p>
          <Translate id="billboard.mainPanel.visibilityMode" />
        </p>
        <ToggleButton
          value={this.state.showActiveBtn}
          onToggle={(value) => {
            this.selectVisibilityMode(value)
          }}
          activeLabel={translate('filter.title.toggleOn')}
          inactiveLabel={translate('filter.title.toggleOff')}
          colors={{
            active: {
              base: theme.accent,
            },
            inactive: {
              base: theme.panelBg,
            },
          }}
        />
      </DataContainer>
    )
  }
}
VisibilityPicker.propTypes = {
  translate: PropTypes.func,
  theme: PropTypes.object,
  visibility: PropTypes.string,
  changeVisibility: PropTypes.func,
}

const mapStateToProps = (state) => ({
  translate: getTranslate(state.get('localize')),
  visibility: getSelfVisibility(state),
})

const withConnect = connect(mapStateToProps, { changeVisibility })

export default withLocalize(compose(withConnect, withTheme)(VisibilityPicker))
