import PropTypes from 'prop-types'
import React, { Component, useState } from 'react'
import { connect } from 'react-redux'

import PanelDrawer from 'containers/mainPanel/PanelDrawer'
import { icons } from 'components/icon/icons'
import LocalePicker from 'containers/mainPanel/LocalePicker'
import LogoutButton from 'containers/mainPanel/LogoutButton'
import AngleButtons from 'containers/mainPanel/AngleButtons'
import VisibilityPicker from 'containers/mainPanel/VisibilityPicker'
import TimeFormatPicker from 'containers/mainPanel/TimeFormatPicker'
import VersionInformation from 'containers/mainPanel/VersionInformation'
import { setCameraAngle } from 'api/unity'
import { clearInnerDrawer } from 'containers/mainPanel/panelDrawer/actions'

const SettingsPicker = () => {
  const [currentAngle, setCurrentAngle] = useState('3d')

  const handleAngleSelect = (angle) => {
    setCameraAngle(angle)
    clearInnerDrawer()
    setCurrentAngle(angle)
  }
  return (
    <PanelDrawer iconName={icons.SETTINGS} label={'Settings'} allowToOpen>
      <VisibilityPicker />
      <TimeFormatPicker />
      <LocalePicker />
      <AngleButtons angle={currentAngle} setAngle={handleAngleSelect} />
      <LogoutButton />
      <VersionInformation />
    </PanelDrawer>
  )
}

SettingsPicker.propTypes = {}

export default connect(null, { clearInnerDrawer })(SettingsPicker)
