import React from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import FilterItem from 'containers/searchPanel/filter/FilterItem'
import {
  setPropFilter,
  clearFilter,
  setAvailablityFilter,
  clearDataset,
} from 'containers/searchPanel/filter/actions'
import { getPropFilters } from 'containers/searchPanel/filter/selectors'
import { getMetaProps, getDeskFilters } from 'containers/app/selectors'
import toJS from 'components/toJS'
import FilterItemGrid from 'containers/searchPanel/filter/FilterItemGrid'
import FilterContainer from 'containers/searchPanel/filter/FilterContainer'
import FilterButton from 'containers/searchPanel/filter/FilterButton'
import FilterButtonRow from 'containers/searchPanel/filter/FilterButtonRow'

const propsMap = {
  'Mac space': 'filter.props.macSpace',
  'Mac client': 'filter.props.macClient',
  'Single screen': 'filter.props.single',
  'Dual screen': 'filter.props.dual',
  'Ergonomic desk': 'filter.props.ergonomic',
  'Curved Screen': 'filter.props.curved',
  Parking: 'filter.props.parking',
  'Electrical Parking': 'filter.props.chargingPoint',
  Locker: 'filter.props.locker',
}

const DeskFilters = (props) => {
  const { propFilters, deskMetaProps, clearFilter, clearDataset } = props

  const handleClick = (e) => {
    const { setPropFilter, propFilters } = props
    const key = e.target.value
    const subFilter = get(propFilters, key)
    setPropFilter(key, !subFilter)
  }

  return (
    <FilterContainer>
      <Translate>
        {({ translate }) => (
          <FilterItemGrid>
            {deskMetaProps.map((deskMetaProp) => (
              <FilterItem
                key={deskMetaProp.id}
                active={get(propFilters, deskMetaProp.name)}
                onClick={handleClick}
                value={deskMetaProp.name}
                caption={translate(propsMap[deskMetaProp.name])}
                iconName={deskMetaProp.res}
              />
            ))}
          </FilterItemGrid>
        )}
      </Translate>
      <FilterButtonRow>
        <FilterButton dark onClick={() => clearFilter()}>
          <Translate id="filter.title.clear" />
        </FilterButton>
        <FilterButton onClick={() => clearDataset()}>
          <Translate id="filter.title.hide" />
        </FilterButton>
      </FilterButtonRow>
    </FilterContainer>
  )
}

DeskFilters.propTypes = {
  setPropFilter: PropTypes.func,
  propFilters: PropTypes.object,
  deskMetaProps: PropTypes.arrayOf(PropTypes.object),
  clearFilter: PropTypes.func,
  clearDataset: PropTypes.func,
}

const mapStateToProps = (state) => {
  const metaProps = getMetaProps(state)
  const deskMetaPropIds = getDeskFilters(state)
  const deskMetaProps = deskMetaPropIds.map((id) => metaProps.get(`${id}`))

  return {
    propFilters: getPropFilters(state),
    deskMetaProps,
  }
}

export default connect(mapStateToProps, {
  setPropFilter,
  clearFilter,
  clearDataset,
  setAvailablityFilter,
})(toJS(DeskFilters))
