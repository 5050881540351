import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { getUnixTime, format, fromUnixTime } from 'date-fns'
import styled from 'styled-components'

import toJS from 'components/toJS'
import ModalRow from 'containers/quickView/modal/ModalRow'
import ModalButtonRow from 'containers/quickView/modal/ModalButtonRow'
import WayFindButton from 'containers/quickView/modal/WayFindButton'
import DeskActionsButton from 'containers/quickView/spaceModal/claimFlow/DeskActionsButton'
import DeskClaimingFlow from 'containers/quickView/spaceModal/claimFlow/DeskClaimingFlow'
import {
  cancelDeskBooking,
  releaseSpace,
  bookDesk,
  claimSpace,
  changeClaimedSpace,
  changeBookedDesk,
  setDeleteOnBehalfPermission,
  getClaimOnBehalfPermission,
  getAssetCalendar,
} from 'containers/quickView/spaceModal/claimFlow/actions'
import { getRegisteredSlot } from 'containers/quickView/selectors'
import {
  getDateFilter,
  getIsFuture,
} from 'containers/searchPanel/filter/selectors'
import {
  getSelfClaims,
  getCurrentFloorId,
  getDeskIdsOnFloors,
  getSpaces,
  getFutureDeskbookingEnabled,
  getClientFeatures,
  getDisableTimeSelection,
} from 'containers/app/selectors'
import { getDeskBookings } from 'containers/mainPanel/bookingsList/selectors'
import { checkSlotInterval, checkIfCurrentBooking } from 'utils/utilsFunctions'
import {
  getHasClaimOnBehalfPermission,
  getEventsPerAssset,
} from 'containers/quickView/spaceModal/claimFlow/selectors'

const DeskClaimingFlowContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

class ClaimableActions extends Component {
  release = () => {
    const { space, setDeleteOnBehalfPermission, registeredSlot } = this.props
    const { eId, start, end } = this.eventsPerDate()
    setDeleteOnBehalfPermission(space.id, eId, start, end)
  }

  eventsPerDate() {
    const { eventsPerAssset, selectedDate, isFuture } = this.props
    return eventsPerAssset
      ? eventsPerAssset.find((event) => {
          const momentToCompare = isFuture
            ? selectedDate
            : getUnixTime(new Date()) / 60
          return (
            +event.end >= +momentToCompare && +event.start <= +momentToCompare
          )
        }) || {}
      : {}
  }

  book = (userId) => {
    const {
      space,
      deskBookingEnabled,
      bookDesk,
      claimSpace,
      registeredSlot,
    } = this.props
    if (deskBookingEnabled) {
      bookDesk(space.id, userId, registeredSlot)
    } else {
      claimSpace(space, registeredSlot)
    }
  }

  changeBooking = () => {
    const {
      changeBookedDesk,
      changeClaimedSpace,
      currentlyClaimedDesk,
      space,
      deskBookingEnabled,
    } = this.props
    if (deskBookingEnabled) {
      changeBookedDesk(currentlyClaimedDesk.id, space.id)
    } else {
      changeClaimedSpace(currentlyClaimedDesk, space)
    }
  }
  render() {
    const {
      space,
      claimedBySelf,
      currentlyClaimedDesk,
      hasClaimOnBehalfPermission,
      disableTimeSelection,
      spaceStatus,
    } = this.props

    return (
      <>
        <WayFindButton itemId={space.id} type="space" floorId={space.floorId} />
        {disableTimeSelection && claimedBySelf ? (
          <ModalRow>
            <ModalButtonRow>
              <DeskActionsButton
                secondary
                handleClick={() => this.release()}
                translation={<Translate id="quickView.button.release" />}
              />
            </ModalButtonRow>
          </ModalRow>
        ) : (
          <DeskClaimingFlow
            space={space}
            spaceStatus={spaceStatus}
            currentlyClaimedDesk={currentlyClaimedDesk}
            onBooking={this.book}
            onChangeBooking={this.changeBooking}
            claimedBySelf={claimedBySelf}
          />
        )}
      </>
    )
  }
}

ClaimableActions.propTypes = {
  isBooked: PropTypes.bool,
  deskBookingEnabled: PropTypes.bool,
  cancelDeskBooking: PropTypes.func,
  releaseSpace: PropTypes.func,
  claimSpace: PropTypes.func,
  bookDesk: PropTypes.func,
  changeBookedDesk: PropTypes.func,
  changeClaimedSpace: PropTypes.func,
  claimedBySelf: PropTypes.bool,
  currentlyClaimedDesk: PropTypes.object,
  space: PropTypes.object,
  getClaimOnBehalfPermission: PropTypes.func,
  getAssetCalendar: PropTypes.func,
  clientFeatures: PropTypes.object,
  hasClaimOnBehalfPermission: PropTypes.bool,
  eventsPerAssset: PropTypes.array,
  setDeleteOnBehalfPermission: PropTypes.func,
  selectedDate: PropTypes.number,
  isFuture: PropTypes.bool,
  registeredSlot: PropTypes.object,
  disableTimeSelection: PropTypes.bool,
  spaceStatus: PropTypes.string,
}

const currentClaimedDesk = (spaces, deskIds, claims) => {
  const claimedDeskId = deskIds.find((deskId) =>
    claims.find((claim) => claim.get('id') === deskId),
  )
  return spaces.get(`${claimedDeskId}`)
}

const isClaimedBySelf = (claims, space) => {
  const matchingClaim = claims.find(
    (claim) => claim.get('id') === space.id && checkIfCurrentBooking(claim),
  )
  return !!matchingClaim
}

const currentBookedDesk = (deskBookings, selectedDate, space) => {
  const currentBookingAtDesk = deskBookings.find((booking) => {
    if (booking.desk.id !== space.id) return false

    return checkSlotInterval(booking, selectedDate)
  })

  if (currentBookingAtDesk) return currentBookingAtDesk

  const currentBooking = deskBookings.find((booking) =>
    checkSlotInterval(booking, selectedDate),
  )
  return currentBooking
}

const isBookedBySelf = (space, currentBooking) => {
  if (!currentBooking) return false
  return (
    currentBooking.desk.id === space.id && checkIfCurrentBooking(currentBooking)
  )
}

const mapStateToProps = (state, props) => {
  const deskBookingEnabled = getFutureDeskbookingEnabled(state)
  const claims = getSelfClaims(state)
  const currentFloorId = getCurrentFloorId(state)
  const deskIds = getDeskIdsOnFloors(state).get(`${currentFloorId}`)
  const spaces = getSpaces(state)
  const deskBookings = getDeskBookings(state)
  const currentlyClaimedDesk = deskBookingEnabled
    ? currentBookedDesk(deskBookings, getDateFilter(state), props.space)
    : currentClaimedDesk(spaces, deskIds, claims)
  return {
    deskBookingEnabled: deskBookingEnabled,
    claimedBySelf: deskBookingEnabled
      ? isBookedBySelf(props.space, currentlyClaimedDesk)
      : isClaimedBySelf(claims, props.space),
    currentlyClaimedDesk: currentlyClaimedDesk,
    clientFeatures: getClientFeatures(state),
    hasClaimOnBehalfPermission: getHasClaimOnBehalfPermission(state),
    eventsPerAssset: getEventsPerAssset(state),
    selectedDate: getUnixTime(new Date(getDateFilter(state))) / 60,
    isFuture: getIsFuture(state),
    registeredSlot: getRegisteredSlot(state),
    disableTimeSelection: getDisableTimeSelection(state),
  }
}

export default connect(mapStateToProps, {
  cancelDeskBooking,
  releaseSpace,
  bookDesk,
  claimSpace,
  changeClaimedSpace,
  changeBookedDesk,
  getClaimOnBehalfPermission,
  getAssetCalendar,
  setDeleteOnBehalfPermission,
})(toJS(ClaimableActions))
