import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { getUnixTime } from 'date-fns'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'

import NoBookings from 'containers/mainPanel/bookingsList/NoBookings'
import SpaceBookingsList from 'containers/quickView/modal/SpaceBookingsList'
import {
  BookingInnerDivider,
  BookingOuterDivider,
} from 'containers/mainPanel/bookingsList/BookingDividers'
import SpaceBooking from 'containers/quickView/spaceModal/SpaceBooking'
import QuickViewBackdrop from 'containers/quickView/QuickViewBackdrop'
import Modal from 'containers/quickView/Modal'
import {
  clearQuickView,
  deleteBookingMessageToShow,
} from 'containers/quickView/actions'
import TimePickerArea from 'containers/quickView/TimePickerArea'
import {
  getSpaces,
  getMetaTypes,
  getFutureDeskbookingEnabled,
  getSelfCalendarItems,
  getFutureDesks,
  getFutureRooms,
  getDisableTimeSelection,
} from 'containers/app/selectors'
import toJS from 'components/toJS'
import ModalStatus from 'containers/quickView/modal/ModalStatus'
import SpaceInfo from 'containers/quickView/spaceModal/SpaceInfo'
import SpaceActions from 'containers/quickView/spaceModal/SpaceActions'
import ClaimableActions from 'containers/quickView/spaceModal/ClaimableActions'
import BookingFlow from 'containers/quickView/spaceModal/BookingFlow'
import SpaceProps from 'containers/quickView/spaceModal/SpaceProps'
import SpaceMeeting from 'containers/quickView/spaceModal/SpaceMeeting'
import { deselect } from 'api/unity'
import SpaceOccupancy from 'containers/quickView/spaceModal/SpaceOccupancy'
import {
  getStatus,
  getMeetingSlots,
} from 'containers/quickView/spaceModal/bookingFlow/selectors'
import ModalTitle from 'containers/quickView/modal/ModalTitle'
import ModalRow from 'containers/quickView/modal/ModalRow'
import {
  getDateFilter,
  getIsFuture,
} from 'containers/searchPanel/filter/selectors'
import { checkSlotInterval } from 'utils/utilsFunctions'
import {
  getAssetCalendar,
  closeClaimUI,
  setDeleteOnBehalfPermission,
} from 'containers/quickView/spaceModal/claimFlow/actions'
import {
  getEventsPerAssset,
  getIsFetching,
  getHasClaimOnBehalfPermission,
} from 'containers/quickView/spaceModal/claimFlow/selectors'
import { getBookingMessage } from 'containers/quickView/selectors'
import InfoBox from 'containers/flowPlanner/InfoBox'
import WrapperDrawerController from 'containers/quickView/modal/WrapperDrawerController'
import {
  closeConfirmationPopup,
  sendConfirmation,
} from 'containers/mainPanel/bookedResources/actions'
import StaticPopover from 'components/StaticPopover'

const DailyBookingsHeader = styled.span`
  padding: 35px 0 0 25px;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  color: ${(props) => props.theme.darkText};
  display: block;
`
const StyledModaRow = styled(ModalRow)`
  padding-right: 5px;
  padding-left: 8px;
`
const StyledModaRowBookings = styled(ModalRow)`
  border: none !important;
  padding-left: 9px;
`
const StyledSpaceInfo = styled(SpaceInfo)`
  padding-top: 35px !important;
`
const InfoBoxWrapper = styled.div`
  position: absolute;
  bottom: 145px;
  top: initial;
  left: 5px;
`

class SpaceModal extends Component {
  state = {
    showPopup: false,
    popupData: {},
  }
  componentDidMount() {
    const { space, getAssetCalendar } = this.props
    getAssetCalendar(space.id)
  }

  handleClose = () => {
    const { clearQuickView } = this.props
    clearQuickView()
    deselect()
  }

  handleBack = () => {
    const { closeClaimUI } = this.props
    closeClaimUI()
  }
  checkIfBooked = () => {
    const { spaceSlots, selectedDate } = this.props
    if (!spaceSlots.bookings) return false
    const isBooked = spaceSlots.bookings.find((booking) =>
      checkSlotInterval(booking, selectedDate),
    )
      ? true
      : false

    return isBooked
  }

  getSpaceStatus = () => {
    const {
      deskBookingEnabled,
      type,
      space,
      futureSpace,
      isFuture,
    } = this.props
    const bookableModes = ['BOOK', 'CLAIM']

    if (bookableModes.includes(type.space.mode) && isFuture && futureSpace) {
      return futureSpace.state
    }

    return space.status.state
  }

  render() {
    const {
      space,
      type,
      meetingStatus,
      selectedDate,
      eventsPerAssset,
      selfCalendar,
      setDeleteOnBehalfPermission,
      deleteBookingMessageToShow,
      bookingMessage,
      isFuture,
      disableTimeSelection,
      isFetching,
      hasClaimOnBehalfPermission,
    } = this.props

    const eventsForCurrentUser = selfCalendar
      ? selfCalendar.filter((item) => item.spaceId === space.id && item.owner)
      : []
    const currentMoment = getUnixTime(new Date()) / 60

    const currentSpaceEvent = eventsPerAssset
      ? eventsPerAssset.find(
          (event) => event.start < currentMoment && event.end > currentMoment,
        )
      : {}

    const deleteEvent = (booking) => {
      const { id: eventId, start, end } = booking
      setDeleteOnBehalfPermission(space.id, eventId, start, end)
      if (!isFetching) {
        this.setState({ showPopup: false })
      }
    }
    const currentUserEvent =
      eventsForCurrentUser && currentSpaceEvent
        ? eventsForCurrentUser.find(
            (event) =>
              event.start === currentSpaceEvent.start &&
              event.end === currentSpaceEvent.end,
          )
        : {}
    if (bookingMessage.length > 0) {
      setTimeout(() => {
        deleteBookingMessageToShow()
      }, 4000)
    }
    const isRoom = type.space.mode === 'BOOK'
    const isDesk = type.space.mode === 'CLAIM'
    const isAsset = isRoom || isDesk
    const bookableModes = ['FREE', 'BOOKED', 'CLAIMED', 'BUSY']
    const bookableDeskModes = ['FREE', 'CLAIMED', 'BUSY']
    const bookableRoomModes = ['FREE', 'BOOKED', 'BUSY']
    const bookableAsset = bookableModes.includes(space.status.state)
    const bookableDesk = bookableDeskModes.includes(space.status.state)
    const bookableRoom = bookableRoomModes.includes(space.status.state)
    const showDatePicker = isDesk && hasClaimOnBehalfPermission

    return (
      <WrapperDrawerController>
        <QuickViewBackdrop />
        <Modal asset={isAsset && bookableAsset ? 1 : 0}>
          <ModalStatus
            status={this.getSpaceStatus()}
            onClick={this.handleClose}
            onBack={this.handleBack}
            selectedDate={selectedDate}
          />
          <Grid container>
            <Grid item xs={isAsset && bookableAsset ? 7 : 12}>
              <StyledSpaceInfo
                space={space}
                type={type}
                status={this.getSpaceStatus()}
              />
              <SpaceProps spaceProps={space.info.props} />
              {type.space.mode === 'TAKE' && (
                <Fragment>
                  <ModalRow>
                    <ModalTitle>
                      <Translate id="filter.nonBookable" />
                    </ModalTitle>
                  </ModalRow>
                </Fragment>
              )}
              {bookableRoom && isRoom && (
                <TimePickerArea
                  key={selectedDate}
                  type={'ROOM'}
                  eventsPerAssset={eventsPerAssset}
                />
              )}

              {!disableTimeSelection && isDesk && bookableDesk && (
                <TimePickerArea
                  key={selectedDate}
                  type={'SPACE'}
                  eventsPerAssset={eventsPerAssset}
                />
              )}
              {bookingMessage && (
                <InfoBoxWrapper>
                  <InfoBox isBookingMessage showMessage={bookingMessage} />
                </InfoBoxWrapper>
              )}
              {bookableRoom && isRoom ? (
                <BookingFlow
                  space={space}
                  event={currentSpaceEvent}
                  owner={currentUserEvent && currentUserEvent.owner}
                  showMessage={bookingMessage}
                />
              ) : (
                <Fragment>
                  {bookableDesk && isDesk ? (
                    <ClaimableActions
                      space={space}
                      isBooked={this.checkIfBooked()}
                      bookingMessage
                      spaceStatus={this.getSpaceStatus()}
                    />
                  ) : (
                    <SpaceActions space={space} />
                  )}
                </Fragment>
              )}
            </Grid>
            {bookableAsset && isAsset && (
              <>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ marginRight: '-1px' }}
                />
                <Grid item xs={5}>
                  <DailyBookingsHeader>
                    <Translate id="quickView.form.bookings" />
                  </DailyBookingsHeader>
                  <SpaceBookingsList max400px={showDatePicker}>
                    {eventsPerAssset.length === 0 ||
                    eventsPerAssset.filter((booking) =>
                      checkSlotInterval(booking, selectedDate),
                    ).length === 0 ? (
                      <StyledModaRowBookings>
                        <Translate id="billboard.mainPanel.noBooking" />
                      </StyledModaRowBookings>
                    ) : (
                      eventsPerAssset
                        .filter((booking) =>
                          checkSlotInterval(booking, selectedDate),
                        )
                        .map((booking) => (
                          <StyledModaRow key={booking.id}>
                            <SpaceBooking
                              deleteEvent={deleteEvent}
                              booking={booking}
                              key={booking.id}
                              isRoom={isRoom}
                              space={space}
                              showConfirmationPopup={(popupData) =>
                                this.setState({
                                  showPopup: true,
                                  popupData: popupData,
                                })
                              }
                            />
                            <BookingInnerDivider />
                          </StyledModaRow>
                        ))
                    )}

                    <StaticPopover
                      closeConfirmationPopup={() =>
                        this.setState({ showPopup: false })
                      }
                      sendConfirmation={(popupData) => deleteEvent(popupData)}
                      popupData={this.state.popupData}
                      showPopup={this.state.showPopup}
                      isFetching={isFetching}
                      deleteReservationMessage={this.state.showPopup}
                    />
                  </SpaceBookingsList>
                </Grid>
              </>
            )}
          </Grid>
        </Modal>
      </WrapperDrawerController>
    )
  }
}

SpaceModal.propTypes = {
  clearQuickView: PropTypes.func,
  space: PropTypes.object,
  type: PropTypes.object,
  meetingStatus: PropTypes.string,
  spaceSlots: PropTypes.object,
  deskBookingEnabled: PropTypes.bool,
  selectedDate: PropTypes.PropTypes.instanceOf(Date),
  getAssetCalendar: PropTypes.func,
  eventsPerAssset: PropTypes.array,
  selfCalendar: PropTypes.array,
  closeClaimUI: PropTypes.func,
  setDeleteOnBehalfPermission: PropTypes.func,
  id: PropTypes.number,
  isFuture: PropTypes.bool,
  futureSpace: PropTypes.object,
  deleteBookingMessageToShow: PropTypes.func,
  bookingMessage: PropTypes.string,
  disableTimeSelection: PropTypes.bool,
  isFetching: PropTypes.bool,
  hasClaimOnBehalfPermission: PropTypes.bool,
}

const mapStateToProps = (state, props) => {
  const space = getSpaces(state).get(`${props.id}`)
  const typeId = space.get('typeId')
  const type = getMetaTypes(state).get(`${typeId}`)
  const meetingStatus = getStatus(state)
  const spaceSlots = getMeetingSlots(state)
  const isFuture = getIsFuture(state)
  const futureDesks = getFutureDesks(state)
  const futureRooms = getFutureRooms(state)
  const futureSpace =
    futureRooms && futureDesks
      ? [...futureDesks, ...futureRooms].find(
          (futureSpace) => futureSpace.id === props.id,
        )
      : {}

  const selectedDate = getDateFilter(state)
  const deskBookingEnabled = getFutureDeskbookingEnabled(state)
  const disableTimeSelection = getDisableTimeSelection(state)
  return {
    space,
    type,
    meetingStatus,
    spaceSlots,
    selectedDate,
    deskBookingEnabled,
    eventsPerAssset: getEventsPerAssset(state),
    selfCalendar: getSelfCalendarItems(state),
    futureSpace,
    isFuture,
    bookingMessage: getBookingMessage(state),
    disableTimeSelection,
    isFetching: getIsFetching(state),
    hasClaimOnBehalfPermission: getHasClaimOnBehalfPermission(state),
  }
}

export default connect(mapStateToProps, {
  clearQuickView,
  getAssetCalendar,
  closeClaimUI,
  setDeleteOnBehalfPermission,
  deleteBookingMessageToShow,
  closeConfirmationPopup,
  sendConfirmation,
})(toJS(SpaceModal))
