import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import logo from 'containers/mainPanel/logo.svg'
import { getLogo } from 'containers/app/selectors'

const StyledImg = styled.img`
  width: 100%;
  height: 60px;
  background-color: ${(props) => props.theme.logoBg};
  background-position: center;
`
class Logotype extends Component {
  render() {
    const { customLogo } = this.props

    return customLogo ? (
      <StyledImg src={customLogo} alt="logotype" />
    ) : (
      <StyledImg src={logo} alt="logotype" />
    )
  }
}

Logotype.propTypes = {
  customLogo: PropTypes.string,
}

const mapStateToProps = (state) => ({
  customLogo: getLogo(state),
})

export default connect(mapStateToProps)(Logotype)
