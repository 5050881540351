import { combineReducers } from 'redux-immutable'
import { Map } from 'immutable'

import {
  SET_DATASET,
  CLEAR_DATASET,
  SET_AVAILABILITY_FILTER,
  SET_BOOKABLE_FILTER,
  SET_PROP_FILTER,
  CLEAR_FILTER,
  SET_NUM_SEATS_FILTER,
  SET_DURATION_FILTER,
  SET_DATE,
  SET_MAP_INTERVAL,
} from 'containers/searchPanel/filter/constants'

const dataset = (state = '', action) => {
  switch (action.type) {
    case SET_DATASET:
      return action.dataset
    case CLEAR_DATASET:
      return ''
    default:
      return state
  }
}

const availability = (state = false, action) => {
  switch (action.type) {
    case SET_AVAILABILITY_FILTER:
      return action.value
    default:
      return state
  }
}

const bookable = (state = false, action) => {
  switch (action.type) {
    case SET_BOOKABLE_FILTER:
      return action.value
    default:
      return state
  }
}

const seatFilter = (state = 0, action) => {
  switch (action.type) {
    case SET_NUM_SEATS_FILTER:
      return action.value
    default:
      return state
  }
}

const durationFilter = (state = 0, action) => {
  switch (action.type) {
    case SET_DURATION_FILTER:
      return action.value
    default:
      return state
  }
}

const dateFilter = (state = new Date(), action) => {
  switch (action.type) {
    case SET_DATE:
      return action.value
    default:
      return state
  }
}

const props = (state = Map({}), action) => {
  switch (action.type) {
    case SET_PROP_FILTER:
      return state.merge(Map({ [action.key]: action.value }))
    default:
      return state
  }
}

const filterResetter = (state, action) => {
  if (action.type === CLEAR_FILTER) {
    state = undefined
  }

  return combineReducers({
    availability,
    bookable,
    seatFilter,
    durationFilter,
    props,
  })(state, action)
}

const mapIntelval = (state = {}, action) => {
  switch (action.type) {
    case SET_MAP_INTERVAL:
      return action.value
    case SET_DATE:
      return {}

    default:
      return state
  }
}

export default combineReducers({
  dataset,
  filters: filterResetter,
  mapIntelval,
  dateFilter,
})
