import {
  takeEvery,
  takeLatest,
  all,
  select,
  call,
  put,
  delay,
} from 'redux-saga/effects'
import Alert from 'react-s-alert'
import { getUnixTime } from 'date-fns'
import isEmpty from 'lodash/isEmpty'

import * as argus from 'api/argus'
import * as desks from 'api/desks'
import {
  MEETING_REQUEST,
  END_MEETING_REQUEST,
} from 'containers/quickView/spaceModal/bookingFlow/constants'
import {
  getCurrentFloorId,
  getSpaces,
  getMetaTypes,
  getFutureDeskbookingEnabled,
} from 'containers/app/selectors'
import { authenticateFailure, updateRequest } from 'containers/app/actions'
import {
  meetingSuccess,
  meetingFailure,
  endMeetingFailure,
  endMeetingSuccess,
  fetchMeetingSlotsSuccess,
  fetchMeetingSlotsFailure,
} from 'containers/quickView/spaceModal/bookingFlow/actions'
import { SELECT_SPACE_ID } from 'containers/quickView/constants'
import { getActiveSpaceId } from 'containers/quickView/selectors'
import {
  BOOK_DESK_SUCCESS,
  CANCEL_BOOKING_SUCCESS,
} from 'containers/quickView/spaceModal/claimFlow/constants'
import {
  getClaimOnBehalfPermission,
  getAssetCalendar,
} from 'containers/quickView/spaceModal/claimFlow/actions'
import { getDateFilter } from 'containers/searchPanel/filter/selectors'
import { setBookingMessageToShow } from 'containers/quickView/actions'
import { getEventsPerAssset } from 'containers/quickView/spaceModal/claimFlow/selectors'
import {
  FETCH_UPDATE_SUCCESS,
  WRONG_SLOT,
  ERROR_APPEARED,
  REACHED_CAPACITY,
  BOOKING_REGISTERED,
  ATTENDANCE_REGISTERED,
  BOOKING_REMOVED,
} from 'containers/app/constants'

function* meetingRequest({ spaceId, slot, subject }) {
  const dateSelected = yield select(getDateFilter)
  const assetEvents = yield select(getEventsPerAssset)
  const currentFloorId = yield select(getCurrentFloorId)
  const start =
    getUnixTime(
      new Date(dateSelected).setHours(
        slot.start.value.getHours(),
        slot.start.value.getMinutes(),
        0,
        0,
      ),
    ) / 60

  const end =
    getUnixTime(
      new Date(dateSelected).setHours(
        slot.end.value.getHours(),
        slot.end.value.getMinutes(),
        0,
        0,
      ),
    ) / 60
  try {
    const response = yield call(argus.postData, '/secure/calendar/create', {
      body: {
        spaceId,
        subject,
        start,
        end,
      },
    })
    const statusResponse = yield call(meetingStatus, response.ticket)
    yield put(getAssetCalendar(spaceId))

    yield put(meetingSuccess(statusResponse.item))
    const roomBookingSuccess = 'roomBookingSuccess'
    yield put(setBookingMessageToShow(BOOKING_REGISTERED))
    yield delay(500)
    yield put(updateRequest([currentFloorId]))
  } catch ({ error, response }) {
    if (error) {
      if (error.code === 400 || error.code === 409) {
        yield put(setBookingMessageToShow(WRONG_SLOT))
      }
      if (response.status === 401) {
        yield put(authenticateFailure())
      }
      if (error.code === 500) {
        yield put(setBookingMessageToShow(ERROR_APPEARED))
      }
      yield delay(500)
      yield put(meetingFailure(error.message))
    }
  }
}

function* meetingStatus(ticketId) {
  try {
    const response = yield call(
      argus.fetchData,
      `/secure/calendar/status?ticket=${ticketId}`,
    )
    if (!response.status) {
      yield delay(250)
      return yield call(meetingStatus, ticketId)
    } else {
      return response
    }
  } catch ({ error, response }) {
    console.error(error.message)
    Alert.error(
      `ERROR: Failed to fetch meeting status from server: ${error.message}`,
    )
    if (response.status === 401) {
      yield put(authenticateFailure())
    }
  }
}

function* endMeetingRequest({ meetingBody }) {
  try {
    const response = yield call(argus.postData, '/secure/calendar/cancel', {
      body: meetingBody,
    })
    const statusResponse = yield call(meetingStatus, response.ticket)
    if (statusResponse.error) {
      yield put(endMeetingFailure(statusResponse.error))
    } else {
      yield put(endMeetingSuccess(statusResponse.item))
    }
  } catch ({ error, response }) {
    console.error(error.message)
    yield put(endMeetingFailure(error.message))
    if (response.status === 401) {
      yield put(authenticateFailure())
    }
  }
}

function* meetingSlotsRoom({ id }) {
  const startMin = encodeURIComponent(
    Math.floor(new Date().getTime() / 1000 / 60),
  )

  try {
    const response = yield call(
      argus.fetchData,
      `/secure/available/timeslots?spaceId=${id}&startMin=${startMin}`,
      {
        body: {},
      },
    )
    yield all([
      put(getClaimOnBehalfPermission(id)),
      put(getAssetCalendar(id)),
      put(fetchMeetingSlotsSuccess(id, response)),
    ])
  } catch ({ error, response }) {
    yield put(fetchMeetingSlotsFailure(id))
    console.error(error.message)
    if (response.status === 401) {
      yield put(authenticateFailure())
    }
  }
}

function* bookingSlotsDesk({ id }) {
  try {
    const response = yield call(desks.getDeskEvents, id)
    yield all([
      put(getClaimOnBehalfPermission(id)),
      put(getAssetCalendar(id)),
      put(fetchMeetingSlotsSuccess(id, response)),
    ])
  } catch ({ error, response }) {
    yield put(fetchMeetingSlotsFailure(id))
    console.error(error.message)
    if (response.status === 401) {
      yield put(authenticateFailure())
    }
  }
}

function* meetingSlotsRequest({ id }) {
  const spaces = yield select(getSpaces)
  const spaceTypeId = spaces.getIn([`${id}`, 'typeId'])
  const types = yield select(getMetaTypes)
  const res = types.getIn([`${spaceTypeId}`, 'res'])
  //Dont fetch meeting slots for spaces that cant be booked
  if (res === 'type_room_book' || res === 'type_desk_take') {
    yield put(getAssetCalendar(id))
  }
  if (res === 'type_room_book') {
    yield call(meetingSlotsRoom, { id })
  } else if (res === 'type_desk_take') {
    const deskBookingEnabled = yield select(getFutureDeskbookingEnabled)
    if (!deskBookingEnabled) return
    yield call(bookingSlotsDesk, { id })
  } else {
    return null
  }
}

function* updateMeetingSlotsRequest() {
  const currentSpaceId = yield select(getActiveSpaceId)
  if (!currentSpaceId) return
  yield call(meetingSlotsRequest, { id: currentSpaceId })
}

function* watchMeetingRequests() {
  yield takeEvery(MEETING_REQUEST, meetingRequest)
}

function* watchEndMeetingRequest() {
  yield takeEvery(END_MEETING_REQUEST, endMeetingRequest)
}

function* watchFetchMeetingSlotsRequest() {
  yield takeLatest(SELECT_SPACE_ID, meetingSlotsRequest)
}

function* watchUpdateDeskBookingSlotsRequests() {
  yield takeEvery(
    [BOOK_DESK_SUCCESS, CANCEL_BOOKING_SUCCESS],
    updateMeetingSlotsRequest,
  )
}

export default function* meetingSagas() {
  yield all([
    watchMeetingRequests(),
    watchEndMeetingRequest(),
    watchFetchMeetingSlotsRequest(),
    watchUpdateDeskBookingSlotsRequests(),
  ])
}
