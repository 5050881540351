import { combineReducers } from 'redux-immutable'

import {
  FETCH_ATTENDANCE_SUCCESS,
  FETCH_ATTENDANCE_DETAILS_SUCCESS,
  FETCH_REGISTERED_ATTENDANCE_SUCCESS,
  FETCH_CAPACITY_SUCCESS,
  FETCH_ATTENDANCE_FILTERED_SUCCESS,
  CLEAR_SEARCH_STATE,
  SET_MESSAGE,
  DELETE_MESSAGE,
  CHANGE_MODAL_STYLE,
  CLEAR_STATE,
  CLEAR_DAILY_STATE,
} from 'containers/flowPlanner/constants'

const attendanceCapacity = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CAPACITY_SUCCESS:
      return action.attendanceData
    default:
      return state
  }
}
const attendanceDataset = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ATTENDANCE_SUCCESS: {
      return action.attendanceData
    }
    default:
      return state
  }
}

const attendanceDatasetDaysOnly = (state = [], action) => {
  switch (action.type) {
    case FETCH_ATTENDANCE_SUCCESS: {
      return [...state, ...action.attendanceData.data]
    }
    case CLEAR_STATE:
      return []
    default:
      return state
  }
}

const attendanceDetails = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ATTENDANCE_DETAILS_SUCCESS:
      return action.allAttendecesForSelectedDay
    default:
      return state
  }
}
const attendanceDetailsUsersOnly = (state = [], action) => {
  switch (action.type) {
    case FETCH_ATTENDANCE_DETAILS_SUCCESS:
      return [...state, ...action.allAttendecesForSelectedDay.data]
    case CLEAR_STATE:
    case CLEAR_DAILY_STATE:
      return []
    default:
      return state
  }
}
const colleagueAttendanceCount = (state = 0, action) => {
  switch (action.type) {
    case FETCH_ATTENDANCE_DETAILS_SUCCESS:
      return action.allAttendecesForSelectedDay.total
    case CLEAR_STATE:
    case CLEAR_DAILY_STATE:
      return 0
    default:
      return state
  }
}
const filteredAttendace = (state = [], action) => {
  switch (action.type) {
    case FETCH_ATTENDANCE_FILTERED_SUCCESS:
      return action.allAttendecesFiltered.data
    default:
      return state
  }
}
const filteredAttendaceUsersOnly = (state = [], action) => {
  switch (action.type) {
    case FETCH_ATTENDANCE_FILTERED_SUCCESS: {
      return [...action.allAttendecesFiltered.data]
    }
    case CLEAR_STATE:
    case CLEAR_SEARCH_STATE: {
      return []
    }
    default:
      return state
  }
}

const selfAttendance = (state = [], action) => {
  switch (action.type) {
    case FETCH_REGISTERED_ATTENDANCE_SUCCESS:
      return action.selfAttendance
    default:
      return state
  }
}

const showMessage = (state = '', action) => {
  switch (action.type) {
    case SET_MESSAGE:
      return action.message
    case DELETE_MESSAGE:
      return ''
    default:
      return state
  }
}

const changeModalPosition = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_MODAL_STYLE:
      return action.newStyle
    default:
      return state
  }
}

export default combineReducers({
  attendanceCapacity,
  attendanceDataset,
  attendanceDetails,
  selfAttendance,
  attendanceDatasetDaysOnly,
  attendanceDetailsUsersOnly,
  filteredAttendaceUsersOnly,
  filteredAttendace,
  showMessage,
  changeModalPosition,
  colleagueAttendanceCount,
})
