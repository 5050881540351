import { combineReducers } from 'redux-immutable'

import {
  CLOSE_CLAIM_UI,
  CLAIM_ON_BEHALF_SELECT,
  CLAIM_SPACE_REQUEST,
  CLAIM_SPACE_FAILURE,
  CLAIM_SPACE_SUCCESS,
  CLEAR_CLAIM_SPACE_ERROR,
  RELEASE_SPACE_REQUEST,
  RELEASE_SPACE_SUCCESS,
  RELEASE_SPACE_FAILURE,
  CANCEL_BOOKING_REQUEST,
  CANCEL_BOOKING_SUCCESS,
  CHANGE_CLAIMED_SPACE_REQUEST,
  CHANGE_BOOKED_DESK_REQUEST,
  BOOK_DESK_REQUEST,
  GET_CLAIM_ON_BEHALF_PERMISSION_REQUEST,
  GET_CLAIM_ON_BEHALF_PERMISSION_SUCCESS,
  GET_CLAIM_ON_BEHALF_PERMISSION_FAILURE,
  BOOK_DESK_SUCCESS,
  BOOK_DESK_FAILURE,
  GET_ASSET_CALENDAR_REQUEST,
  GET_ASSET_CALENDAR_SUCCESS,
  GET_ASSET_CALENDAR_FAILURE,
  SET_DELETE_ON_BEHALF_REQUEST,
  GET_МAX_ALLOWED_DAYS_SUCCESS,
  GET_МAX_ALLOWED_DURATION_SUCCESS,
} from 'containers/quickView/spaceModal/claimFlow/constants'
import {
  CLEAR_QUICK_VIEW,
  SELECT_SPACE_ID,
} from 'containers/quickView/constants'
import {
  FETCH_ATTENDANCE_REQUEST,
  FETCH_ATTENDANCE_SUCCESS,
  FETCH_ATTENDANCE_FAILURE,
  FETCH_ATTENDANCE_DETAILS_FAILURE,
  FETCH_ATTENDANCE_DETAILS_SUCCESS,
  FETCH_ATTENDANCE_DETAILS_REQUEST,
} from 'containers/flowPlanner/constants'

const isFetching = (state = false, action) => {
  switch (action.type) {
    case RELEASE_SPACE_REQUEST:
    case CLAIM_SPACE_REQUEST:
    case CHANGE_CLAIMED_SPACE_REQUEST:
    case CHANGE_BOOKED_DESK_REQUEST:
    case BOOK_DESK_REQUEST:
    case CANCEL_BOOKING_REQUEST:
    case GET_CLAIM_ON_BEHALF_PERMISSION_REQUEST:
    case GET_ASSET_CALENDAR_REQUEST:
    case SET_DELETE_ON_BEHALF_REQUEST:
    case FETCH_ATTENDANCE_REQUEST:
    case FETCH_ATTENDANCE_DETAILS_REQUEST:
      return true
    case RELEASE_SPACE_SUCCESS:
    case RELEASE_SPACE_FAILURE:
    case CLAIM_SPACE_SUCCESS:
    case CLAIM_SPACE_FAILURE:
    case CANCEL_BOOKING_SUCCESS:
    case GET_CLAIM_ON_BEHALF_PERMISSION_SUCCESS:
    case GET_CLAIM_ON_BEHALF_PERMISSION_FAILURE:
    case BOOK_DESK_SUCCESS:
    case BOOK_DESK_FAILURE:
    case FETCH_ATTENDANCE_SUCCESS:
    case FETCH_ATTENDANCE_FAILURE:
    case FETCH_ATTENDANCE_DETAILS_FAILURE:
    case FETCH_ATTENDANCE_DETAILS_SUCCESS:
    case GET_ASSET_CALENDAR_SUCCESS:
    case GET_ASSET_CALENDAR_FAILURE:
      return false
    default:
      return state
  }
}

const status = (state = 'closed', action) => {
  switch (action.type) {
    case SELECT_SPACE_ID:
      return 'select'
    case CLOSE_CLAIM_UI:
    case CLEAR_QUICK_VIEW:
    case BOOK_DESK_SUCCESS:
      return 'closed'
    case CLAIM_ON_BEHALF_SELECT:
      return 'selectOnBehalf'
    default:
      return state
  }
}

const toClaimId = (state = 0, action) => {
  switch (action.type) {
    case CLAIM_SPACE_REQUEST:
      return action.space.id
    case CLAIM_SPACE_SUCCESS:
    case CLAIM_SPACE_FAILURE:
      return 0
    default:
      return state
  }
}

const toReleaseId = (state = 0, action) => {
  switch (action.type) {
    case RELEASE_SPACE_REQUEST:
      return action.space.id
    case RELEASE_SPACE_SUCCESS:
    case RELEASE_SPACE_FAILURE:
      return 0
    default:
      return state
  }
}

const eventsPerAsset = (state = [], action) => {
  switch (action.type) {
    case GET_ASSET_CALENDAR_SUCCESS:
      return action.assetsEvents
    case GET_ASSET_CALENDAR_FAILURE:
      return []
    default:
      return state
  }
}

const hasClaimOnBehalfPermission = (state = false, action) => {
  switch (action.type) {
    case CLEAR_QUICK_VIEW:
    case SELECT_SPACE_ID:
    case GET_CLAIM_ON_BEHALF_PERMISSION_REQUEST:
    case GET_CLAIM_ON_BEHALF_PERMISSION_FAILURE:
    case GET_ASSET_CALENDAR_REQUEST:
      return state
    case GET_CLAIM_ON_BEHALF_PERMISSION_SUCCESS:
      return action.hasPermission
    default:
      return state
  }
}

const maxDaysAllowed = (state = 0, action) => {
  switch (action.type) {
    case CLEAR_QUICK_VIEW:
    case SELECT_SPACE_ID:
    case GET_CLAIM_ON_BEHALF_PERMISSION_REQUEST:
    case GET_CLAIM_ON_BEHALF_PERMISSION_FAILURE:
    case GET_ASSET_CALENDAR_REQUEST:
      return 0
    case GET_МAX_ALLOWED_DAYS_SUCCESS:
      return +action.maxDays
    default:
      return state
  }
}

const maxDurationAllowed = (state = 0, action) => {
  switch (action.type) {
    case CLEAR_QUICK_VIEW:
    case SELECT_SPACE_ID:
    case GET_CLAIM_ON_BEHALF_PERMISSION_REQUEST:
    case GET_CLAIM_ON_BEHALF_PERMISSION_FAILURE:
    case GET_ASSET_CALENDAR_REQUEST:
      return 0
    case GET_МAX_ALLOWED_DURATION_SUCCESS:
      return +action.maxDuration
    default:
      return state
  }
}

const claimError = (state = null, action) => {
  switch (action.type) {
    case CLEAR_QUICK_VIEW:
    case SELECT_SPACE_ID:
    case CLAIM_SPACE_REQUEST:
    case CLAIM_SPACE_SUCCESS:
    case CLEAR_CLAIM_SPACE_ERROR:
      return null
    case CLAIM_SPACE_FAILURE:
      return action.message
    default:
      return state
  }
}

export default combineReducers({
  isFetching,
  status,
  toClaimId,
  toReleaseId,
  hasClaimOnBehalfPermission,
  claimError,
  eventsPerAsset,
  maxDurationAllowed,
  maxDaysAllowed,
})
