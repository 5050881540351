import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'

import MainPanelWrapper from 'containers/mainPanel/MainPanelWrapper'
import BuildingPicker from 'containers/mainPanel/BuildingPicker'
import FloorPicker from 'containers/mainPanel/FloorPicker'
import AngleButtons from 'containers/mainPanel/AngleButtons'
import FlexSpacer from 'components/FlexSpacer'
import PanelButton from 'containers/mainPanel/PanelButton'
import PanelFoot from 'containers/mainPanel/PanelFoot'
import Logotype from 'containers/mainPanel/Logotype'
import Statistics from 'containers/mainPanel/Statistics'
import LocalePicker from 'containers/mainPanel/LocalePicker'
import SettingsPicker from 'containers/mainPanel/SettingsPicker'
import LogoutButton from 'containers/mainPanel/LogoutButton'
import BookingsList from 'containers/mainPanel/BookingsList'
import BookedResources from 'containers/mainPanel/BookedResources'
import { updateRequest, togglePolling } from 'containers/app/actions'
import {
  getCurrentFloorId,
  getPollingActive,
  getClientFeatures,
} from 'containers/app/selectors'
import { icons } from 'components/icon/icons'
import { getIsFuture } from 'containers/searchPanel/filter/selectors'
import TimePickerFilter from 'containers/mainPanel/TimePickerFilter'
import FlowPlannerPicker from 'containers/mainPanel/FlowPlannerPicker'

class MainPanel extends Component {
  handleUpdate = () => {
    const { currentFloorId, updateRequest } = this.props
    updateRequest([currentFloorId])
  }

  handleTogglePolling = () => {
    const { togglePolling } = this.props
    togglePolling()
  }
  render() {
    const { pollingActive, isFuture, clientFeatures } = this.props
    const showFlowPlanner = clientFeatures
      ? clientFeatures.getIn(['attendance', 'enabled'])
      : false

    return (
      <MainPanelWrapper>
        <Logotype />
        <BookingsList />
        <TimePickerFilter />
        <FlexSpacer />
        <Statistics isFuture={isFuture} />
        {process.env.NODE_ENV === 'development' && (
          <React.Fragment>
            <PanelButton iconName={icons.UPDATE} onClick={this.handleUpdate}>
              <Translate id="billboard.mainPanel.update" />
            </PanelButton>
            <PanelButton
              iconName={icons.ROTATE_LEFT}
              onClick={this.handleTogglePolling}
              active={pollingActive}
            >
              <Translate id="billboard.mainPanel.poll" />
            </PanelButton>
          </React.Fragment>
        )}
        {showFlowPlanner && <FlowPlannerPicker />}
        <BookedResources />
        <BuildingPicker />
        <FloorPicker />
        <SettingsPicker />
        <PanelFoot />
      </MainPanelWrapper>
    )
  }
}

MainPanel.propTypes = {
  currentFloorId: PropTypes.number,
  updateRequest: PropTypes.func,
  togglePolling: PropTypes.func,
  pollingActive: PropTypes.bool,
  isFuture: PropTypes.bool,
  clientFeatures: PropTypes.object,
}

const mapStateToProps = (state) => ({
  currentFloorId: getCurrentFloorId(state),
  pollingActive: getPollingActive(state),
  isFuture: getIsFuture(state),
  clientFeatures: getClientFeatures(state),
})

export default connect(mapStateToProps, { updateRequest, togglePolling })(
  MainPanel,
)
