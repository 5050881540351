import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Translate } from 'react-localize-redux'

import ModalRow from 'containers/quickView/modal/ModalRow'
import ModalButtonRow from 'containers/quickView/modal/ModalButtonRow'
import ModalButton from 'containers/quickView/modal/ModalButton'
import Icon from 'components/Icon'
import {
  canBookMeeting,
  isAvailableNow,
} from 'containers/quickView/spaceModal/utils'
import WayFindButton from 'containers/quickView/modal/WayFindButton'
import { icons } from 'components/icon/icons'

class BookingClosed extends Component {
  render() {
    const { space, onOpen } = this.props

    return (
      <ModalRow>
        <ModalButtonRow>
          <WayFindButton
            itemId={space.id}
            type="space"
            floorId={space.floorId}
          />
          <ModalButton
            primary
            disabled={
              !isAvailableNow(space.status.state) ||
              !canBookMeeting(space.meetings)
            }
            onClick={onOpen}
          >
            <Icon name={icons.CALENDAR} />
            <span>
              <Translate id="quickView.button.instantMeeting" />
            </span>
          </ModalButton>
        </ModalButtonRow>
      </ModalRow>
    )
  }
}

BookingClosed.propTypes = {
  onOpen: PropTypes.func,
  space: PropTypes.object,
}

export default BookingClosed
