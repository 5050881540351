import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { differenceInMinutes } from 'date-fns'

import {
  getStatus,
  getCurrentMeeting,
} from 'containers/quickView/spaceModal/bookingFlow/selectors'
import { getRegisteredSlot } from 'containers/quickView/selectors'
import { setDeleteOnBehalfPermission } from 'containers/quickView/spaceModal/claimFlow/actions'
import {
  openBookingUI,
  closeBookingUI,
  meetingRequest,
  endMeeting,
  endMeetingConfirm,
} from 'containers/quickView/spaceModal/bookingFlow/actions'
import { clearQuickView } from 'containers/quickView/actions'
import BookingSelect from 'containers/quickView/spaceModal/bookingFlow/BookingSelect'
import BookingSuccess from 'containers/quickView/spaceModal/bookingFlow/BookingSuccess'
import BookingFailure from 'containers/quickView/spaceModal/bookingFlow/BookingFailure'
import BookingClosed from 'containers/quickView/spaceModal/bookingFlow/BookingClosed'
import BookingCancelSuccess from 'containers/quickView/spaceModal/bookingFlow/BookingCancelSuccess'
import { startWayfinding } from 'containers/app/actions'

class BookingFlow extends Component {
  handleEnd = () => {
    const { endMeeting, currentMeeting } = this.props
    endMeeting({
      unique: currentMeeting,
    })
  }

  handleDelete = () => {
    const { space, event, setDeleteOnBehalfPermission } = this.props
    const { id: eventId, start, end } = event
    setDeleteOnBehalfPermission(space.id, eventId, start, end)
  }
  handleEndConfirm = () => {
    const { endMeetingConfirm } = this.props
    endMeetingConfirm()
  }

  handleClose = () => {
    const { closeBookingUI } = this.props
    closeBookingUI()
  }

  handleOpen = () => {
    const { openBookingUI } = this.props
    openBookingUI()
  }

  handleConfirm = () => {
    const { clearQuickView } = this.props
    clearQuickView()
  }

  handleBooking = (diff, subject) => {
    const { space, meetingRequest, registeredSlot } = this.props
    meetingRequest(space.id, registeredSlot, subject)
  }

  renderView = () => {
    const { status, space, owner } = this.props

    return (
      <BookingSelect
        space={space}
        onClose={this.handleClose}
        onBooking={this.handleBooking}
      />
    )
  }

  render() {
    return this.renderView()
  }
}

BookingFlow.propTypes = {
  clearQuickView: PropTypes.func,
  closeBookingUI: PropTypes.func,
  currentMeeting: PropTypes.string,
  endMeeting: PropTypes.func,
  endMeetingConfirm: PropTypes.func,
  meetingRequest: PropTypes.func,
  openBookingUI: PropTypes.func,
  space: PropTypes.object,
  status: PropTypes.string,
  event: PropTypes.object,
  setDeleteOnBehalfPermission: PropTypes.func,
  owner: PropTypes.bool,
  registeredSlot: PropTypes.object,
}

const mapStateToProps = (state) => ({
  status: getStatus(state),
  currentMeeting: getCurrentMeeting(state),
  registeredSlot: getRegisteredSlot(state),
})

export default connect(mapStateToProps, {
  closeBookingUI,
  openBookingUI,
  meetingRequest,
  clearQuickView,
  startWayfinding,
  endMeeting,
  endMeetingConfirm,
  setDeleteOnBehalfPermission,
})(BookingFlow)
