const unityEvent = (payload) =>
  window.postMessage({ type: 'toUnity', payload }, window.location.origin)

export const setCameraAngle = (angle) => {
  unityEvent({
    command: 'camera_angle',
    content: {
      angle,
    },
  })
}

export const setCameraRotation = (degrees) => {
  unityEvent({
    command: 'rotate',
    content: {
      type: 'set',
      degrees,
    },
  })
}

export const rotateCamera = (degrees) => {
  unityEvent({
    command: 'rotate',
    content: {
      type: 'add',
      degrees,
    },
  })
}

export const zoomCamera = (delta) => {
  unityEvent({
    command: 'zoom',
    content: {
      type: 'add',
      value: delta,
    },
  })
}

export const setCameraZoom = (value) => {
  unityEvent({
    command: 'zoom',
    content: {
      type: 'set',
      value,
    },
  })
}

export const startMount = (elementId) => {
  unityEvent({
    command: 'start',
    content: elementId,
  })
}

export const startSettings = (settings) => {
  unityEvent({
    command: 'settings',
    content: settings,
  })
}

export const startUser = (id, token) => {
  unityEvent({
    command: 'initMe',
    content: {
      id,
      token,
    },
  })
}

export const updatePosition = (position, floorId, useRotation = false) => {
  unityEvent({
    command: 'position_update',
    content: {
      floorId: floorId,
      useRotation: useRotation,
      pos: {
        x: position.x,
        y: position.y,
        r: position.r,
      },
    },
  })
}

export const positionRemove = () => {
  unityEvent({
    command: 'position_remove',
    content: {},
  })
}

export const contentUpdate = (update) => {
  unityEvent({
    command: 'contentUpdate',
    content: update,
  })
}

export const showBuilding = (buildingId) => {
  unityEvent({
    command: 'selectBuilding',
    content: {
      buildingId,
    },
  })
}

export const showFloor = (floorId, forced = false) => {
  unityEvent({
    command: 'show_floor',
    content: {
      floorId,
      forced,
    },
  })
}

export const buildingView = (floorId) => {
  unityEvent({
    command: 'building_view',
    content: {
      floorId,
    },
  })
}

export const locate = (floorId, assetType, assetId) =>
  unityEvent({
    command: 'locate',
    content: {
      floorId,
      assetType,
      assetId,
    },
  })

export const deselect = () =>
  unityEvent({
    command: 'deselect',
    content: {},
  })

export const wayfind = (floorId, assetType, assetId) => {
  unityEvent({
    command: 'wayfinding',
    content: {
      stop: false,
      toFloorId: floorId,
      toAssetType: assetType,
      toAssetId: assetId,
    },
  })
}

export const stopWayfinding = () => {
  unityEvent({
    command: 'wayfinding',
    content: {
      stop: true,
    },
  })
}

export const filter = (update) => {
  unityEvent({
    command: 'filter',
    content: update,
  })
}
