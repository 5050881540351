import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { Translate, getTranslate } from 'react-localize-redux'

import {
  getBuildings,
  getFloorIdsOnBuildings,
  getFloors,
  getSpaces,
  getMetaTypes,
} from 'containers/app/selectors'
import PanelButton from 'containers/mainPanel/PanelButton'
import toJS from 'components/toJS'
import { icons } from 'components/icon/icons'
import Icon from 'components/Icon'
import { getLocalizedTime, getLocalizedDateAndTime } from 'utils/utilsFunctions'
import { openConfirmationPopup } from 'containers/mainPanel/bookedResources/actions'
import { locate } from 'api/unity'
import { selectSpaceId } from 'containers/quickView/actions'
import { ROOM, SPACE } from 'utils/appVars'
import { getTimeFormat } from 'containers/mainPanel/timeFormats/selectors'

const BookingHolder = styled(PanelButton)`
  height: auto;
  padding: 15px 46px 15px 15px;
  align-items: flex-start;
  position: relative;
`
const DeleteButton = styled.div`
  position: absolute;
  right: 25px;
  top: 49px;
  i {
    margin: 0 !important;
  }
`

const InfoHolder = styled.div`
  display: flex;
  line-height: 1.4;
`
const TitleHolder = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
`
const SpaceTitle = styled.div`
  font-weight: 500;
`

const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
`

const NoBooking = styled.div`
  min-width: 190px;
  padding: 20px;
  background-color: ${(props) => props.theme.panelBg};
`
const LocateLink = styled.div`
  :hover {
    text-decoration: underline;
  }
`
const ResourceBookingText = styled.p`
  display: flex;
  flex-direction: column;
  margin: 0;
  flex: 1;
`
const ResourceItem = ({
  booking,
  active,
  onClick,
  iconName,
  floorIdsOnBuildings,
  buildings,
  floors,
  openConfirmationPopup,
  selfAttendance,
  translate,
  spaceMetaTypes,
  spaces,
  selectSpaceId,
  timeFormat,
}) => {
  if (!booking)
    return (
      <NoBooking>
        <Translate id="billboard.mainPanel.noBooking" />
      </NoBooking>
    )

  const startTime = getLocalizedTime(booking.start)
  const endTime = getLocalizedTime(booking.end)

  const getResourceTypeName = () => {
    if (
      booking.resources &&
      booking.resources.length > 0 &&
      booking.resources[0].typeId
    ) {
      const spaceTypeId = booking.resources[0].typeId
      const typeName = spaceTypeId ? spaceMetaTypes[spaceTypeId].space.desc : ''
      return typeName
    }
    return null
  }

  const getBookingName = () => {
    const typeName = getResourceTypeName()
    switch (typeName) {
      case ROOM:
        return booking.resources[0].name
      case null:
        return ''
      default:
        return booking.name
    }
  }

  const getResourceType = () => {
    const typeName = getResourceTypeName()
    switch (typeName) {
      case ROOM:
        return translate('filter.title.room')
      case null:
        return 'FlowPlanner'
      default:
        return translate('filter.title.space')
    }
  }

  const getIconName = () => {
    const typeName = getResourceTypeName()
    switch (typeName) {
      case ROOM:
        return typeName
      default:
        return iconName
    }
  }

  const getBookingBuildingName = () => {
    if (booking.resources.length > 0 && booking.resources[0].floorId) {
      const floorId = booking.resources[0].floorId
      let buildingId = 0

      for (const building in floorIdsOnBuildings) {
        if (floorIdsOnBuildings[building].includes(floorId)) {
          buildingId = building
          break
        }
      }
      const buildingName = buildings[buildingId]['info']['name']
      const floorName = floors[floorId]['info']['name']
      return `${buildingName}, ${floorName}`
    }
    return ''
  }

  const reservationName =
    booking && booking.name
      ? getBookingName()
      : translate('flowPlanner.officeAttendance')

  const buildingName = booking.buildingId
    ? buildings[booking.buildingId]['info']['name']
    : getBookingBuildingName()

  const bookingResourceType = getResourceType()
  const dataForPopup = {
    id: booking.id,
    reservationName,
    selfAttendance: selfAttendance ?? false,
  }
  const iconToShow = getIconName()
  const spaceId =
    booking.resources &&
    booking.resources.length > 0 &&
    booking.resources[0].typeId
      ? booking.resources[0].id
      : null
  const fullSpace = spaceId ? spaces[spaceId] : null

  const locateSpace = (spaceId, fullSpace) => {
    if (fullSpace) {
      locate(fullSpace.floorId, SPACE, fullSpace.id)
      selectSpaceId(spaceId)
    }
  }

  return (
    <BookingHolder active={active}>
      <ContentHolder>
        <TitleHolder>{bookingResourceType}</TitleHolder>

        <InfoHolder>
          <div>
            <Icon name={icons[iconToShow]} />
          </div>
          <div>
            <SpaceTitle>{reservationName}</SpaceTitle>
            <ResourceBookingText>
              <span>{getLocalizedDateAndTime(booking.start, timeFormat)}</span>
              <span>{getLocalizedDateAndTime(booking.end, timeFormat)}</span>
            </ResourceBookingText>

            <div>{buildingName}</div>
            {fullSpace && (
              <LocateLink onClick={() => locateSpace(spaceId, fullSpace)}>
                <Translate id="quickView.wayfinding" />
              </LocateLink>
            )}
          </div>
          <DeleteButton onClick={() => openConfirmationPopup(dataForPopup)}>
            <Icon name={icons.CLOSE} />
          </DeleteButton>
        </InfoHolder>
      </ContentHolder>
    </BookingHolder>
  )
}

ResourceItem.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  booking: PropTypes.object,
  iconName: PropTypes.string,
  floorIdsOnBuildings: PropTypes.object,
  buildings: PropTypes.object,
  floors: PropTypes.object,
  openConfirmationPopup: PropTypes.func,
  selfAttendance: PropTypes.bool,
  translate: PropTypes.func,
  spaceMetaTypes: PropTypes.object,
  spaces: PropTypes.object,
  selectSpaceId: PropTypes.func,
  timeFormat: PropTypes.string,
}

const mapStateToProps = (state) => ({
  floorIdsOnBuildings: getFloorIdsOnBuildings(state),
  buildings: getBuildings(state),
  floors: getFloors(state),
  translate: getTranslate(state.get('localize')),
  spaceMetaTypes: getMetaTypes(state),
  spaces: getSpaces(state),
  timeFormat: getTimeFormat(state),
})

export default connect(mapStateToProps, {
  openConfirmationPopup,
  selectSpaceId,
})(toJS(ResourceItem))
