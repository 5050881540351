import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component } from 'react'

import {
  addUserToSelected,
  deleteUserFromSelected,
} from 'containers/searchPanel/results/actions'
import { getSelectedColleagues } from 'containers/searchPanel/results/selectors'
import { getSelfId } from 'containers/app/selectors'

const StarButton = styled.div`
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: transparent;
  background-image: url(./star_outline.svg);
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    cursor: pointer;
  }
  ${(props) =>
    props.active &&
    css`
      background-image: url(./star_filled.svg);
    `}
  ${(props) =>
    props.modalStar &&
    css`
      float: right;
      width: 25px;
      height: 25px;
    `}
`
class SelectColleague extends Component {
  selectAColleague(e) {
    const { addUserToSelected, deleteUserFromSelected, id } = this.props
    const isUserSelected = this.checkIfSelected()
    if (!isUserSelected) {
      addUserToSelected(id)
    } else {
      deleteUserFromSelected(id)
    }
    e.stopPropagation()
  }

  checkIfSelected() {
    const { selectedUsers, id } = this.props

    return selectedUsers && selectedUsers.find((user) => user.id === id)
      ? true
      : false
  }

  render() {
    const { modalStar, id, selfId } = this.props
    if (id === selfId) return null
    const selectedUser = this.checkIfSelected()
    return (
      <StarButton
        modalStar={modalStar}
        onClick={(e) => this.selectAColleague(e)}
        active={selectedUser}
      />
    )
  }
}

SelectColleague.propTypes = {
  addUserToSelected: PropTypes.func,
  deleteUserFromSelected: PropTypes.func,
  id: PropTypes.number,
  selectedUsers: PropTypes.array,
  modalStar: PropTypes.bool,
  selfId: PropTypes.number,
}

const mapStateToProps = (state) => ({
  selectedUsers: getSelectedColleagues(state),
  selfId: getSelfId(state),
})

export default connect(mapStateToProps, {
  addUserToSelected,
  deleteUserFromSelected,
})(SelectColleague)
