import React from 'react'
import styled from 'styled-components'

const StyledVersion = styled.span`
  position: fixed;
  left: 16px;
  bottom: 20px;
  font-size: 1.4rem;
  line-height: 1.4;
  color: rgba(245, 245, 220, 0.8);
  z-index: -1;
`

const Version = () => (
  <StyledVersion>
    {process.env.REACT_APP_NAME}
    <br />
    {process.env.REACT_APP_VERSION}
  </StyledVersion>
)

export default Version
