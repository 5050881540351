import { combineReducers } from 'redux-immutable'
import { Map } from 'immutable'

import {
  FETCH_PROFILE_PICTURE_SUCCESS,
  FETCH_PROFILE_PICTURE_FAILURE,
  SET_ACTIVE_BY_INDEX,
  CLEAR_ACTIVE_BY_INDEX,
  GET_SELECTED_COLLEAGUES,
  GET_SELECTED_COLLEAGUES_SUCCESS,
  SELECT_A_COLLEAGUE,
} from 'containers/searchPanel/results/constants'
import {
  CLEAR_QUICK_VIEW,
  SELECT_SIGN_ID,
  SELECT_SPACE_ID,
} from 'containers/quickView/constants'
import { UNITY_LONG_CLICKED } from 'containers/unityLoader/constants'
import { SET_DATASET, SET_DATE } from 'containers/searchPanel/filter/constants'
import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_CLEAR,
  FUTURE_SEARCH_SUCCESS,
} from 'containers/searchPanel/search/constants'

const profilePictures = (state = Map([]), action) => {
  switch (action.type) {
    case FETCH_PROFILE_PICTURE_SUCCESS:
    case FETCH_PROFILE_PICTURE_FAILURE: {
      return state.merge(Map({ [action.userId]: action.imageUrl || null }))
    }
    default:
      return state
  }
}

const activeByIndex = (state = null, action) => {
  switch (action.type) {
    case SET_ACTIVE_BY_INDEX:
      return action.index
    case CLEAR_ACTIVE_BY_INDEX:
    case CLEAR_QUICK_VIEW:
    case SELECT_SPACE_ID:
    case SELECT_SIGN_ID:
    case UNITY_LONG_CLICKED:
    case SET_DATASET:
    case SEARCH_REQUEST:
      return null
    default:
      return state
  }
}

const showSearchResults = (state = false, action) => {
  switch (action.type) {
    case SEARCH_SUCCESS:
    case FUTURE_SEARCH_SUCCESS:
      return true
    case SEARCH_CLEAR:
    case SET_DATE:
      return false
    default:
      return state
  }
}

const getSelectedColleagues = (state = [], action) => {
  switch (action.type) {
    case GET_SELECTED_COLLEAGUES_SUCCESS:
      return action.users
    default:
      return state
  }
}

const selectColleague = (state = '', action) => {
  switch (action.type) {
    case SELECT_A_COLLEAGUE:
      return action
    default:
      return state
  }
}

export default combineReducers({
  profilePictures,
  activeByIndex,
  showSearchResults,
  getSelectedColleagues,
  selectColleague,
})
