import PropTypes from 'prop-types'
import React from 'react'
import { Field, reduxForm } from 'redux-form/immutable'
import styled from 'styled-components'

import Row from 'components/form/Row'
import Label from 'components/form/Label'
import Input from 'components/form/Input'
import Password from 'components/form/Password'
import SubmitButton from 'components/SubmitButton'
import Icon from 'components/Icon'
import { icons } from 'components/icon/icons'

const validate = (values) => {
  const errors = {}
  if (!values.username) {
    errors.username = 'Required'
  }
  if (!values.password) {
    errors.password = 'Required'
  }
  return errors
}
const LoginRow = styled(Row)`
  width: 350px;
  display: block;
  margin: 0 auto;
  > label {
    margin: 10px;
  }
  > div {
    margin: 10px;
  }
  > button {
    width: 70%;
    margin: 20px auto;
    display: block;
  }
`
const BackButton = styled.div`
  display: flex;
  margin: 20px 10px 30px 10px;
  align-items: center;
  font-weight: 500;
  :hover {
    cursor: pointer;
  }
  span {
    display: block;
    font-size: 1.5rem;
  }
  i {
    font-weight: 700;
    font-size: 2.5rem;
    padding-top: 3px;
  }
`

const LoginForm = ({
  handleSubmit,
  pristine,
  invalid,
  submitting,
  hideLoginTitle,
}) => (
  <>
    <BackButton onClick={hideLoginTitle}>
      <span>
        <Icon name={icons.LEFT_ARROW} />
      </span>
      <span>Back</span>
    </BackButton>
    <form onSubmit={handleSubmit}>
      <LoginRow>
        <Label htmlFor="username">Username</Label>
        <Field
          name="username"
          component={Input}
          type="text"
          autoComplete="username"
          autoFocus
          required
        />
      </LoginRow>
      <LoginRow>
        <Label htmlFor="password">Password</Label>
        <Field
          name="password"
          component={Password}
          autoComplete="current-password"
          required
        />
      </LoginRow>
      <LoginRow>
        <SubmitButton
          submitting={submitting}
          disabled={pristine || invalid}
          primary
        >
          Log in
        </SubmitButton>
        {/* {errorMessage && <FormError>{errorMessage}</FormError>} */}
      </LoginRow>
    </form>
  </>
)

LoginForm.propTypes = {
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  hideLoginTitle: PropTypes.func,
}

export default reduxForm({
  form: 'login',
  validate,
})(LoginForm)
