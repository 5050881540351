export const FETCH_ATTENDANCE_REQUEST =
  'containers/filter/FETCH_ATTENDANCE_REQUEST'
export const FETCH_ATTENDANCE_SUCCESS =
  'containers/filter/FETCH_ATTENDANCE_SUCCESS'
export const FETCH_ATTENDANCE_FAILURE =
  'containers/filter/FETCH_ATTENDANCE_FAILURE'
export const REGISTER_ATTENDANCE_REQUEST =
  'containers/filter/REGISTER_ATTENDANCE_REQUEST'
export const REGISTER_ATTENDANCE_FAILURE =
  'containers/filter/REGISTER_ATTENDANCE_FAILURE'
export const DELETE_ATTENDANCE_REQUEST =
  'containers/filter/DELETE_ATTENDANCE_REQUEST'
export const DELETE_ATTENDANCE_SUCCESS =
  'containers/filter/DELETE_ATTENDANCE_SUCCESS'
export const DELETE_ATTENDANCE_FAILURE =
  'containers/filter/DELETE_ATTENDANCE_FAILURE'
export const FETCH_REGISTERED_ATTENDANCE_FAILURE =
  'containers/filter/FETCH_REGISTERED_ATTENDANCE_FAILURE'
export const FETCH_REGISTERED_ATTENDANCE_SUCCESS =
  'containers/filter/FETCH_REGISTERED_ATTENDANCE_SUCCESS'
export const FETCH_REGISTERED_ATTENDANCE_REQUEST =
  'containers/filter/FETCH_REGISTERED_ATTENDANCE_REQUESTexport'
export const FETCH_ATTENDANCE_DETAILS_FAILURE =
  'containers/filter/FETCH_ATTENDANCE_DETAILS_FAILURE'
export const FETCH_ATTENDANCE_DETAILS_SUCCESS =
  'containers/filter/FETCH_ATTENDANCE_DETAILS_SUCCESS'
export const FETCH_ATTENDANCE_DETAILS_REQUEST =
  'containers/filter/FETCH_ATTENDANCE_DETAILS_REQUEST'
export const FETCH_CAPACITY_SUCCESS = 'containers/filter/FETCH_CAPACITY_SUCCESS'
export const FETCH_CAPACITY_REQUEST = 'containers/filter/FETCH_CAPACITY_REQUEST'
export const FETCH_CAPACITY_FAILURE = 'containers/filter/FETCH_CAPACITY_FAILURE'
export const FETCH_ATTENDANCE_FILTERED_SUCCESS =
  'containers/filter/FETCH_ATTENDANCE_FILTERED_SUCCESS'
export const CLEAR_SEARCH_STATE = 'containers/filter/CLEAR_SEARCH_STATE'
export const SET_MESSAGE = 'containers/filter/SET_MESSAGE'
export const DELETE_MESSAGE = 'containers/filter/DELETE_MESSAGE'
export const FETCH_UNIQUE_IMAGES = 'containers/filter/FETCH_UNIQUE_IMAGES'
export const CHANGE_MODAL_STYLE = 'containers/filter/CHANGE_MODAL_STYLE'
export const CLEAR_STATE = 'containers/filter/CLEAR_STATE'
export const CLEAR_DAILY_STATE = 'containers/filter/CLEAR_DAILY_STATE'
