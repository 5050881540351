import { combineReducers } from 'redux-immutable'

import bookingFlow from 'containers/quickView/spaceModal/bookingFlow/reducers'
import claimFlow from 'containers/quickView/spaceModal/claimFlow/reducers'
import {
  SELECT_SPACE_ID,
  SELECT_SIGN_ID,
  SELECT_USER,
  CLEAR_QUICK_VIEW,
  SEARCH_USERS_REQUEST,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_FAILURE,
  SEARCH_USERS_CLEAR,
  REGISTER_SLOT,
  SELECT_USER_SUCCESS,
  SELECT_USER_FAILURE,
  SET_BOOKING_MESSAGE,
  DELETE_BOOKING_MESSAGE,
  SET_DISABLED_SUBMIT,
  SELECT_USER_DETAILS_REQUEST,
  SELECT_USER_DETAILS_SUCCESS,
  SELECT_USER_DETAILS_FAILURE,
} from 'containers/quickView/constants'
import {
  UNITY_LONG_CLICKED,
  UNITY_DESELECTED_ASSET,
} from 'containers/unityLoader/constants'

const activeSpaceId = (state = null, action) => {
  switch (action.type) {
    case SELECT_SPACE_ID:
      return action.id
    case SELECT_SIGN_ID:
    case CLEAR_QUICK_VIEW:
    case SELECT_USER:
    case UNITY_LONG_CLICKED:
      return null
    default:
      return state
  }
}

const activeSignId = (state = null, action) => {
  switch (action.type) {
    case SELECT_SIGN_ID:
      return action.id
    case SELECT_SPACE_ID:
    case CLEAR_QUICK_VIEW:
    case SELECT_USER:
    case UNITY_LONG_CLICKED:
      return null
    default:
      return state
  }
}

const activeUser = (state = null, action) => {
  switch (action.type) {
    case SELECT_USER:
      return action.user
    case SELECT_SIGN_ID:
    case CLEAR_QUICK_VIEW:
    case SELECT_SPACE_ID:
    case UNITY_LONG_CLICKED:
      return null
    default:
      return state
  }
}
const activeUserBookings = (state = [], action) => {
  switch (action.type) {
    case SELECT_USER_SUCCESS:
      return action.bookings
    case SELECT_USER_FAILURE:
      return []
    default:
      return state
  }
}

const info = (state = null, action) => {
  switch (action.type) {
    case UNITY_LONG_CLICKED:
      return action.payload
    case SELECT_SIGN_ID:
    case CLEAR_QUICK_VIEW:
    case SELECT_SPACE_ID:
    case SELECT_USER:
      return null
    default:
      return state
  }
}

const registeredSlot = (state = {}, action) => {
  switch (action.type) {
    case REGISTER_SLOT:
      return action.slot
    case CLEAR_QUICK_VIEW:
      return {}
    default:
      return state
  }
}

const userSearch = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_USERS_REQUEST:
      return {
        ...state,
        query: action.query,
      }
    case SEARCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.users,
      }
    case SEARCH_USERS_FAILURE:
      return {
        ...state,
        users: null,
      }
    case SEARCH_USERS_CLEAR:
    case CLEAR_QUICK_VIEW:
      return {}
    default:
      return state
  }
}

const bookingMessage = (state = '', action) => {
  switch (action.type) {
    case SET_BOOKING_MESSAGE:
      return action.message
    case DELETE_BOOKING_MESSAGE:
      return ''
    default:
      return state
  }
}

const disabledSubmit = (state = false, action) => {
  switch (action.type) {
    case SET_DISABLED_SUBMIT:
      return action.payload
    case UNITY_DESELECTED_ASSET:
      return false
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case SELECT_USER_DETAILS_REQUEST:
      return true
    case SELECT_USER_SUCCESS:
    case SELECT_USER_DETAILS_FAILURE:
      return false
    default:
      return state
  }
}

const selectedUserDetails = (state = {}, action) => {
  switch (action.type) {
    case SELECT_USER_DETAILS_REQUEST:
      return {}
    case SELECT_USER_DETAILS_SUCCESS:
      return action.userInfo
    default:
      return state
  }
}

export default combineReducers({
  activeSpaceId,
  activeSignId,
  activeUser,
  info,
  bookingFlow,
  claimFlow,
  userSearch,
  registeredSlot,
  activeUserBookings,
  bookingMessage,
  disabledSubmit,
  isFetching,
  selectedUserDetails,
})
