import { takeEvery, all, call, put, take, select } from 'redux-saga/effects'

import * as unity from 'api/unity'
import {
  START_WAYFINDING_REQUEST,
  STOP_WAYFINDING_REQUEST,
} from 'containers/app/constants'
import { SELECT_SPACE_ID, SELECT_USER } from 'containers/quickView/constants'
import { UNITY_COMMAND_DONE } from 'containers/unityLoader/constants'
import { getIsWayfinding } from 'containers/unityLoader/selectors'
import {
  wayfindSuccess,
  wayfindFailure,
  stopWayfindSuccess,
  stopWayfindFailure,
} from 'containers/app/actions'

const commandDone = (command) => (action) =>
  action.type === UNITY_COMMAND_DONE && action.payload.command === command

function* wayfind({ floorId, assetType, assetId, stop }) {
  yield call(unity.wayfind, floorId, assetType, assetId, stop)
  const response = yield take(commandDone('wayfinding'))
  if (response.payload.successful) {
    yield put(wayfindSuccess())
  } else yield put(wayfindFailure())
}

function* stopWayfind() {
  const isWayfinding = yield select(getIsWayfinding)
  if (!isWayfinding) return
  yield call(unity.stopWayfinding)
  const response = yield take(commandDone('wayfinding'))
  if (response.payload.successful) {
    yield put(stopWayfindSuccess())
  } else yield put(stopWayfindFailure())
}

function* watchWayfinding() {
  yield takeEvery(START_WAYFINDING_REQUEST, wayfind)
}

function* watchStopWayfinding() {
  yield takeEvery(STOP_WAYFINDING_REQUEST, stopWayfind)
}

function* watchQuickViewSpaceSelection() {
  yield takeEvery(SELECT_SPACE_ID, stopWayfind)
}

function* watchQuickViewUserSelection() {
  yield takeEvery(SELECT_USER, stopWayfind)
}

export default function* () {
  yield all([
    watchWayfinding(),
    watchStopWayfinding(),
    watchQuickViewSpaceSelection(),
    watchQuickViewUserSelection(),
  ])
}
