import isSameDay from 'date-fns/isSameDay'
import isPast from 'date-fns/isPast'

import { makeSelector } from 'containers/app/selectors'

const activeDataset = (state) => state.getIn(['filter', 'dataset'])
const availabilityFilter = (state) =>
  state.getIn(['filter', 'filters', 'availability'])
const bookableFilter = (state) => state.getIn(['filter', 'filters', 'bookable'])
const propFilter = (state) => state.getIn(['filter', 'filters', 'props'])
const seatFilter = (state) => state.getIn(['filter', 'filters', 'seatFilter'])
const durationFilter = (state) =>
  state.getIn(['filter', 'filters', 'durationFilter'])
const dateFilter = (state) => state.getIn(['filter', 'dateFilter'])
const selectedIntelval = (state) => state.getIn(['filter', 'mapIntelval'])
const isFuture = (state) =>
  (!isSameDay(dateFilter(state), Date.now()) && !isPast(dateFilter(state))) ||
  Object.values(selectedIntelval(state)).length > 0

export const getSelectedIntelval = makeSelector(selectedIntelval)
export const getActiveDataset = makeSelector(activeDataset)
export const getAvailabilityFilter = makeSelector(availabilityFilter)
export const getBookableFilter = makeSelector(bookableFilter)
export const getPropFilters = makeSelector(propFilter)
export const getNumSeatsFilter = makeSelector(seatFilter)
export const getDurationFilter = makeSelector(durationFilter)
export const getDateFilter = makeSelector(dateFilter)
export const getIsFuture = makeSelector(isFuture)
