import { schema } from 'normalizr'
import get from 'lodash/get'
import startOfDay from 'date-fns/startOfDay'
import addMinutes from 'date-fns/addMinutes'

function parseMeetings(arr = []) {
  const midnight = startOfDay(new Date())

  return arr.reduce((acc, cur, idx) => {
    if (idx % 2 !== 0) {
      return acc
    }
    return [
      ...acc,
      {
        start: addMinutes(midnight, cur).getTime(),
        end: addMinutes(midnight, arr[idx + 1]).getTime(),
      },
    ]
  }, [])
}

function createEntity(name, relation) {
  return new schema.Entity(
    name,
    {},
    {
      processStrategy: (value, parent) => ({
        ...value,
        [relation]: parent.parentId,
      }),
    },
  )
}

function createSpaceEntity(name, relation) {
  return new schema.Entity(
    name,
    {},
    {
      processStrategy: (value, parent) => ({
        ...value,
        [relation]: parent.parentId || value.loc.spaceId & 0x00000fff,
        meetings: parseMeetings(get(value, 'status.times')),
      }),
    },
  )
}

export function createEdgeEntity(key, entity) {
  return new schema.Entity(
    key,
    { add: [entity], update: [entity], remove: [entity] },
    { idAttribute: 'parentId' },
  )
}

export const site = createEntity('sites', 'companyId')
export const building = createEntity('buildings', 'siteId')
export const floor = createEntity('floors', 'buildingId')
export const space = createSpaceEntity('spaces', 'floorId')
export const user = createEntity('users', 'floorId')
export const sign = createEntity('signs', 'floorId')
export const metaType = new schema.Entity('metaTypes')
export const metaProp = new schema.Entity('metaProps')
export const futureDesks = createEntity('futureDesks', 'spaceId')
export const futureRooms = createEntity('futureRooms', 'spaceId')

export const update = {
  sites: [createEdgeEntity('sitesOnCompany', site)],
  buildings: [createEdgeEntity('buildingsOnSites', building)],
  floors: [createEdgeEntity('floorsOnBuildings', floor)],
  spaces: [createEdgeEntity('spacesOnFloors', space)],
  users: [createEdgeEntity('usersOnFloors', user)],
  signs: [createEdgeEntity('signsOnFloors', sign)],
  meta: {
    types: { add: [metaType], update: [metaType] },
    props: { add: [metaProp], update: [metaProp] },
  },
  futureDesks: [createEdgeEntity('futureDesks', futureDesks)],
  futureRooms: [createEdgeEntity('futureDesks', futureRooms)],
}
