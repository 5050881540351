export const CLEAR_QUICK_VIEW = 'containers/quickView/CLEAR_QUICK_VIEW'
export const SELECT_SPACE_ID = 'containers/quickView/SELECT_SPACE_ID'
export const SELECT_SIGN_ID = 'containers/quickView/SELECT_SIGN_ID'
export const SELECT_USER = 'containers/quickView/SELECT_USER'
export const SELECT_USER_FAILURE = 'containers/quickView/SELECT_USER_FAILURE'
export const SELECT_USER_SUCCESS = 'containers/quickView/SELECT_USER_SUCCESS'

export const SEARCH_USERS_REQUEST = 'containers/quickView/SEARCH_USERS_REQUEST'
export const SEARCH_USERS_SUCCESS = 'containers/quickView/SEARCH_USERS_SUCCESS'
export const SEARCH_USERS_FAILURE = 'containers/quickView/SEARCH_USERS_FAILURE'
export const SEARCH_USERS_CLEAR = 'containers/quickView/SEARCH_USERS_CLEAR'

export const REGISTER_SLOT = 'containers/quickView/REGISTER_SLOT'

export const SET_BOOKING_MESSAGE = 'containers/quickView/SET_BOOKING_MESSAGE'
export const DELETE_BOOKING_MESSAGE =
  'containers/quickView/DELETE_BOOKING_MESSAGE'

export const SET_DISABLED_SUBMIT = 'containers/quickView/SET_DISABLED_SUBMIT'

export const SELECT_USER_DETAILS_REQUEST =
  'containers/quickView/SELECT_USER_DETAILS_REQUEST'
export const SELECT_USER_DETAILS_SUCCESS =
  'containers/quickView/SELECT_USER_DETAILS_SUCCESS'
export const SELECT_USER_DETAILS_FAILURE =
  'containers/quickView/SELECT_USER_DETAILS_FAILURE'
