import styled, { css } from 'styled-components'

const BookingContainer = styled.div`
  display: flex;
  min-height: 48px;
  padding: 6px 12px;
  align-items: center;
  background: ${(props) => props.theme.holidayPanelItem};
  font-size: 1.5em;

  ${(props) =>
    props.active &&
    css`
      background-color: ${(props) => props.theme.logoBg};
    `};
`

export default BookingContainer
