import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import { getDateFilter } from 'containers/searchPanel/filter/selectors'
import InfoSymbol from 'containers/quickView/InfoSymbol'
import InfoText from 'containers/quickView/InfoText'
import ProfilePicture from 'components/ProfilePicture'
import {
  selectUser,
  selectUserDetailsRequest,
} from 'containers/quickView/actions'
import { changeModalPosition } from 'containers/flowPlanner/actions'
import { getActiveUser } from 'containers/quickView/selectors'
import { getLocalizedTime } from 'utils/utilsFunctions'
import { getTimeFormat } from 'containers/mainPanel/timeFormats/selectors'
import {
  ALLOW_TO_OPEN_MODAL,
  SAME_BUILDING,
  OTHER_BUILDING,
  HOME,
  VACATION,
} from 'utils/appVars'
import Icon from 'components/Icon'

const Holder = styled.div.attrs({
  className: ALLOW_TO_OPEN_MODAL,
})`
  &:first-child {
    border-top: solid 1px #d5d5d7;
  }
  height: 75px;
  border-bottom: solid 1px #d5d5d7;
  display: flex;
  align-items: center;
  padding: 0 21px 0 9px;
  border-left: 2px solid #fff;
  :hover {
    cursor: pointer;
    background-color: #f5fafe;
  }
  ${(props) =>
    props.selected &&
    css`
      background-color: #f5fafe;
      border-left: 2px solid #0178d4;
    `};
`
const SmallerInfoSymbol = styled.div`
  height: 40px !important;
  width: 40px !important;
  margin-right: 16px;
  > div {
    width: 100%;
    height: 100%;
    font-size: 18px;
  }
`
const InfoCardText = styled(InfoText)`
  font-size: 14px;
  span:last-child {
    font-weight: normal;
  }
  span:first-child {
    font-size: 16px;
    font-weight: 500;
  }
`
const InfoButton = styled.div`
  width: 23px;
  height: 23px;
  background-size: 23px;
  background-image: url(./common-icons/info2.svg);
  :hover {
    cursor: pointer;
  }
`

const StyledIcon = styled(Icon)`
  display: inline-block;
  text-align: center;
  height: 1.1em;
  width: 1.1em;
  position: relative;
  background-repeat: no-repeat;
  margin: 0;
  ${(props) =>
    props.type === HOME &&
    css`
      background-image: url(./home.svg);
      bottom: -0.5px;
    `};
  ${(props) =>
    props.type === VACATION &&
    css`
      background-image: url(./vacation.svg);
    `};
  ${(props) =>
    props.type === SAME_BUILDING &&
    css`
      background-image: url(./same_building.svg);
      height: 1em;
      width: 1em;
    `};
  ${(props) =>
    props.type === OTHER_BUILDING &&
    css`
      background-image: url(./other_building.svg);
      width: 1.9em;
    `};
`

const DetailedInfoCard = ({
  user,
  selectUser,
  changeModalPosition,
  activeUser,
  timeFormat,
  location,
  selectUserDetailsRequest,
  type,
}) => {
  const userModalPosition = {
    bottom: '390px',
    'z-index': 3,
  }
  const isActiveUser = activeUser ? activeUser.id === user.id : false
  const userFn = user?.name?.split(' ')[0]
  const userLn = user?.name?.split(' ')[1]
  return (
    <Holder
      selected={isActiveUser}
      onClick={() => {
        changeModalPosition(userModalPosition)
        selectUserDetailsRequest(user.id)
      }}
    >
      <SmallerInfoSymbol>
        <InfoSymbol>
          <ProfilePicture id={user.id} fn={userFn} ln={userLn} />
        </InfoSymbol>
      </SmallerInfoSymbol>
      <InfoCardText>
        <span>
          {userFn} {userLn}
        </span>
        <span>
          <StyledIcon type={type} /> {location}
        </span>
      </InfoCardText>
    </Holder>
  )
}

DetailedInfoCard.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  selectUser: PropTypes.func,
  user: PropTypes.object,
  activeUser: PropTypes.object,
  changeModalPosition: PropTypes.func,
  timeFormat: PropTypes.string,
  location: PropTypes.string,
  selectUserDetailsRequest: PropTypes.func,
  type: PropTypes.string,
}
const mapStateToProps = (state) => ({
  selectedDate: getDateFilter(state),
  activeUser: getActiveUser(state),
  timeFormat: getTimeFormat(state),
})

export default connect(mapStateToProps, {
  selectUser,
  changeModalPosition,
  selectUserDetailsRequest,
})(DetailedInfoCard)
