import { LOGIN_REQUEST, SAVE_TOKEN } from 'pages/login/constants'

export const loginRequest = (data) => ({
  type: LOGIN_REQUEST,
  data: data,
})

export const saveToken = (token) => ({
  type: SAVE_TOKEN,
  token,
})
