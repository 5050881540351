import { combineReducers } from 'redux-immutable'
import { Map, List, fromJS, is } from 'immutable'
import get from 'lodash/get'

import {
  FETCH_UPDATE_REQUEST,
  FETCH_UPDATE_FAILURE,
  FETCH_UPDATE_SUCCESS,
  CLEAR_FLOOR_STAMPS,
  TOGGLE_POLLING,
  CLEAR_UPDATES,
} from 'containers/app/constants'

export const createByIdReducer = (entityKey, persistant = false) => (
  state = Map(),
  action,
) => {
  if (!persistant && action.type === CLEAR_UPDATES) {
    return new Map()
  }

  if (action.response) {
    const entities = get(action.response, `entities[${entityKey}]`)
    if (!entities) return state
    return state.mergeDeep(fromJS(entities))
  }

  return state
}

export const createByIdReducerForSpaces = () => (state = Map(), action) => {
  if (action.type === CLEAR_UPDATES) {
    return new Map()
  }

  if (action.response) {
    const entities = get(action.response, `entities['spaces']`)
    if (!entities) return state

    const mappedResults = fromJS(entities)

    return mappedResults.reduce((R, V, K) => {
      let tempState = R.setIn([K, 'meetings'], V.getIn(['meetings']))
      tempState = tempState.setIn(
        [K, 'status', 'claims'],
        V.getIn(['status', 'claims']),
      )
      if (V.getIn(['info']) != null) {
        tempState = tempState.setIn(
          [K, 'info', 'props'],
          V.getIn(['info', 'props']),
        )
      }
      return tempState
    }, state.mergeDeep(mappedResults))
  }
  return state
}

export const listReducer = (state = List(), action) => {
  let nextState = state
  if (action.remove) {
    nextState = nextState.filter((i) => action.remove.indexOf(i) < 0)
  }
  if (action.add) {
    nextState = nextState.push(...action.add)
  }
  return nextState
}

export const createListReducer = (resultKey, persistant = false) => (
  state = List(),
  action,
) => {
  if (!persistant && action.type === CLEAR_UPDATES) {
    return new List()
  }
  if (action.response) {
    const result = get(action.response, `result[${resultKey}]`, {})
    return listReducer(state, result)
  }
  return state
}

export const createEdgesReducer = (entityKey, persistant = false) => (
  state = Map(),
  action,
) => {
  if (!persistant && action.type === CLEAR_UPDATES) {
    return new Map()
  }

  if (action.response) {
    const entities = get(action.response, `entities[${entityKey}]`)
    if (!entities) return state
    const updatedEdges = Object.values(entities).reduce(
      (acc, { parentId, add, remove }) =>
        acc.mergeDeep({
          [parentId]: listReducer(state.get(`${parentId}`), { add, remove }),
        }),
      Map(),
    )
    const nextState = state.merge(updatedEdges)
    return is(state, nextState) ? state : nextState
  }
  return state
}

const stamp = (state = 0, action) => {
  if (action.type === FETCH_UPDATE_SUCCESS) {
    const stamp = get(action.response, 'result.stamp')
    if (!stamp) return state
    return stamp
  }
  return state
}

const self = (state = Map(), action) => {
  if (action.type === FETCH_UPDATE_SUCCESS) {
    const self = state.merge(fromJS(get(action.response, 'result.self')))
    if (self) return self
  }

  return state
}

const floorStamps = (state = {}, action) => {
  switch (action.type) {
    case FETCH_UPDATE_SUCCESS:
      if (!action.floorIds) return state
      return {
        ...state,
        ...action.floorIds.reduce(
          (acc, floorId) => ({
            ...acc,
            [floorId]: action.response.result.stamp,
          }),
          {},
        ),
      }
    case CLEAR_FLOOR_STAMPS:
    case CLEAR_UPDATES:
      return {}
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_UPDATE_REQUEST:
      return true
    case FETCH_UPDATE_SUCCESS:
    case FETCH_UPDATE_FAILURE:
      return false
    default:
      return state
  }
}

const hasFetched = (state = false, action) => {
  switch (action.type) {
    case FETCH_UPDATE_SUCCESS:
    case FETCH_UPDATE_FAILURE:
      return true
    default:
      return state
  }
}

const pollingActive = (
  state = process.env.NODE_ENV === 'development' ? false : true,
  action,
) => {
  if (action.type === TOGGLE_POLLING) {
    return !state
  }
  return state
}

export default combineReducers({
  stamp,
  floorStamps,
  self,
  sites: combineReducers({
    byId: createByIdReducer('sites', true),
    sitesOnCompany: createEdgesReducer('sitesOnCompany', true),
  }),

  buildings: combineReducers({
    byId: createByIdReducer('buildings', true),
    buildingsOnSites: createEdgesReducer('buildingsOnSites', true),
  }),
  floors: combineReducers({
    byId: createByIdReducer('floors', true),
    floorsOnBuildings: createEdgesReducer('floorsOnBuildings', true),
  }),
  spaces: combineReducers({
    byId: createByIdReducerForSpaces(),
    spacesOnFloors: createEdgesReducer('spacesOnFloors'),
  }),
  users: combineReducers({
    byId: createByIdReducer('users'),
    usersOnFloors: createEdgesReducer('usersOnFloors'),
  }),
  signs: combineReducers({
    byId: createByIdReducer('signs'),
    signsOnFloors: createEdgesReducer('signsOnFloors'),
  }),
  metaTypes: combineReducers({
    byId: createByIdReducer('metaTypes', true),
    active: createListReducer('meta.types', true),
  }),
  metaProps: combineReducers({
    byId: createByIdReducer('metaProps', true),
    active: createListReducer('meta.props', true),
  }),
  isFetching,
  hasFetched,
  pollingActive,
})
