import { takeEvery, all, call, put } from 'redux-saga/effects'
import Alert from 'react-s-alert'

import * as argus from 'api/argus'
import { LOGIN_REQUEST } from 'pages/login/constants'
import { AUTH_SUCCESS } from 'containers/app/constants'
import {
  authenticateSuccess,
  authenticateFailure,
  token,
} from 'containers/app/actions'

function* login({ data }) {
  try {
    const response = yield call(argus.postData, '/public/login/user', {
      body: data,
    })
    yield put(authenticateSuccess(response))
  } catch ({ error, response }) {
    yield put(authenticateFailure(error.message))
    console.error(error.message)
    Alert.error(`ERROR: Failed to login: ${error.message}`)
    if (response.status === 401) {
      yield put(authenticateFailure())
    }
  }
}

function* saveLogin({ response }) {
  yield put(token(response.token))
  window.localStorage.setItem('oauthSuccess', true)
  window.location.reload()
}

function* watchLoginRequest() {
  yield takeEvery(LOGIN_REQUEST, login)
}

function* watchLoginSuccess() {
  yield takeEvery(AUTH_SUCCESS, saveLogin)
}

export default function* () {
  yield all([watchLoginRequest(), watchLoginSuccess()])
}
