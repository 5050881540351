import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { isFuture } from 'date-fns'
import { Translate } from 'react-localize-redux'

import InfoText from 'containers/quickView/InfoText'
import ModalRow from 'containers/quickView/modal/ModalRow'
import {
  getUsers,
  getFutureDesks,
  getFutureUsers,
  getFutureRooms,
} from 'containers/app/selectors'
import InfoSymbol from 'containers/quickView/InfoSymbol'
import ProfilePicture from 'components/ProfilePicture'
import { selectUser } from 'containers/quickView/actions'
import Icon from 'components/Icon'
import { icons } from 'components/icon/icons'

const RowLinkIndicator = styled.div`
  ${Icon} {
    font-size: 24px;
    padding: 0;
  }
`

const renderWithUser = (user) => {
  return (
    <Fragment>
      <InfoSymbol status={user.getIn(['status', 'state'])}>
        <ProfilePicture
          id={user.get('id')}
          fn={user.getIn(['info', 'fn'])}
          ln={user.getIn(['info', 'ln'])}
        />
      </InfoSymbol>
      <InfoText>
        <span>
          <Translate id="space.status.booked" />
        </span>
        <span>
          {user.getIn(['info', 'fn'])} {user.getIn(['info', 'ln'])}
        </span>
      </InfoText>
      <RowLinkIndicator>
        <Icon name={icons.CIRCLED_RIGHT} />
      </RowLinkIndicator>
    </Fragment>
  )
}

const renderWithInfo = (userFn, userLn) => {
  return (
    <Fragment>
      <InfoSymbol status={'OFFLINE'}>
        <ProfilePicture fn={userFn} ln={userLn} />
      </InfoSymbol>
      <InfoText>
        <span>
          <Translate id="billboard.reservedBy" />
        </span>
        <span>
          {userFn} {userLn}
        </span>
      </InfoText>
    </Fragment>
  )
}

const SpaceOccupancy = ({ userFn, userLn, user, selectUser }) => {
  if (user) {
    return (
      <ModalRow onClick={() => selectUser(user)}>
        {renderWithUser(user)}
      </ModalRow>
    )
  } else if (userFn && userLn) {
    return <ModalRow>{renderWithInfo(userFn, userLn)}</ModalRow>
  } else return null
}

const mapStateToProps = (state, { space, room }) => {
  const futureDesks = getFutureDesks(state)
  const futureUsers = getFutureUsers(state)
  const futureRooms = getFutureRooms(state)

  if (futureDesks || futureRooms) {
    const selectedDesk = [...futureDesks, ...futureRooms].find(
      (desk) => desk.id === space.id,
    )
    if (!selectedDesk) return {}
    const userId =
      selectedDesk.state === 'BOOKED' ? selectedDesk.events[0].userId : null
    if (!userId) return {}
    const user = futureUsers.find((user) => user.id === userId)
    const userFn = user.name
    const userLn = user.lName
    return {
      userFn,
      userLn,
    }
  }

  if (room && space.status.occupant) {
    const occupantNames = space.status.occupant.trim().split(' ')

    return {
      userFn:
        occupantNames.length > 2 ? space.status.occupant : occupantNames[0],
      userLn: occupantNames.length > 2 ? ' ' : occupantNames[1],
    }
  }
  const claimInfo = space.status.claims
  if (!claimInfo || claimInfo.length <= 0) return {}
  const userFn = claimInfo[0].fn
  const userLn = claimInfo[0].ln
  const user = getUsers(state).get(`${claimInfo[0].id}`)
  return {
    userFn,
    userLn,
    user,
  }
}

SpaceOccupancy.propTypes = {
  userFn: PropTypes.string,
  userLn: PropTypes.string,
  user: PropTypes.object,
  selectUser: PropTypes.object,
}

export default connect(mapStateToProps, { selectUser })(SpaceOccupancy)
