import React, { Component } from 'react'
import { Translate } from 'react-localize-redux'

import ModalRow from 'containers/quickView/modal/ModalRow'
import ModalTitle from 'containers/quickView/modal/ModalTitle'

class BookingCancelSuccess extends Component {
  render() {
    return (
      <React.Fragment>
        <ModalRow>
          <ModalTitle>
            <Translate id="quickView.meeting.cancelConfirmed" />
          </ModalTitle>
        </ModalRow>
      </React.Fragment>
    )
  }
}

export default BookingCancelSuccess
