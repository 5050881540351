import englishLocale from 'date-fns/locale/en-US'
import * as locales from 'date-fns/locale'
import {
  isWithinInterval,
  fromUnixTime,
  isSameDay,
  isFuture,
  format,
  set,
  getHours,
  getMinutes,
  endOfDay,
  startOfDay,
  isToday,
  isEqual,
} from 'date-fns'
import { useSelector } from 'react-redux'

import {
  DEFAULT_SPACE_START_TIME,
  DEFAULT_SPACE_END_TIME,
  DEFAULT_TIMESTAMP_INDEX,
  DEFAULT_TIME_FORMAT,
  ADD_STRING,
  UPDATE_STRING,
} from 'utils/appVars'
import { getDateFilter } from 'containers/searchPanel/filter/selectors'

export const sortByString = (stringA, stringB) =>
  stringA.localeCompare(stringB, 'en', { numeric: true })

const localeMap = {
  en: englishLocale,
  sv: locales.sv,
  no: locales.nb,
  fr: locales.fr,
  de: locales.de,
  es: locales.es,
}

const dateFormatMap = {
  en: 'dd.MM.yy',
  sv: 'yy.MM.dd',
  no: 'yy.MM.dd',
  fr: 'dd.MM.yy',
  de: 'dd.MM.yy',
  es: 'dd.MM.yy',
}

export const getLocale = (languageCode) => localeMap[languageCode]

export const checkSlotInterval = (slot, dateToCheck) => {
  const slotStartInMs = slot.start * 60 * 1000
  const slotEndInMs = slot.end * 60 * 1000
  const startOfDayValue = startOfDay(dateToCheck)
  const endOfDayValue = endOfDay(dateToCheck)
  return startOfDayValue < slotEndInMs && endOfDayValue > slotStartInMs
}

export const checkIfCurrentBooking = (slot) => {
  const start = fromUnixTime(new Date(slot.start * 60))
  const now = Date.now()
  const currentYear = new Date().getFullYear()
  const yearsToAdd = 1
  let end
  slot.end
    ? (end = fromUnixTime(new Date(slot.end * 60)))
    : (end = new Date(currentYear + yearsToAdd, 0))

  return now >= start && now <= end
}

export const uniqueIds = (idsArray) => {
  const resultObj = {}
  idsArray.forEach((item) => {
    resultObj[item] = ''
  })
  return Object.keys(resultObj)
}

export const buildInitialsString = (fn, ln) => {
  if (fn && fn.length) {
    return `${fn.substring(0, 1)}${ln && ln.length ? ln.substring(0, 1) : ''}`
  } else if (ln && ln.length) {
    return `${ln.substring(0, 1)}`
  } else {
    return ''
  }
}

const getCurrentLocale = () => {
  const currentLanguage = localStorage.getItem('language')
  return localeMap[currentLanguage]
}

export const getLocalizedTime = (
  timestamp,
  timeFormat = DEFAULT_TIME_FORMAT,
  index = DEFAULT_TIMESTAMP_INDEX,
) => {
  if (!timestamp) return ''
  const currentLocale = getCurrentLocale()
  return format(new Date(timestamp * index), timeFormat, {
    locale: currentLocale,
  })
}
export const getLocalizedDateAndTime = (
  timestamp,
  timeFormat = DEFAULT_TIME_FORMAT,
  index = DEFAULT_TIMESTAMP_INDEX,
) => {
  if (!timestamp) return ''
  const currentLocale = getCurrentLocale()
  const currentLanguage = localStorage.getItem('language')
  return format(
    new Date(timestamp * index),
    `${dateFormatMap[currentLanguage]} - ${timeFormat}`,
    {
      locale: currentLocale,
    },
  )
}

export const checkIfToearlierThanFrom = (valueTo, valueFrom) => {
  if (valueTo) {
    return valueTo <= valueFrom
  }
  return true
}

export const getWorkingHour = (
  startHour = 0,
  startMinutes = 0,
  selectedDate,
  timeFormat = DEFAULT_TIME_FORMAT,
) => {
  const date = selectedDate ? new Date(selectedDate) : new Date()
  const hourToShow = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    startHour,
    startMinutes,
  )
  const currentLocale = getCurrentLocale()
  return {
    label: format(hourToShow, timeFormat, { locale: currentLocale }),
    value: hourToShow,
  }
}

export const timeOptions = (
  intervalInMinutes,
  selectedDate,
  startHour,
  startMinutes,
  timeFormat = DEFAULT_TIME_FORMAT,
  lastHour = 24,
) => {
  const datesValues = []
  const date = selectedDate ? new Date(selectedDate) : new Date()
  const firstOptionToShow = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    startHour,
    startMinutes,
  )
  const coeff = 1000 * 60 * intervalInMinutes
  const secondOptionToShow = new Date(
    Math.round(firstOptionToShow.getTime() / coeff) * coeff,
  )
  datesValues.push(new Date(firstOptionToShow))
  datesValues.push(new Date(secondOptionToShow))

  const iterations = (lastHour - startHour) * (60 / intervalInMinutes)
  const numberOfIterations = lastHour === 23 ? iterations - 1 : iterations
  for (let i = 0; i < numberOfIterations; i++) {
    const oldValue = new Date(datesValues[datesValues.length - 1])
    const newValue = new Date().setTime(
      oldValue.getTime() + intervalInMinutes * 60 * 1000,
    )
    datesValues.push(new Date(newValue))
  }
  for (let i = 0; i < numberOfIterations; i++) {
    if (datesValues[i] >= datesValues[i + 1]) {
      datesValues.splice(i, i + 1)
    }
  }
  return datesValues.map((date) =>
    getWorkingHour(date.getHours(), date.getMinutes(), date, timeFormat),
  )
}

export const getLocalDate = (dateString) => {
  const date = new Date(dateString)
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
  return date
}

export const getStartMinutesOfInterval = (
  minutesToCompare,
  usedInterval = 30,
) => {
  const index = Math.trunc(minutesToCompare / usedInterval)
  //returns the start minute of an partial interval
  return index * usedInterval
}

export const getEndTimeValue = (start, end, selectedDate) => {
  const time = new Date(start)
  const toIsEarlierThenFrom = checkIfToearlierThanFrom(end, time)
  let endTime
  if (toIsEarlierThenFrom) {
    endTime = time.setHours(
      getHours(endOfDay(time)),
      getMinutes(endOfDay(time)),
      0,
    )
    endTime = new Date(endTime)
    return endTime
  }
  if (!end) {
    endTime = getDefaultEndTime(selectedDate)
    endTime = new Date(endTime)
    return endTime
  }
  endTime = new Date(end)
  return endTime
}

export const getDefaultEndTime = (timeFormat = DEFAULT_TIME_FORMAT) => {
  const defaultEnd = DEFAULT_SPACE_END_TIME
  const endHour = Math.trunc(defaultEnd / 60)
  const endMinute = defaultEnd % 60
  const defaultEndTime = getWorkingHour(
    endHour,
    endMinute,
    this.selectedDate,
    timeFormat,
  )
  return defaultEndTime
}

export const selectedItemIndex = (options, searchedValue) => {
  const ignoreSecondsAndMiliSeconds =
    searchedValue && searchedValue.value
      ? searchedValue.value.setHours(
          searchedValue.value.getHours(),
          searchedValue.value.getMinutes(),
          0,
          0,
        )
      : null
  return options.findIndex((date) =>
    isEqual(date.value, ignoreSecondsAndMiliSeconds),
  )
}

export const scrollTheValueToTop = (id) => {
  setTimeout(() => {
    const selectedEl = document.getElementById(id)
    if (selectedEl) {
      selectedEl.parentNode.scroll({
        top: selectedEl.offsetTop,
      })
    }
  }, 5)
}

export const handlePrevDayFuture = (payload) => {
  const replaceValue = new RegExp([ADD_STRING, UPDATE_STRING].join('|'), 'g')

  const payloadAsString = JSON.stringify(payload)
  const editedPayload = payloadAsString.replace(replaceValue, function ($1) {
    return $1 === ADD_STRING ? UPDATE_STRING : ADD_STRING
  })

  return JSON.parse(editedPayload)
}

export const addDayIfPastMidday = (days) => {
  const currentDay = new Date()
  const isPastNoon = currentDay.getHours() >= 12
  days = isPastNoon ? days + 1 : days
  return days
}

export const compareNameAndQueryString = (name, str) => {
  const fn = name.split(' ')[0].toLowerCase()
  const ln = name.split(' ')[1].toLowerCase()
  str = str.toLowerCase()
  return fn.startsWith(str) || ln.startsWith(str)
}
