import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'

export const StyledInput = styled.input`
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 1.4rem;
  font-family: inherit;
  width: 100%;
  border: 1px solid ${(props) => props.theme.statusAway};
  box-sizing: border-box;
  letter-spacing: 0.03em;
  background-color: ${(props) => props.theme.panelBgLight};
  margin: 0;
`

export const Wrapper = styled.label`
  min-height: 4rem;
  display: flex;
`

const Input = (props) => (
  <Wrapper type={props.type}>
    <StyledInput
      id={props.input.name}
      tabIndex="0"
      {...props.input}
      {...props}
    />
  </Wrapper>
)

Input.propTypes = {
  type: PropTypes.string.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
}

export default Input
