import { takeLatest, all, select, call, put } from 'redux-saga/effects'
import Alert from 'react-s-alert'
import { normalize } from 'normalizr'
import get from 'lodash/get'
import isArray from 'lodash/isArray'

import { result } from 'containers/searchPanel/search/schema'
import * as argus from 'api/argus'
import {
  SEARCH_REQUEST,
  FUTURE_SEARCH_REQUEST,
} from 'containers/searchPanel/search/constants'
import {
  searchSuccess,
  searchFailure,
  futureSearchSuccess,
} from 'containers/searchPanel/search/actions'
import {
  getClientFeatures,
  getFutureUsers,
  getFutureDesks,
  getSpaces,
  getActiveDeskIds,
  getActiveRoomIds,
  getFutureRooms,
} from 'containers/app/selectors'
import { authenticateFailure, startFilter } from 'containers/app/actions'
import { clearDataset } from 'containers/searchPanel/filter/actions'
import {
  BOOK_DESK_SUCCESS,
  CANCEL_BOOKING_SUCCESS,
} from 'containers/quickView/spaceModal/claimFlow/constants'
import { getActiveDataset } from 'containers/searchPanel/filter/selectors'

function mapFloorIdFromSpaceId(entity) {
  const spaceId = get(entity, 'loc.spaceId')
  if (spaceId) {
    return {
      ...entity,
      floorId: spaceId & 0x00000fff,
    }
  }
  return entity
}

function reduceResponse(response) {
  return {
    ...response,
    result: Object.keys(response.result).reduce((acc, key) => {
      const value = response.result[key]
      if (isArray(value)) {
        return {
          ...acc,
          [key]: value.map(mapFloorIdFromSpaceId),
        }
      }
      return {
        ...acc,
        [key]: value,
      }
    }, {}),
  }
}

function* fetchSpaceIds() {
  const activeDataSet = yield select(getActiveDataset)
  const deskIds = yield select(getActiveDeskIds)
  const roomIds = yield select(getActiveRoomIds)
  const ids = []

  if ((activeDataSet === 'rooms' || !activeDataSet) && roomIds) {
    ids.push(...roomIds)
  }
  if ((activeDataSet === 'desks' || !activeDataSet) && deskIds) {
    ids.push(...deskIds)
  }
  return ids
}

function* search({ query }) {
  // TODO fix searches with filters still active
  yield put(clearDataset())
  const clientFeatures = yield select(getClientFeatures)

  try {
    const response = yield call(
      argus.fetchData,
      `/secure/search/fullObjects?str=${query}&searchUsers=${clientFeatures.getIn(
        ['colleagueFinder', 'enabled'],
      )}&fields=FIRSTNAME&fields=LASTNAME&fields=TITLE`,
    )
    const normalizedResponse = normalize(response, result)
    const reducedResponse = reduceResponse(normalizedResponse)
    yield put(searchSuccess(reducedResponse))
    const hideIds = yield call(fetchSpaceIds)
    const showIds = response.spaces.map((space) => space.id)
    yield put(startFilter('update', showIds, hideIds))
  } catch ({ error, response }) {
    yield put(searchFailure(error.message))
    Alert.error(`ERROR: Failed to search on server: ${error.message}`)
    if (response.status === 401) {
      yield put(authenticateFailure())
    }
  }
}

function* futureSearch({ query }) {
  const futureUsers = yield select(getFutureUsers)
  const futureDesks = yield select(getFutureDesks)
  const futureRooms = yield select(getFutureRooms)
  const spaces = yield select(getSpaces)
  if (!futureUsers || !futureDesks || !futureRooms) return null
  const futureSpaces = [...futureDesks, ...futureRooms]

  const stringQuery = query ? query : ''
  const queriedUsers = futureUsers.filter(
    (user) =>
      user.name
        .toLowerCase()
        .concat(' ', user.lName.toLowerCase())
        .startsWith(stringQuery.toLowerCase()) ||
      user.lName.toLowerCase().startsWith(stringQuery.toLowerCase()),
  )

  const filteredSpaces = futureSpaces
    .filter((futureSpace) => {
      const space = spaces.get(`${futureSpace.id}`)
      if (!space) return false
      const spaceToJS = space.toJS()
      const name = spaceToJS.info.name
      if (name.toLowerCase().startsWith(stringQuery.toLowerCase())) return true

      if (space.state === 'BOOKED' || space.state === 'CLAIMED') {
        const userId = space.events[0].userId
        const user = queriedUsers.find((user) => user.id === userId)
        if (user) {
          return true
        } else {
          return false
        }
      }
      return false
    })
    .map((desk) => desk.id)

  yield put(futureSearchSuccess(filteredSpaces))
}

function* watchSearch() {
  yield takeLatest(SEARCH_REQUEST, search)
}
function* watchFutureSearch() {
  yield takeLatest(
    [FUTURE_SEARCH_REQUEST, BOOK_DESK_SUCCESS, CANCEL_BOOKING_SUCCESS],
    futureSearch,
  )
}

function* searchSaga() {
  yield all([watchSearch(), watchFutureSearch()])
}
export default searchSaga
