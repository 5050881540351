import { takeEvery, takeLatest, all, call, put } from 'redux-saga/effects'
import Alert from 'react-s-alert'
import { Map } from 'immutable'

import { START_WAYFINDING_SUCCESS } from 'containers/app/constants'
import * as unity from 'api/unity'
import * as argus from 'api/argus'
import * as desks from 'api/desks'
import { authenticateFailure } from 'containers/app/actions'
import {
  searchUsersSuccess,
  searchUsersFailure,
  clearQuickView,
  selectUserSuccess,
  selectUserFailure,
  selectUserDetailsSuccess,
  selectUser,
} from 'containers/quickView/actions'
import { END_MEETING_CONFIRMED } from 'containers/quickView/spaceModal/bookingFlow/constants'
import {
  SEARCH_USERS_REQUEST,
  SELECT_USER,
  CLEAR_QUICK_VIEW,
  SELECT_USER_DETAILS_REQUEST,
} from 'containers/quickView/constants'
import { searchClear, clearQuery } from 'containers/searchPanel/search/actions'
import { getStamp } from 'containers/app/selectors'
function* wayfindingStarted() {
  yield put(clearQuickView())
  yield call(unity.deselect)
}

function* endMeeting() {
  yield put(clearQuickView())
  yield call(unity.deselect)
}

function* searchUsers({ query }) {
  try {
    const response = yield call(
      argus.fetchData,
      `/secure/deskbooking/bobo/search?str=${query}`,
    )
    yield put(searchUsersSuccess(response.users))
  } catch ({ error, response }) {
    yield put(searchUsersFailure(error.message))
    Alert.error(`ERROR: Failed to search on server: ${error.message}`)
    if (response.status === 401) {
      yield put(authenticateFailure())
    }
  }
}

function* onSelectUser({ user }) {
  const userId = user.get('id')
  try {
    const response = yield call(
      argus.fetchData,
      `/secure/deskbooking/user/${userId}`,
    )
    yield put(selectUserSuccess(response.bookings))
  } catch ({ error, response }) {
    yield put(selectUserFailure())
    Alert.error(`ERROR: Failed to fetch from server: ${error.message}`)
    if (response.status === 401) {
      yield put(authenticateFailure())
    }
  }
}

function* clearedQuickView() {
  yield put(clearQuery())
  yield put(searchClear())
}

function* selectUserDetails({ id }) {
  try {
    const response = yield call(
      argus.fetchData,
      `/secure/user?id=${id}&stamp=0`,
    )
    yield put(selectUser(Map(response)))
  } catch ({ error, response }) {
    yield put(selectUserFailure())
    Alert.error(`ERROR: Failed to fetch from server: ${error.message}`)
    if (response.status === 401) {
      yield put(authenticateFailure())
    }
  }
}

function* watchClearedQuickView() {
  yield takeEvery(CLEAR_QUICK_VIEW, clearedQuickView)
}
function* watchWayfinding() {
  yield takeEvery(START_WAYFINDING_SUCCESS, wayfindingStarted)
}

function* watchEndMeetingSuccess() {
  yield takeEvery(END_MEETING_CONFIRMED, endMeeting)
}

function* watchSearchUsers() {
  yield takeLatest(SEARCH_USERS_REQUEST, searchUsers)
}
function* watchSelectUser() {
  yield takeLatest(SELECT_USER, onSelectUser)
}

function* watchSelectUserDetails() {
  yield takeLatest(SELECT_USER_DETAILS_REQUEST, selectUserDetails)
}

export default function* quickViewSagas() {
  yield all([
    watchWayfinding(),
    watchEndMeetingSuccess(),
    watchSearchUsers(),
    watchSelectUser(),
    watchClearedQuickView(),
    watchSelectUserDetails(),
  ])
}
