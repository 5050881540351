export const SET_DATASET = 'containers/filter/SET_DATASET'
export const CLEAR_DATASET = 'containers/filter/CLEAR_DATASET'
export const SET_AVAILABILITY_FILTER =
  'containers/filter/SET_AVAILABILITY_FILTER'
export const SET_BOOKABLE_FILTER = 'containers/filter/SET_BOOKABLE_FILTER'
export const SET_PROP_FILTER = 'containers/filter/SET_PROP_FILTER'
export const CLEAR_FILTER = 'containers/filter/CLEAR_FILTER'
export const SET_NUM_SEATS_FILTER = 'containers/filter/SET_NUM_SEATS_FILTER'
export const SET_DURATION_FILTER = 'containers/filter/SET_DURATION_FILTER'
export const SET_DATE = 'containers/filter/SET_DATE'
export const SET_MAP_INTERVAL = 'containers/filter/SET_MAP_INTERVAL'
