import { take, takeEvery, all, select, call, put } from 'redux-saga/effects'
import set from 'lodash/set'
import { getUnixTime, fromUnixTime } from 'date-fns'
import Alert from 'react-s-alert'

import * as unity from 'api/unity'
import * as argus from 'api/argus'
import { config } from 'api/public'
import {
  getIsFuture,
  getDateFilter,
  getSelectedIntelval,
} from 'containers/searchPanel/filter/selectors'
import {
  getSpaces,
  getMetaTypes,
  getDeskIdsOnFloors,
  getRoomIdsOnFloors,
  getAuthToken,
  getCurrentBuildingId,
  getCurrentFloorId,
  getUsers,
  getSelfId,
  getClientAuthentication,
  getBuildings,
  getFloors,
} from 'containers/app/selectors'
import {
  startFilter,
  updateRequest,
  updateFuture,
  authenticateFailure,
  updateDeskBookingsFailure,
  fetchFutureView,
  locationRequest,
} from 'containers/app/actions'
import { initUnitySuccess } from 'containers/unityLoader/actions'
import {
  BOOK_DESK_SUCCESS,
  CANCEL_BOOKING_SUCCESS,
} from 'containers/quickView/spaceModal/claimFlow/constants'
import {
  UNITY_INIT_REQUEST,
  UNITY_INIT_SUCCESS,
  UNITY_READY,
  UNITY_COMMAND_DONE,
  UNITY_CLICKED_ASSET,
  UNITY_DESELECTED_ASSET,
  FORCE_FUTURE_CONTENT_UPDATE,
} from 'containers/unityLoader/constants'
import {
  FETCH_UPDATE_SUCCESS,
  FETCH_CLIENT_CONFIG_SUCCESS,
  SET_FLOOR,
  SET_BUILDING,
  FETCH_FUTURE_VIEW,
} from 'containers/app/constants'
import { getUnityHasLoaded } from 'containers/unityLoader/selectors'
import { UpdateCache } from 'containers/unityLoader/utils/updateCache'
import defaultSettings from 'containers/unityLoader/settings'
import {
  selectSpaceId,
  clearQuickView,
  selectSignId,
  selectUser,
} from 'containers/quickView/actions'
import { getEndTimeValue, handlePrevDayFuture } from 'utils/utilsFunctions'
import { setMapInterval } from 'containers/searchPanel/filter/actions'
import { DEFAULT_START_HOUR, DEFAULT_END_HOUR } from 'utils/appVars'

const contentUpdateCache = new UpdateCache()

const commandDone = (command) => (action) =>
  action.type === UNITY_COMMAND_DONE && action.payload.command === command

function* startUnity() {
  yield all([take(FETCH_UPDATE_SUCCESS), take(FETCH_CLIENT_CONFIG_SUCCESS)])
  const authOptions = yield select(getClientAuthentication)
  //Checks to see if the session is stored in a cookie or in state
  let authToken = ''
  if (
    authOptions.getIn(['authOptions', '0', 'id']) !==
    ('oauth2-microsoft' || 'oauth2-google')
  ) {
    authToken = yield select(getAuthToken)
  }

  if (process.env.NODE_ENV === 'development') {
    yield take(UNITY_INIT_REQUEST)
  }

  yield call(unity.startMount, process.env.REACT_APP_UNITY_ID)

  yield take(UNITY_READY)
  const settings = yield call(getSettings)
  yield call(unity.startSettings, settings)

  yield take(commandDone('settings'))
  const selfId = yield select(getSelfId)
  yield call(unity.startUser, selfId, authToken) //TODO: Send unique and token to Unity

  yield take(commandDone('init_me'))
  yield call(contentUpdateCache.load, unity.contentUpdate)

  yield take(commandDone('contentUpdate'))
  const currentBuildingId = yield select(getCurrentBuildingId)
  const currentFloorId = yield select(getCurrentFloorId)

  yield call(unity.showBuilding, currentBuildingId)
  //TODO: Make sure "show building" completes with Unity callback before sending "show floor"
  yield call(unity.showFloor, currentFloorId)

  yield put(initUnitySuccess())
}

function* updateUnity({ payload, resetMap }) {
  if (payload) {
    const unityHasLoaded = yield select(getUnityHasLoaded)
    if (resetMap) {
      payload = handlePrevDayFuture(payload)
    }
    if (unityHasLoaded) {
      yield call(unity.contentUpdate, payload)
    } else {
      contentUpdateCache.add(payload)
    }
  }
}

function* getSettings() {
  const settings = { ...defaultSettings }
  const argus_url = yield call(config, 'argus_url')
  set(settings, 'serverURL', argus_url)
  set(settings, 'camera.defaultRot', 0)
  set(
    settings,
    'camera.defaultAngle',
    '3d', //TODO: Use latest angle from local storage
  )
  set(settings, 'application.showAreas', true)
  set(settings, 'application.showUsers', true)
  set(settings, 'application.showDesks', true)
  return settings
}

function* showFloor({ floorId, forced = false }) {
  yield call(unity.showFloor, floorId, forced)
}

function* showBuilding({ buildingId, floorId }) {
  yield call(unity.showBuilding, buildingId)
  yield call(showFloor, { floorId })
}

function* updatePosition(params) {
  const payload = params.payload
  if (!payload) return

  if (payload.self && payload.self.loc) {
    const floorId = yield params.payload.self.loc.spaceId & 0x00000fff
    const position = yield params.payload.self.loc.pos
    yield call(unity.updatePosition, position, floorId)
  } else {
    yield call(unity.positionRemove)
  }
}

function* selectAsset({ payload: { assetType, id } }) {
  if (assetType === 'SPACE' || assetType === 'space') {
    yield put(selectSpaceId(id))
  } else if (assetType === 'SIGN' || assetType === 'sign') {
    yield put(selectSignId(id))
  } else if (assetType === 'USER' || assetType === 'user') {
    const users = yield select(getUsers)
    yield put(selectUser(users.get(`${id}`)))
  }
}

function* updateContentUpdate() {
  const isFuture = yield select(getIsFuture)

  const currentFloorId = yield select(getCurrentFloorId)
  const dateValue = yield select(getDateFilter)

  isFuture
    ? yield put(fetchFutureView())
    : yield put(updateRequest([currentFloorId]))
}

function* updateFutureUnity() {
  const currentFloorId = yield select(getCurrentFloorId)
  const currentBuildingId = yield select(getCurrentBuildingId)
  const buildings = yield select(getBuildings)
  const floors = yield select(getFloors)
  const isFuture = yield select(getIsFuture)
  const selectedInterval = yield select(getSelectedIntelval)
  const selectedDate = yield select(getDateFilter)
  const filterInterval = Object.values(selectedInterval).length > 0

  let start = isFuture
    ? getUnixTime(
        new Date(selectedDate).setHours(DEFAULT_START_HOUR, 0, 0, 0),
      ) / 60
    : getUnixTime(new Date(selectedDate)) / 60
  let end = isFuture
    ? getUnixTime(new Date(selectedDate).setHours(DEFAULT_END_HOUR, 0, 0, 0)) /
      60
    : getUnixTime(new Date(selectedDate)) / 60

  if (filterInterval) {
    start = getUnixTime(selectedInterval.start) / 60
    end =
      getUnixTime(
        getEndTimeValue(
          selectedInterval.start,
          selectedInterval.end,
          selectedDate,
        ),
      ) / 60

    yield put(
      setMapInterval({
        start: fromUnixTime(start * 60),
        end: fromUnixTime(end * 60),
      }),
    )
  }

  start = Math.trunc(start)
  end = Math.trunc(end)

  try {
    const response = yield call(
      argus.fetchFutureUpdate,
      currentFloorId,
      start,
      end,
    )
    const dataToShow = JSON.parse(response.contentUpdate)
    // Should always send arrays for add, update and remove
    if (dataToShow.spaces && dataToShow.spaces.length > 0) {
      dataToShow.spaces.forEach((floor) => {
        floor.add = floor.add ?? []
        floor.update = floor.update ?? []
        floor.remove = floor.remove ?? []
      })
    }
    yield call(unity.contentUpdate, dataToShow)
    yield put(updateFuture(response))
  } catch ({ error, response }) {
    yield put(updateDeskBookingsFailure(error.message))
    console.error(error.message)
    if (response.status === 401) {
      yield put(authenticateFailure())
    }
    if (response.status === 404) {
      const currentBuilding = buildings.get(`${currentBuildingId}`)
      const currentFloor = floors.get(`${currentFloorId}`)
      if (!currentBuilding || !currentFloor) {
        yield put(locationRequest())
      }
    } else {
      Alert.error(`ERROR: ${error.message}`)
    }
  }
}

function* watchBookDeskSuccess() {
  yield takeEvery(
    [BOOK_DESK_SUCCESS, CANCEL_BOOKING_SUCCESS],
    updateContentUpdate,
  )
}

function* deselectAsset() {
  yield put(clearQuickView())
}

function* watchUpdateUnity() {
  yield takeEvery([FETCH_UPDATE_SUCCESS], updateUnity)
}

function* watchUpdateFutureUnity() {
  yield takeEvery([FETCH_FUTURE_VIEW], updateFutureUnity)
}

function* watchShowFloor() {
  yield take(UNITY_INIT_SUCCESS)
  yield takeEvery(SET_FLOOR, showFloor)
}

function* watchShowBuilding() {
  yield take(UNITY_INIT_SUCCESS)
  yield takeEvery(SET_BUILDING, showBuilding)
}

function* watchSelectAsset() {
  yield take(UNITY_INIT_SUCCESS)
  yield takeEvery(UNITY_CLICKED_ASSET, selectAsset)
}

function* watchDeselectAsset() {
  yield take(UNITY_INIT_SUCCESS)
  yield takeEvery(UNITY_DESELECTED_ASSET, deselectAsset)
}

function* watchUpdatePosition() {
  yield take(UNITY_INIT_SUCCESS)
  yield takeEvery(FETCH_UPDATE_SUCCESS, updatePosition)
}

export default function* unitySagas() {
  yield all([
    startUnity(),
    watchShowFloor(),
    watchShowBuilding(),
    watchUpdateUnity(),
    watchSelectAsset(),
    watchDeselectAsset(),
    watchUpdatePosition(),
    watchUpdateFutureUnity(),
    watchBookDeskSuccess(),
  ])
}
