import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import styled from 'styled-components'

import InlineLoading from './InlineLoading'

export const StyledButton = styled.button`
  font: inherit;
  width: 50%;
  cursor: pointer;
  font-size: 1.4rem;
  background-color: ${(props) => props.theme.loginButton};
  color: ${(props) => props.theme.actionButton};
  outline: none;
  border: none;
  border-radius: 3px;
  padding: 0 20px;
  line-height: 36px;
  display: inline-block;
  white-space: pre;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.02em;

  :disabled {
    background: ${(props) => props.theme.disabledButtonBg};
    color: ${(props) => props.theme.disabledButton};
    box-shadow: none;
    cursor: default;
  }
`
class SubmitButton extends PureComponent {
  render() {
    const {
      submitting,
      disabled,
      children,
      type = 'submit',
      ...rest
    } = this.props
    return (
      <StyledButton type={type} disabled={submitting || disabled} {...rest}>
        {submitting ? <InlineLoading /> : children}
      </StyledButton>
    )
  }
}

SubmitButton.propTypes = {
  children: PropTypes.object,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  type: PropTypes.string,
}

export default SubmitButton
