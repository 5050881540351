import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Translate } from 'react-localize-redux'

import ModalRow from 'containers/quickView/modal/ModalRow'
import ModalTitle from 'containers/quickView/modal/ModalTitle'
import ModalButtonRow from 'containers/quickView/modal/ModalButtonRow'
import ModalButton from 'containers/quickView/modal/ModalButton'
import Icon from 'components/Icon'
import WayFindButton from 'containers/quickView/modal/WayFindButton'
import { icons } from 'components/icon/icons'

class BookingSuccess extends Component {
  render() {
    const { onEnd, space, owner } = this.props

    return (
      <React.Fragment>
        {!owner && (
          <ModalRow flat>
            <ModalTitle>
              <Translate id="quickView.bookingSuccess" />
            </ModalTitle>
          </ModalRow>
        )}
        <ModalRow>
          <ModalButtonRow>
            {owner ? (
              <ModalButton wide owner onClick={onEnd}>
                <span>
                  <Translate id="billboard.mainPanel.removeBookingConfirmation.button.no" />
                </span>
              </ModalButton>
            ) : (
              <ModalButton wide onClick={onEnd}>
                <Icon name={icons.UNDO} />
                <span>
                  <Translate id="quickView.button.undoBooking" />
                </span>
              </ModalButton>
            )}
            <WayFindButton
              itemId={space.id}
              type="space"
              floorId={space.floorId}
            />
          </ModalButtonRow>
        </ModalRow>
      </React.Fragment>
    )
  }
}

BookingSuccess.propTypes = {
  onEnd: PropTypes.func,
  space: PropTypes.object,
  owner: PropTypes.bool,
}

export default BookingSuccess
