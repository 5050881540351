import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Translate, getTranslate } from 'react-localize-redux'

import StatisticsItem from 'containers/flowPlanner/StatisticsItem'
import Icon from 'components/Icon'
import DetailedInfoCard from 'containers/flowPlanner/DetailedInfoCard'
import { getBuildings, getCurrentBuildingId } from 'containers/app/selectors'
import {
  FAVORITE_USERS,
  BUILDING_USERS,
  SAME_BUILDING,
  OTHER_BUILDING,
  OFFICE,
  DESK,
} from 'utils/appVars'

const ListHolder = styled.div`
  ${(props) => props.openList && css``};
  ${(props) =>
    !props.openList &&
    css`
      height: 70px;
    `};
`

const InfiniteScrollHolder = styled.div.attrs({
  id: 'scrollableDiv',
})`
  overflow: auto;
  display: flex;
  flexdirection: column-reverse;
  max-height: 381px;
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border: 3.5px solid #fff;
    background-color: #d8d8d8;
    border-radius: 7px;
  }

  > div {
    width: 100%;
  }
  h4 {
    padding-left: 18px;
  }
  ${(props) =>
    props.showListOnly &&
    css`
      margin-top: 25px;
    `};
  ${(props) =>
    props.activeLists?.favoritesAttendance &&
    props.activeLists?.buildingAttendance &&
    css`
      max-height: 229px;
    `};
`
const MetaHolder = styled.div`
  display: flex;
  height: 70px;
  line-height: 70px;
  justify-content: space-between;
  padding-left: 13px;
  padding-right: 15px;
  > div > span:first-child {
    width: 19px;
    height: 19px;
    background-size: 18px;
    display: inline-block;
    margin: 0 0.2em;
  }
`
const DataHolder = styled.div`
  overflow: auto;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 229px;
  ${(props) =>
    props.activeLists?.favoritesAttendance &&
    !props.activeLists?.buildingAttendance &&
    css`
      max-height: 381px;
    `};
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border: 3.5px solid #fff;
    background-color: #d8d8d8;
    border-radius: 7px;
  }
`
const OpenList = styled.div`
  width: 20px;
  margin: 0 10px 0 0;
  transform: rotate(270deg);
  padding-top: 10px;
  font-size: 23px;
  height: 100%;
  :hover {
    cursor: pointer;
  }
  ${(props) =>
    props.openList &&
    css`
      i {
        transform: rotate(180deg);
      }
    `};
`

const typesMap = {
  VACATION: 'flowPlanner.offWork',
  HOME: 'flowPlanner.remote',
}

const DetailedInfoList = ({
  label,
  icon,
  hasIcon,
  favoritesAttendance,
  buildingAttendance,
  fetchMoreData,
  loadMorePages,
  showListOnly,
  closeOtherLists,
  isOpen,
  buildings,
  currentBuilding,
  listType,
  translate,
  activeLists,
}) => {
  const [listIsOpen, setListIsOpen] = useState(isOpen)
  const openList = () => {
    setListIsOpen(!listIsOpen)
    if (listType === FAVORITE_USERS) {
      closeOtherLists({
        buildingAttendance: activeLists.buildingAttendance,
        favoritesAttendance: !activeLists.favoritesAttendance,
      })
    }
    if (listType === BUILDING_USERS) {
      closeOtherLists({
        buildingAttendance: !activeLists.buildingAttendance,
        favoritesAttendance: activeLists.favoritesAttendance,
      })
    }
  }
  const getBuildingsName = (buildingId) => {
    return buildings.get(`${buildingId}`).getIn(['info', 'name'])
  }
  const handleAttendanceType = ({ type, buildingId }) => {
    if (type === OFFICE || type === DESK)
      return currentBuilding === buildingId ? SAME_BUILDING : OTHER_BUILDING
    return type
  }
  const getAttendanceLocation = ({ type, buildingId }) => {
    if (!type || type === SAME_BUILDING || type === OTHER_BUILDING) {
      const buildingName = buildings
        .get(`${buildingId}`)
        .getIn(['info', 'name'])
      return buildingName
    }
    const localizedAttendanceType = translate(typesMap[type])
    return localizedAttendanceType
  }
  const transformData = (array) => {
    const transformedArray = []
    array.forEach((item) => {
      const user = {}
      user.userInfo = item.user
      user.name = item.user.name
      user.id = item.user.id
      user.start = item.start
      user.end = item.end
      user.status = {}
      user.status.state = item.user.state
      user.buildingId = item.buildingId
      user.type = handleAttendanceType(item)
      transformedArray.push(user)
    })
    return transformedArray
  }
  favoritesAttendance = favoritesAttendance
    ? transformData(favoritesAttendance)
    : []
  buildingAttendance = buildingAttendance
    ? transformData(buildingAttendance)
    : []
  return (
    <ListHolder
      openList={listIsOpen}
      showListOnly={showListOnly}
      listIsOpen={listIsOpen}
    >
      {!showListOnly && (
        <MetaHolder onClick={openList}>
          <StatisticsItem value={label} hasIcon={hasIcon} iconName={icon} />
          <OpenList openList={listIsOpen}>
            <Icon name="back" />
          </OpenList>
        </MetaHolder>
      )}
      {listIsOpen && favoritesAttendance && (
        <DataHolder activeLists={activeLists}>
          {favoritesAttendance.map((att, index) => {
            return (
              <DetailedInfoCard
                key={index}
                user={att.userInfo}
                location={getAttendanceLocation(att)}
                type={att.type}
              />
            )
          })}
        </DataHolder>
      )}
      {(listIsOpen || showListOnly) && listType === BUILDING_USERS && (
        <InfiniteScrollHolder
          showListOnly={showListOnly}
          activeLists={activeLists}
        >
          <InfiniteScroll
            dataLength={buildingAttendance.length}
            next={fetchMoreData}
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
            hasMore={loadMorePages}
            scrollableTarget="scrollableDiv"
          >
            {buildingAttendance.map((attendance, index) => {
              return (
                <DetailedInfoCard
                  key={index}
                  user={attendance}
                  location={getAttendanceLocation(attendance)}
                  type={attendance.type || SAME_BUILDING}
                />
              )
            })}
          </InfiniteScroll>
        </InfiniteScrollHolder>
      )}
    </ListHolder>
  )
}

DetailedInfoList.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  hasIcon: PropTypes.bool,
  favoritesAttendance: PropTypes.array,
  buildingAttendance: PropTypes.array,
  fetchMoreData: PropTypes.func,
  loadMorePages: PropTypes.bool,
  showListOnly: PropTypes.bool,
  closeOtherLists: PropTypes.func,
  isOpen: PropTypes.bool,
  buildings: PropTypes.object,
  currentBuilding: PropTypes.number,
  listType: PropTypes.string,
  translate: PropTypes.func,
  activeLists: PropTypes.object,
}
const mapStateToProps = (state) => ({
  buildings: getBuildings(state),
  currentBuilding: getCurrentBuildingId(state),
  translate: getTranslate(state.get('localize')),
})

export default connect(mapStateToProps, {})(DetailedInfoList)
