import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Translate, getTranslate } from 'react-localize-redux'
import { format } from 'date-fns'

import { registerAttendanceRequest } from 'containers/flowPlanner/actions'
import {
  timeOptions,
  checkIfToearlierThanFrom,
  getWorkingHour,
} from 'utils/utilsFunctions'
import {
  DEFAULT_SPACE_START_TIME,
  DEFAULT_OPTIONS_START_HOUR,
  DEFAULT_SPACE_END_TIME,
  OFFICE,
  VACATION,
  HOME,
} from 'utils/appVars'
import { getIsFuture } from 'containers/searchPanel/filter/selectors'

const Wrapper = styled.div`
  height: auto;
  width: 100%;
  padding: 0 0 0 16px;
  box-sizing: border-box;
  margin-top: 10px;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  position: relative;
  font-size: 15px;
  font-weight: normal;
  :hover {
    cursor: pointer;
  }
`

const RadioButtonCheckbox = styled.label`
  position: absolute;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #2f3437;
  padding: 2px;
`
const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 9px;
  height: 9px;
  margin-right: 15px;

  &:hover ~ ${RadioButtonCheckbox} {
    &::after {
      font-family: 'Roboto';
      display: block;
      color: white;
      width: 10px;
      height: 10px;
      margin: 4px;
    }
  }
  &:checked + ${Item} {
    background: #2f3437;
    border: 2px solid white;
  }
  &:checked + ${RadioButtonCheckbox} {
    background-image: linear-gradient(to bottom, #2f3437 0%, #2f3437 100%),
      linear-gradient(to bottom, white 0%, white 100%);
    background-clip: content-box, padding-box;
    &::after {
      display: block;
      color: white;
      width: 9px;
      height: 9px;
      margin: 4px;
    }
  }
`
const RadioButtonLabel = styled.label`
  cursor: pointer;
`
const BookingBgr = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(59, 55, 55, 0.19);
`

const BookingHolder = styled.div`
  width: 252px;
  height: 222px;
  padding: 20px 0 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(149, 147, 147, 0.5);
  background-color: #fff;
  position: absolute;
  z-index: 4;
  top: 30%;
  left: 22%;
`
const BtnHolder = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 65px;
  border-top: 2px solid #e0e7ee;
  display: flex;
  justify-content: end;
  align-items: center;
`
const PopupBtn = styled.button`
  display: block;
  width: auto;
  height: 35px;
  border-radius: 5px;
  border: solid 1px #5b5757;
  background-color: #fff;
  color: #5b5757;
  font-size: 14px;
  margin-right: 5px;
  &:last-child {
    margin-right: 15px;
  }
  :hover {
    cursor: pointer;
  }
  :disabled {
    cursor: auto;
    filter: brightness(50%);
  }
  ${(props) =>
    props.submit &&
    css`
      color: #fff;
      background-color: #0178d4;
      border-color: #0178d4;
      margin-left: 12px;
    `};
  ${(props) =>
    !props.submit &&
    css`
      border: none;
      :hover {
        text-decoration: underline;
      }
    `};
`
const PopupInfo = styled.p`
  text-align: start;
  width: 100%;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 0 15px;
  padding: 0;
  ${(props) =>
    props.smaller &&
    css`
      margin-top: 8px;
      font-size: 15px;
      font-weight: normal;
      text-transform: capitalize;
    `};
`

const BookingPopup = ({
  cancel,
  selectedDate,
  registerAttendanceRequest,
  buildingId,
  currentLocale,
  timeFormat,
  defaultStart = DEFAULT_SPACE_START_TIME,
  defaultEnd = DEFAULT_SPACE_END_TIME,
  isFuture,
  translate,
}) => {
  // #region defining default times
  const currentHour = new Date().getHours()
  const currentMinutes = new Date().getMinutes()
  const startHour = isFuture ? Math.trunc(defaultStart / 60) : currentHour
  const intervalInMinutes = 30
  const startMinutes = isFuture ? defaultStart % 60 : currentMinutes
  const endHour = Math.trunc(defaultEnd / 60)
  const endMinute = defaultEnd % 60
  const startWorkingHour = getWorkingHour(
    startHour,
    startMinutes,
    selectedDate,
    timeFormat,
  )
  const lastWorkingHour = getWorkingHour(
    endHour,
    endMinute,
    selectedDate,
    timeFormat,
  )
  const dateToShow = format(selectedDate, 'EEE, MMM dd', {
    locale: currentLocale,
  })

  const defineToOptions = (newValue) => {
    setValueFrom(newValue)
    if (valueTo && checkIfToearlierThanFrom(valueTo.value, newValue.value)) {
      setToValue({})
      setDisabledSubmit(true)
    }

    setSelectedSlot({
      start: newValue,
      end: lastWorkingHour,
    })

    const options = timeOptions(
      intervalInMinutes,
      newValue.value,
      newValue.value.getHours(),
      newValue.value.getMinutes(),
      timeFormat,
    )
    setТimeOptionsАvailableTo(options)
  }

  useEffect(() => {
    defineToOptions(selectedSlot.start)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const [selectedSlot, setSelectedSlot] = useState({
    start: startWorkingHour,
    end: lastWorkingHour,
  })
  const [valueFrom, setValueFrom] = useState(selectedSlot.start)
  const [valueTo, setValueTo] = useState(selectedSlot.end)
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [timeOptionsАvailableFrom, setТimeOptionsАvailableFrom] = useState(
    timeOptions(
      intervalInMinutes,
      selectedDate,
      isFuture ? DEFAULT_OPTIONS_START_HOUR : startHour,
      startMinutes,
      timeFormat,
    ),
  )
  const [timeOptionsАvailableTo, setТimeOptionsАvailableTo] = useState(
    timeOptions(
      intervalInMinutes,
      selectedDate,
      startHour,
      startMinutes,
      timeFormat,
    ),
  )
  const [attendanceType, setAttendanceType] = React.useState('OFFICE')

  const setToValue = (newValue) => {
    setValueTo(newValue)
    setSelectedSlot({
      start: selectedSlot.start,
      end: newValue,
    })
    setDisabledSubmit(false)
  }

  const registerAtt = () => {
    const userTimeSlot = {
      start: selectedSlot.start.value,
      end: selectedSlot.end.value,
    }
    const userInfo = { userTimeSlot, buildingId, attendanceType }
    registerAttendanceRequest(userInfo)
    cancel()
  }

  const handleSelectChange = (value) => {
    setAttendanceType(value)
  }

  return (
    <BookingBgr>
      <BookingHolder>
        <PopupInfo>
          <Translate id="flowPlanner.selectWorkLocation" />
        </PopupInfo>
        <PopupInfo smaller>{dateToShow.replaceAll('.', '')}</PopupInfo>
        <Wrapper>
          <Item onClick={() => handleSelectChange(OFFICE)}>
            <RadioButton
              type="radio"
              id={OFFICE}
              checked={attendanceType === OFFICE}
            />
            <RadioButtonCheckbox />
            <Translate>
              {({ translate }) => (
                <RadioButtonLabel>
                  {translate('flowPlanner.office')}
                </RadioButtonLabel>
              )}
            </Translate>{' '}
          </Item>
          <Item onClick={() => handleSelectChange(HOME)}>
            <RadioButton
              type="radio"
              id={HOME}
              checked={attendanceType === HOME}
            />
            <RadioButtonCheckbox />

            <Translate>
              {({ translate }) => (
                <RadioButtonLabel>
                  {translate('flowPlanner.remote')}
                </RadioButtonLabel>
              )}
            </Translate>
          </Item>
          <Item onClick={() => handleSelectChange(VACATION)}>
            <RadioButton
              type="radio"
              id={VACATION}
              checked={attendanceType === VACATION}
            />
            <RadioButtonCheckbox />
            <Translate>
              {({ translate }) => (
                <RadioButtonLabel>
                  {translate('flowPlanner.offWork')}
                </RadioButtonLabel>
              )}
            </Translate>{' '}
          </Item>
        </Wrapper>
        <BtnHolder>
          <PopupBtn onClick={cancel}>
            <Translate id="quickView.button.cancelBooking" />
          </PopupBtn>
          <PopupBtn onClick={registerAtt}>
            <Translate id="billboard.mainPanel.removeBookingConfirmation.button.save" />
          </PopupBtn>
        </BtnHolder>
      </BookingHolder>
    </BookingBgr>
  )
}

BookingPopup.propTypes = {
  cancel: PropTypes.func,
  selectedDate: PropTypes.instanceOf(Date),
  registerAttendanceRequest: PropTypes.func,
  buildingId: PropTypes.number,
  currentLocale: PropTypes.object,
  timeFormat: PropTypes.string,
  defaultStart: PropTypes.string,
  defaultEnd: PropTypes.string,
  isFuture: PropTypes.bool,
  translate: PropTypes.func,
}

const mapStateToProps = (state) => ({
  isFuture: getIsFuture(state),
  translate: getTranslate(state.get('localize')),
})

export default connect(mapStateToProps, {
  registerAttendanceRequest,
})(BookingPopup)
