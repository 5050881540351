import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import get from 'lodash/get'

import FilterItem from 'containers/searchPanel/filter/FilterItem'
import {
  setPropFilter,
  clearFilter,
  clearDataset,
} from 'containers/searchPanel/filter/actions'
import { getPropFilters } from 'containers/searchPanel/filter/selectors'
import { getMetaProps, getRoomFilters } from 'containers/app/selectors'
import toJS from 'components/toJS'
import FilterItemGrid from 'containers/searchPanel/filter/FilterItemGrid'
import FilterContainer from 'containers/searchPanel/filter/FilterContainer'
import FilterButtonRow from 'containers/searchPanel/filter/FilterButtonRow'
import FilterButton from 'containers/searchPanel/filter/FilterButton'
import MinSeatsFilter from 'containers/searchPanel/filter/MinSeatsFilter'
import DurationFilter from 'containers/searchPanel/filter/DurationFilter'
import BookableFilter from 'containers/searchPanel/filter/BookableFilter'

const propsMap = {
  Screen: 'filter.props.screen',
  Projector: 'filter.props.projector',
  Phone: 'filter.props.phone',
  Video: 'filter.props.video',
  Sofa: 'filter.props.sofa',
}

class RoomFilters extends Component {
  onPropClick = (e) => {
    const { setPropFilter, propFilters } = this.props
    const key = e.target.value
    const subFilter = get(propFilters, key)
    setPropFilter(key, !subFilter)
  }

  render() {
    const { propFilters, roomMetaProps, clearFilter, clearDataset } = this.props
    return (
      <FilterContainer>
        <BookableFilter />
        <MinSeatsFilter />
        <DurationFilter />
        <Translate>
          {({ translate }) => (
            <FilterItemGrid>
              {roomMetaProps.map((roomMetaProp) => (
                <FilterItem
                  key={roomMetaProp.id}
                  active={get(propFilters, roomMetaProp.name)}
                  onClick={this.onPropClick}
                  value={roomMetaProp.name}
                  caption={translate(propsMap[roomMetaProp.name])}
                  iconName={roomMetaProp.res}
                />
              ))}
            </FilterItemGrid>
          )}
        </Translate>
        <FilterButtonRow>
          <FilterButton dark onClick={() => clearFilter()}>
            <Translate id="filter.title.clear" />
          </FilterButton>
          <FilterButton onClick={() => clearDataset()}>
            <Translate id="filter.title.hide" />
          </FilterButton>
        </FilterButtonRow>
      </FilterContainer>
    )
  }
}

RoomFilters.propTypes = {
  clearDataset: PropTypes.func,
  clearFilter: PropTypes.func,
  propFilters: PropTypes.object,
  roomMetaProps: PropTypes.array,
  setPropFilter: PropTypes.func,
}

const mapStateToProps = (state) => {
  const metaProps = getMetaProps(state)
  const roomMetaPropIds = getRoomFilters(state)
  const roomMetaProps = roomMetaPropIds.map((id) => metaProps.get(`${id}`))

  return {
    propFilters: getPropFilters(state),
    roomMetaProps,
  }
}

export default connect(mapStateToProps, {
  setPropFilter,
  clearFilter,
  clearDataset,
})(toJS(RoomFilters))
