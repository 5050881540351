import isFuture from 'date-fns/isFuture'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import isWithinInterval from 'date-fns/isWithinInterval'
import addMinutes from 'date-fns/addMinutes'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { enUS } from 'date-fns/locale'

const minimumMeetingTime = 15

// returns currently ongoing meeting, or undefined
export function ongoingMeeting(arr) {
  return arr.find((meeting) =>
    isWithinInterval(new Date(), {
      start: new Date(meeting.start),
      end: new Date(meeting.end),
    }),
  )
}

// returns index of currently ongoing meeting, or undefined
export function indexOfOngoingMeeting(arr) {
  return arr.findIndex((meeting) =>
    isWithinInterval(new Date(), {
      start: new Date(meeting.start),
      end: new Date(meeting.end),
    }),
  )
}

// returns next meeting, or undefined
export function nextMeeting(arr) {
  return arr.find((meeting) => isFuture(new Date(meeting.start)))
}

// returns minutes until next meeting, or Infinity
export function minutesToNextMeeting(arr, comparisonDate = new Date()) {
  const meeting = nextMeeting(arr)
  if (!meeting) return Infinity
  return differenceInMinutes(new Date(meeting.start), comparisonDate)
}

export function timeToNextMeetingInWords(arr, locale = enUS) {
  const minutes = minutesToNextMeeting(arr)
  const time = addMinutes(new Date(), minutes === Infinity ? 0 : minutes)
  return formatDistanceToNow(time, { locale: locale })
}

// returns true if a meeting can fit from comparison date until time to next meeting, else false
export function canBookMeeting(arr, comparisonDate) {
  return (
    !ongoingMeeting(arr) &&
    minutesToNextMeeting(arr, comparisonDate) > minimumMeetingTime
  )
}

// checks array for a time when you can fit the smallest timeslot for a meeting
function timeWhenFree(arr) {
  const [meeting, ...rest] = arr
  if (canBookMeeting(rest, new Date(meeting.end))) {
    return meeting.end
  }
  return timeWhenFree(rest)
}

// returns number of minutes until room can fit a new meeting of the smallest timeslot
export function minutesUntilFree(arr) {
  const idx = indexOfOngoingMeeting(arr)
  if (idx < 0) {
    return 0
  }
  const end = timeWhenFree(arr.slice((arr.length - idx) * -1))
  return differenceInMinutes(new Date(end), new Date())
}

export function timeUntilFreeInWords(arr, locale = enUS) {
  const time = addMinutes(new Date(), minutesUntilFree(arr))
  return formatDistanceToNow(time, { locale: locale })
}

export function isAvailableNow(status) {
  return status === 'FREE' || status === 'BUSY' ? true : false
}
