import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate, getTranslate } from 'react-localize-redux'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import differenceInSeconds from 'date-fns/differenceInSeconds'

import ModalRow from 'containers/quickView/modal/ModalRow'
import ModalTitle from 'containers/quickView/modal/ModalTitle'
import ModalButtonRow from 'containers/quickView/modal/ModalButtonRow'
import ModalButton from 'containers/quickView/modal/ModalButton'
import Icon from 'components/Icon'
import {
  getError,
  getIsFetching,
  getMeetingSlots,
  getDesiredMeetingSlot,
} from 'containers/quickView/spaceModal/bookingFlow/selectors'
import { icons } from 'components/icon/icons'
import QuickViewInput from 'containers/quickView/components/QuickViewInput'

const MIN_TO_MS = 60000

class BookingFailure extends Component {
  constructor(props) {
    super(props)

    this.state = {
      meetingSubject: '',
    }
  }
  handleBooking = () => {
    const { onBooking, desiredMeetingSlot } = this.props
    const meetingDate = new Date(desiredMeetingSlot.duration * MIN_TO_MS)
    let diff = differenceInMinutes(meetingDate, Date.now())
    const diffSecs = differenceInSeconds(meetingDate, Date.now())
    const subject = this.currentSubject()

    if (diffSecs / 60 > diff) diff += 1

    onBooking(diff, subject)
  }

  currentSubject = () => {
    const { meetingSubject } = this.state
    const { translate } = this.props
    return meetingSubject
      ? meetingSubject
      : translate('quickView.defaultMeetingSubject')
  }

  render() {
    const { onClose, isFetching, error, translate } = this.props

    return (
      <React.Fragment>
        <ModalRow>
          <ModalTitle>
            <span>
              <Translate id="quickView.meetingSubject" />
            </span>
          </ModalTitle>
          <QuickViewInput
            type="text"
            name="subject"
            value={this.state.meetingSubject}
            onChange={this.handleChangeSubject}
            placeholder={translate('quickView.defaultMeetingSubject')}
          />
        </ModalRow>
        <ModalRow flat>
          <ModalTitle>
            <Translate id="quickView.bookingFailed" />
          </ModalTitle>
          <span>{error}</span>
        </ModalRow>
        <ModalRow>
          <ModalButtonRow>
            <ModalButton
              wide
              primary
              onClick={this.handleBooking}
              disabled={isFetching}
            >
              <span>
                <Translate id="quickView.button.book" />
              </span>
            </ModalButton>
          </ModalButtonRow>
        </ModalRow>
      </React.Fragment>
    )
  }
}

BookingFailure.propTypes = {
  desiredMeetingSlot: PropTypes.object,
  isFetching: PropTypes.bool,
  meetingSlots: PropTypes.object,
  onBooking: PropTypes.func,
  onClose: PropTypes.func,
  setBookingFlowMeetingDuration: PropTypes.func,
  translate: PropTypes.func,
  error: PropTypes.object,
}

const mapStateToProps = (state) => ({
  error: getError(state),
  isFetching: getIsFetching(state),
  meetingSlots: getMeetingSlots(state),
  desiredMeetingSlot: getDesiredMeetingSlot(state),
  translate: getTranslate(state.get('localize')),
})

export default connect(mapStateToProps)(BookingFailure)
