import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'

import SpaceItem from 'containers/searchPanel/results/SpaceItem'
import {
  getSpaceResults,
  getUserResults,
} from 'containers/searchPanel/search/selectors'
import ResultList from 'containers/searchPanel/results/ResultList'
import ResultBar from 'containers/searchPanel/results/ResultBar'
import ResultHeader from 'containers/searchPanel/results/ResultHeader'
import ColleagueHeader from 'containers/searchPanel/results/ColleagueHeader'
import FlexSpacer from 'components/FlexSpacer'
import UserItem from 'containers/searchPanel/results/UserItem'
import { getActiveSpaceId } from 'containers/quickView/selectors'
import { getActiveByIndex } from 'containers/searchPanel/results/selectors'
import { setActiveByIndex } from 'containers/searchPanel/results/actions'
import {
  getSpaces,
  getFloors,
  getCurrentBuildingId,
} from 'containers/app/selectors'

class SearchResults extends Component {
  sortIntoBuildings = (spaceIds) => {
    const { spaces, floors } = this.props
    const splitIntoBuildings = {}
    spaceIds.forEach((id) => {
      const space = spaces.get(`${id}`)
      if (!space) return null
      const floorId = space.get('floorId')
      const floor = floors.get(`${floorId}`)
      if (!floor) return null
      const buildingId = floor.get('buildingId')
      splitIntoBuildings[buildingId]
        ? splitIntoBuildings[buildingId].push(id)
        : (splitIntoBuildings[buildingId] = [id])
    })
    return splitIntoBuildings
  }

  render() {
    const {
      spaceIds,
      userResults,
      activeSpaceId,
      activeIndex,
      setActiveByIndex,
      currentBuildingId,
    } = this.props

    const spacesOnBuildings = this.sortIntoBuildings(spaceIds)

    const showCurrentBuildingFirst = (buildingIds) => {
      const currentBuildingIdToString = currentBuildingId.toString()
      if (buildingIds.includes(currentBuildingIdToString)) {
        return [
          buildingIds.find((id) => id === currentBuildingIdToString),
          ...buildingIds.filter((id) => id !== currentBuildingIdToString),
        ]
      }
      return buildingIds
    }
    return (
      <Fragment>
        <ResultList>
          {userResults.size > 0 ? (
            <ColleagueHeader>
              {userResults.map((user, index) => (
                <UserItem
                  key={user.getIn(['info', 'mail'])}
                  user={user}
                  active={index === activeIndex}
                  onClick={() => setActiveByIndex(index)}
                />
              ))}
            </ColleagueHeader>
          ) : null}
          {Object.values(spacesOnBuildings).length > 0 &&
            showCurrentBuildingFirst(Object.keys(spacesOnBuildings)).map(
              (key, index) => {
                return (
                  <ResultHeader key={index} id={key}>
                    {spacesOnBuildings[key].map((spaceId) => (
                      <SpaceItem
                        key={spaceId}
                        id={spaceId}
                        active={spaceId === activeSpaceId}
                      />
                    ))}
                  </ResultHeader>
                )
              },
            )}
        </ResultList>
        <FlexSpacer />
        <ResultBar>
          <span>
            <Translate id="billboard.searchPanel.results" />
          </span>
          <span />
          <span>{spaceIds.size + userResults.size}</span>
        </ResultBar>
      </Fragment>
    )
  }
}

SearchResults.propTypes = {
  activeIndex: PropTypes.number,
  activeSpaceId: PropTypes.number,
  setActiveByIndex: PropTypes.func,
  spaceIds: PropTypes.object,
  spaces: PropTypes.object,
  userResults: PropTypes.object,
  floors: PropTypes.object,
  currentBuildingId: PropTypes.number,
}

const mapStateToProps = (state) => ({
  spaces: getSpaces(state),
  spaceIds: getSpaceResults(state),
  userResults: getUserResults(state),
  activeSpaceId: getActiveSpaceId(state),
  activeIndex: getActiveByIndex(state),
  floors: getFloors(state),
  currentBuildingId: getCurrentBuildingId(state),
})

export default connect(mapStateToProps, { setActiveByIndex })(SearchResults)
