import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ModalButtonRow from 'containers/quickView/modal/ModalButtonRow'
import ModalRow from 'containers/quickView/modal/ModalRow'
import WayFindButton from 'containers/quickView/modal/WayFindButton'

class SpaceActions extends Component {
  render() {
    const { space } = this.props
    return (
      <ModalRow>
        <ModalButtonRow>
          <WayFindButton
            itemId={space.id}
            type="space"
            floorId={space.floorId}
          />
        </ModalButtonRow>
      </ModalRow>
    )
  }
}

SpaceActions.propTypes = {
  space: PropTypes.object,
}

export default SpaceActions
