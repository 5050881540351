import fetch from 'isomorphic-fetch'

import { config } from 'api/public'

const joinParamArray = (name, arr) => arr.map((i) => `${name}=${i}`).join('&')

export const getAvailableDesks = async (start, end, floors) => {
  const argus_url = await config('argus_url')
  const response = await fetch(
    `${argus_url}/secure/deskbooking/available?start=${start}&end=${end}&${joinParamArray(
      'fId',
      floors,
    )}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  )
  if (response.ok) {
    const data = await response.json()
    return Promise.resolve(data)
  }
  const error = await response.json()
  return Promise.reject({ error, response })
}

export const getDeskEvents = async (deskId) => {
  const argus_url = await config('argus_url')
  const response = await fetch(
    `${argus_url}/secure/deskbooking/desk/${deskId}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  )
  if (response.ok) {
    const data = await response.json()
    return Promise.resolve(data)
  }
  const error = await response.json()
  return Promise.reject({ error, response })
}

export const getAllUserBookings = async () => {
  const argus_url = await config('argus_url')
  const response = await fetch(`${argus_url}/secure/deskbooking/user`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
  if (response.ok) {
    const data = await response.json()
    return Promise.resolve(data)
  }
  const error = await response.json()
  return Promise.reject({ error, response })
}

export const bookDesk = async (deskId, userId, start, end) => {
  const argus_url = await config('argus_url')
  const response = await fetch(
    `${argus_url}/secure/deskbooking/desk/${deskId}`,
    {
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, start, end }),
    },
  )
  if (response.ok) {
    const data = await response.json()
    return Promise.resolve(data)
  }
  const error = await response.json()
  return Promise.reject({ error, response })
}

export const deleteAssetBooking = async (deskBookingId, eventId) => {
  const argus_url = await config('argus_url')
  const response = await fetch(
    `${argus_url}/secure/v2/asset/${deskBookingId}/event/${eventId}`,
    {
      credentials: 'include',
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  )
  if (response.ok) {
    return Promise.resolve(response)
  }
  const error = await response.json()
  return Promise.reject({ error, response })
}

export const cancelBooking = async (deskBookingId) => {
  const argus_url = await config('argus_url')
  const response = await fetch(
    `${argus_url}/secure/deskbooking/event/${deskBookingId}`,
    {
      credentials: 'include',
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  )
  if (response.ok) {
    return Promise.resolve(response)
  }
  const error = await response.json()
  return Promise.reject({ error, response })
}

export const cancelAssetBooking = async (deskBookingId, eventId) => {
  const argus_url = await config('argus_url')
  const response = await fetch(
    `${argus_url}/secure/v2/asset/${deskBookingId}/event/${eventId}/end`,
    {
      credentials: 'include',
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  )
  if (response.ok) {
    return Promise.resolve(response)
  }
  const error = await response.json()
  return Promise.reject({ error, response })
}
