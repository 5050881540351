import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import UIWrapper from 'components/UIWrapper'
import MainPanel from 'containers/MainPanel'
import SearchPanel from 'containers/SearchPanel'
import TouchActions from 'containers/TouchActions'
import UnityLoader from 'containers/UnityLoader'
import QuickView from 'containers/QuickView'
import {
  setBuilding,
  updateRequest,
  locationRequest,
  updateDeskBookingsRequest,
  fetchFutureView,
} from 'containers/app/actions'
import {
  getHasFetched,
  getPollingActive,
  getCurrentFloorId,
} from 'containers/app/selectors'
import { getUnityHasLoaded } from 'containers/unityLoader/selectors'
import {
  getIsFuture,
  getDateFilter,
  getSelectedIntelval,
} from 'containers/searchPanel/filter/selectors'
import { setMapInterval } from 'containers/searchPanel/filter/actions'
class Billboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isPrevDateInFuture: false,
    }
  }
  componentDidMount() {
    const { setBuilding, locationRequest } = this.props

    const buildingId = window.localStorage.getItem('buildingId')
    const floorId = window.localStorage.getItem('floorId')

    if (!buildingId || !floorId) {
      locationRequest()
    } else {
      setBuilding(parseInt(buildingId, 10), parseInt(floorId, 10))
    }
    this.contentPollInterval = setInterval(this.pollContent, 3 * 1000)
  }

  componentWillUnmount() {
    clearInterval(this.contentPollInterval)
  }

  pollContent = () => {
    const {
      isFuture,
      updateRequest,
      currentFloorId,
      pollingActive,
      dateFilter,
      fetchFutureView,
      updateDeskBookingsRequest,
      selectedInterval,
      unityHasLoaded,
    } = this.props
    const useDefaultTimes = Object.values(selectedInterval).length < 0

    const start = useDefaultTimes
      ? new Date(dateFilter.setHours(6, 0, 0, 0))
      : selectedInterval.start
    const end = useDefaultTimes
      ? new Date(dateFilter.setHours(18, 0, 0, 0))
      : selectedInterval.end
    setMapInterval({
      start,
      end,
    })
    if (pollingActive && currentFloorId && unityHasLoaded) {
      if (isFuture) {
        this.setState({ isPrevDateInFuture: true })
        fetchFutureView()
      } else {
        if (this.state.isPrevDateInFuture === true) {
          updateRequest([currentFloorId], true)
          this.setState({ isPrevDateInFuture: false })
        } else {
          updateRequest([currentFloorId])
        }
      }
      updateDeskBookingsRequest()
    }
  }

  render() {
    const { hasFetched, unityHasLoaded } = this.props
    if (!hasFetched) return null
    return (
      <Fragment>
        <UnityLoader />
        <QuickView />
        {!(process.env.NODE_ENV !== 'development' && !unityHasLoaded) && (
          <UIWrapper>
            <MainPanel />
            <SearchPanel />
            <TouchActions />
          </UIWrapper>
        )}
      </Fragment>
    )
  }
}

Billboard.propTypes = {
  currentFloorId: PropTypes.number,
  hasFetched: PropTypes.bool,
  locationRequest: PropTypes.func,
  pollingActive: PropTypes.bool,
  setBuilding: PropTypes.func,
  unityHasLoaded: PropTypes.bool,
  updateRequest: PropTypes.func,
  isFuture: PropTypes.bool,
  dateFilter: PropTypes.object,
  fetchFutureView: PropTypes.func,
  updateDeskBookingsRequest: PropTypes.func,
  selectedInterval: PropTypes.object,
  setMapInterval: PropTypes.func,
}

const mapStateToProps = (state) => ({
  hasFetched: getHasFetched(state),
  unityHasLoaded: getUnityHasLoaded(state),
  pollingActive: getPollingActive(state),
  currentFloorId: getCurrentFloorId(state),
  isFuture: getIsFuture(state),
  dateFilter: getDateFilter(state),
  selectedInterval: getSelectedIntelval(state),
})

export default connect(mapStateToProps, {
  updateRequest,
  setBuilding,
  locationRequest,
  fetchFutureView,
  updateDeskBookingsRequest,
  setMapInterval,
})(Billboard)
