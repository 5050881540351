import { makeSelector } from 'containers/app/selectors'

const activeSpaceId = (state) => state.getIn(['quickView', 'activeSpaceId'])
const activeSignId = (state) => state.getIn(['quickView', 'activeSignId'])
const activeUser = (state) => state.getIn(['quickView', 'activeUser'])
const activeUserBookings = (state) =>
  state.getIn(['quickView', 'activeUserBookings'])

const info = (state) => state.getIn(['quickView', 'info'])
const users = (state) => state.getIn(['quickView', 'userSearch', 'users'])
const registeredSlot = (state) => state.getIn(['quickView', 'registeredSlot'])
const bookingMessage = (state) => state.getIn(['quickView', 'bookingMessage'])
const disabledSubmit = (state) => state.getIn(['quickView', 'disabledSubmit'])
const selectedUserDetails = (state) =>
  state.getIn(['quickView', 'selectedUserDetails'])
const isFetching = (state) => state.getIn(['quickView', 'isFetching'])

export const getIsFetching = makeSelector(isFetching)
export const getSelectedUserDetails = makeSelector(selectedUserDetails)
export const getActiveSpaceId = makeSelector(activeSpaceId)
export const getActiveSignId = makeSelector(activeSignId)
export const getActiveUser = makeSelector(activeUser)
export const getInfo = makeSelector(info)
export const getUsers = makeSelector(users)
export const getRegisteredSlot = makeSelector(registeredSlot)
export const getActiveUserBookings = makeSelector(activeUserBookings)
export const getBookingMessage = makeSelector(bookingMessage)
export const getSubmitDisabled = makeSelector(disabledSubmit)
