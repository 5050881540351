import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { loginRequest } from 'pages/login/actions'
import { getClientAuthentication, getBaseUrl } from 'containers/app/selectors'
import Button from 'components/Button'
import { config } from 'api/public'
import LoginForm from 'pages/login/LoginForm'

const LoginButton = styled(Button)`
  background-color: transparent;
  left: 0;
  right: 0;
  width: 319px;
  height: 40px;
  margin: 0 auto 20px auto;
`
const OpenIdLoginWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`

const StyledLink = styled.p`
  color: #1a1515;
  font-size: 14px;
  margin: 0 auto;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const buttonPngWidth = '220px'
const buttonPngHeight = '50px'

class LoginAuth extends Component {
  state = {
    showCredentialsModal: false,
  }
  handleUsernamePassword = (values) => {
    const { loginRequest } = this.props
    loginRequest({ ...values, deviceType: 'WEB' })
  }

  handleGoogleOAuth = async () => {
    const { baseUrl } = this.props

    const argus_url = await config('argus_url')
    window.location = `${argus_url}/public/login/oauth2-google/web/start/?successPath=${baseUrl}oauthsuccess`
  }

  handleMicrosoftOAuth = async () => {
    const { baseUrl } = this.props

    const argus_url = await config('argus_url')
    window.location = `${argus_url}/public/login/oauth2-microsoft/web/start/?successPath=${baseUrl}oauthsuccess`
  }

  handleOpenIdOAuth = async () => {
    const { baseUrl } = this.props

    const argus_url = await config('argus_url')
    window.location = `${argus_url}/public/login/oauth2-openid/web/start/?successPath=${baseUrl}oauthsuccess`
  }

  openCredentialsModal = () => {
    const { hideLoginTitle } = this.props
    this.setState({ showCredentialsModal: true })
    hideLoginTitle()
  }
  hideCredentialsModal = () => {
    const { hideLoginTitle } = this.props
    this.setState({ showCredentialsModal: false })
    hideLoginTitle()
  }
  createLoginButton(loginType) {
    if (!this.state.showCredentialsModal) {
      if (loginType === 'oauth2-microsoft') {
        const MS_Login = `${process.env.PUBLIC_URL}/ms-signin.svg`
        return (
          <LoginButton key={loginType} onClick={this.handleMicrosoftOAuth}>
            <img src={MS_Login} alt="Microsoft OAuth Logo" />
          </LoginButton>
        )
      }

      if (loginType === 'oauth2-google') {
        const Google_Login = `${process.env.PUBLIC_URL}/google.png`
        return (
          <LoginButton key={loginType} onClick={this.handleGoogleOAuth} primary>
            <img
              src={Google_Login}
              width={buttonPngWidth}
              height={buttonPngHeight}
              alt="Google logo"
            />
          </LoginButton>
        )
      }
      if (loginType === 'oauth2-openid') {
        const openId_Login = `${process.env.PUBLIC_URL}/open_id.png`
        return (
          <LoginButton key={loginType} onClick={this.handleOpenIdOAuth} primary>
            <img
              src={openId_Login}
              width={buttonPngWidth}
              height={buttonPngHeight}
              alt="OpenId logo"
            />
          </LoginButton>
        )
      }

      if (loginType === 'username-password')
        return (
          <StyledLink key={loginType} onClick={this.openCredentialsModal}>
            Sign in with credentials
          </StyledLink>
        )
    }

    if (loginType === 'username-password' && this.state.showCredentialsModal)
      return (
        <LoginForm
          key={`${loginType}2`}
          onSubmit={this.handleUsernamePassword}
          hideLoginTitle={this.hideCredentialsModal}
        />
      )
  }

  render() {
    const { authType } = this.props

    if (!authType) return null

    return (
      <>
        {authType
          .get('authOptions')
          .map((authOption) => this.createLoginButton(authOption.get('id')))}
      </>
    )
  }
}

LoginAuth.propTypes = {
  authType: PropTypes.object,
  loginRequest: PropTypes.any,
  baseUrl: PropTypes.string,
  hideLoginTitle: PropTypes.func,
}

const mapStateToProps = (state) => ({
  authType: getClientAuthentication(state),
  baseUrl: getBaseUrl(state),
})

export default connect(mapStateToProps, { loginRequest })(LoginAuth)
