import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import QuickViewContainer from 'containers/quickView/QuickViewContainer'
import SpaceModal from 'containers/quickView/SpaceModal'
import UserModal from 'containers/quickView/UserModal'
import InfoModal from 'containers/quickView/InfoModal'
import SignModal from 'containers/quickView/SignModal'
import {
  getActiveSpaceId,
  getActiveUser,
  getInfo,
  getActiveSignId,
} from 'containers/quickView/selectors'
import { getChangeModalPosition } from 'containers/flowPlanner/selectors'

class QuickView extends Component {
  render() {
    const {
      activeSpaceId,
      activeSignId,
      activeUser,
      activeInfo,
      changeModalPosition,
    } = this.props
    return (
      <QuickViewContainer>
        {activeSpaceId && <SpaceModal id={activeSpaceId} />}
        {activeUser && (
          <UserModal
            user={activeUser}
            changeModalPosition={changeModalPosition}
          />
        )}
        {activeInfo && <InfoModal />}
        {activeSignId && <SignModal id={activeSignId} />}
      </QuickViewContainer>
    )
  }
}

QuickView.propTypes = {
  activeInfo: PropTypes.object,
  activeSignId: PropTypes.number,
  activeSpaceId: PropTypes.number,
  activeUser: PropTypes.object,
  changeModalPosition: PropTypes.object,
}

const mapStateToProps = (state) => ({
  activeSpaceId: getActiveSpaceId(state),
  activeUser: getActiveUser(state),
  activeInfo: getInfo(state),
  activeSignId: getActiveSignId(state),
  changeModalPosition: getChangeModalPosition(state),
})

export default connect(mapStateToProps)(QuickView)
