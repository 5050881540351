import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import Select, { components, MenuListProps } from 'react-select'

import { selectedItemIndex, scrollTheValueToTop } from 'utils/utilsFunctions'

const SelectHolder = styled.div`
  cursor: pointer;
  display: flex;
  > i {
    display: block;
    line-height: 40px;
    margin-right: 5px;
  }
  & > div > div {
    border: 1px solid #e0e7ee;
    width: 11rem;
    cursor: pointer;
    div, svg {
      color: #5b5757;
      font-family: Roboto;
      font-size: 12px;
      font-weight: normal;
      &::-webkit-scrollbar {
        width: 12px;
      }
    
      &::-webkit-scrollbar-track {
        background: #fff;
      }
    
      &::-webkit-scrollbar-thumb {
        border: 3.5px solid #fff;
        background-color: #d8d8d8;
        border-radius: 7px;
      }
      }
    }
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
`

const TimePicker = ({
  options,
  selectValue,
  value,
  translate,
  className,
  mapFilter,
  children,
  openMenu,
}) => {
  const handleChange = (newValue) => {
    selectValue(newValue)
    setMenuIsOpen(false)
  }
  const [wasMenuOpen, setMenuWasOpen] = useState(false)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  let selectRef = null

  useEffect(() => {
    if (openMenu) {
      selectRef.focus()
    } else {
      selectRef.blur()
    }
  }, [openMenu]) // eslint-disable-line react-hooks/exhaustive-deps

  const mapIntervalStyles = {
    valueContainer: (styles) => {
      return {
        ...styles,
        padding: 0,
        justifyContent: 'center',
      }
    },
    option: (styles, state) => {
      if (state.isSelected && !wasMenuOpen) {
        scrollTheValueToTop(state.innerProps.id)
      }
      return {
        ...styles,
        backgroundColor:
          state.isFocused || state.isSelected ? '#999999' : 'transparent',
      }
    },
  }

  const defaultStyles = {
    option: (base, state) => {
      if (state.isSelected && !wasMenuOpen) {
        scrollTheValueToTop(state.innerProps.id)
      }
      return {
        ...base,
        color: state.isSelected ? 'white !important' : 'inherit',
      }
    },
  }

  const selectedDateIndex = selectedItemIndex(options, value)

  const onMenuOpen = (state) => {
    setTimeout(() => {
      setMenuWasOpen(true)
    }, 1000)
  }
  const onMenuClose = (state) => {
    setMenuWasOpen(false)
  }
  return (
    <SelectHolder className={className}>
      {children}
      <Select
        ref={(ref) => {
          selectRef = ref
        }}
        menuIsOpen={menuIsOpen}
        options={options}
        value={options[selectedDateIndex] ?? value}
        onChange={handleChange}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        onFocus={() => setMenuIsOpen(true)}
        onBlur={() => setMenuIsOpen(false)}
        placeholder={translate('quickView.form.select')}
        styles={(mapFilter && mapIntervalStyles) || defaultStyles}
        maxMenuHeight={250}
      />
    </SelectHolder>
  )
}

TimePicker.propTypes = {
  value: PropTypes.object,
  options: PropTypes.array,
  selectValue: PropTypes.func,
  translate: PropTypes.func,
  className: PropTypes.string,
  mapFilter: PropTypes.bool,
  children: PropTypes.node,
  openMenu: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  translate: getTranslate(state.get('localize')),
})

export default connect(mapStateToProps, {})(TimePicker)
