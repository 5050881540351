export const MEETING_REQUEST = 'containers/quickView/spaceModal/MEETING_REQUEST'
export const MEETING_SUCCESS = 'containers/quickView/spaceModal/MEETING_SUCCESS'
export const MEETING_FAILURE = 'containers/quickView/spaceModal/MEETING_FAILURE'
export const CLOSE_BOOKING_UI =
  'containers/quickView/spaceModal/CLOSE_BOOKING_UI'
export const MEETING_SELECT = 'containers/quickView/spaceModal/MEETING_SELECT'

export const END_MEETING_REQUEST =
  'containers/quickView/spaceModal/END_MEETING_REQUEST'
export const END_MEETING_SUCCESS =
  'containers/quickView/spaceModal/END_MEETING_SUCCESS'
export const END_MEETING_FAILURE =
  'containers/quickView/spaceModal/END_MEETING_FAILURE'
export const END_MEETING_CONFIRMED =
  'containers/quickView/spaceModal/END_MEETING_CONFIRMED'

export const FETCH_MEETING_SLOTS_SUCCESS =
  'containers/quickView/spaceModal/FETCH_MEETING_SLOTS_SUCCESS'
export const FETCH_MEETING_SLOTS_FAILURE =
  'containers/quickView/spaceModal/FETCH_MEETING_SLOTS_FAILURE'

export const SET_MEETING_DURATION =
  'containers/quickView/spaceModal/SET_MEETING_DURATION'
