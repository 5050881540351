import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Translate, withLocalize } from 'react-localize-redux'

import ModalRow from 'containers/quickView/modal/ModalRow'
import ModalTitle from 'containers/quickView/modal/ModalTitle'
import { getLocale } from 'utils/utilsFunctions'
import {
  ongoingMeeting,
  minutesToNextMeeting,
  timeUntilFreeInWords,
  timeToNextMeetingInWords,
} from 'containers/quickView/spaceModal/utils'

class SpaceMeeting extends Component {
  renderTimeText = (currentLocale) => {
    const { meetings } = this.props

    return (
      <Translate
        id="quickView.meeting.timeToNextMeeting"
        data={{ time: timeToNextMeetingInWords(meetings, currentLocale) }}
      />
    )
  }

  render() {
    const { activeLanguage } = this.props

    return (
      <ModalRow>
        <ModalTitle>
          {this.renderTimeText(getLocale(activeLanguage.code))}
        </ModalTitle>
      </ModalRow>
    )
  }
}

SpaceMeeting.propTypes = {
  activeLanguage: PropTypes.object,
  meetings: PropTypes.array,
}

export default withLocalize(SpaceMeeting)
