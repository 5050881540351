import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import Icon from 'components/Icon'
import { getMetaProps } from 'containers/app/selectors'

const StyledProp = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.darkText};

  ${Icon} {
    display: block;
    width: 100%;
    margin: 0;
    margin-bottom: 8px;
    font-size: 24px;
  }

  span {
    display: block;
    font-size: 14px;
    font-weight: normal;
  }
`

const SpaceProp = ({ caption, iconName }) => (
  <StyledProp>
    <Icon name={iconName} />
    <span>{caption}</span>
  </StyledProp>
)

SpaceProp.propTypes = {
  caption: PropTypes.string,
  iconName: PropTypes.string,
}

const mapStateToProps = (state, props) => {
  const metaProp = getMetaProps(state).get(`${props.id}`)

  return {
    caption: metaProp.get('name'),
    iconName: metaProp.get('res'),
  }
}

export default connect(mapStateToProps)(SpaceProp)
