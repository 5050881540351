import { takeEvery, all, call, put } from 'redux-saga/effects'

import * as argus from 'api/argus'
import { FETCH_CLIENT_CONFIG_REQUEST } from 'containers/app/constants'
import {
  fetchClientConfigSuccess,
  fetchClientConfigFailure,
} from 'containers/app/actions'

function* fetchClientConfig() {
  try {
    const config = yield call(argus.fetchPublicData, '/public/config')
    yield put(fetchClientConfigSuccess(config))
  } catch ({ error }) {
    yield put(fetchClientConfigFailure(error.message))
  }
}

function* watchClientConfig() {
  yield takeEvery(FETCH_CLIENT_CONFIG_REQUEST, fetchClientConfig)
}

export default function* () {
  yield all([watchClientConfig()])
}
